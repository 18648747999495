export default [
  '#d3e7bd',
  '#f3d09d',
  '#dfbaaf',
  '#f7bdbb',
  '#dcb5e3',
  '#b2beff',
  '#8fcaf9',
  '#96dfe9',
  '#ebd1d1',
  '#a8cbc7',
];
