<template>
  <div class="profit">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.projectReport.list.pageTitle') }}</span>
    </p>
    <!--page container -->
    <PageInnerSet :title="$t('general.projectReport.list.pageTitle')">
      <!--Column Filter-->
      <v-row class="mb-1">
        <v-col cols="6"> </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn dark color="red lighten-2" small @click.stop="resetFilter()" class="mx-1">{{
            $t('general.crud.reset')
          }}</v-btn>
          <ColumnFilterModal
            :title="this.$t('general.crud.filterColumn')"
            :openButton="this.$t('general.crud.filterColumn')"
          >
            <v-row>
              <v-col cols="3" v-for="item in headersForSelectBox" :key="item.value">
                <v-checkbox v-model="visibleColumns" :label="item.text" :value="item.value" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </ColumnFilterModal>
        </v-col>
      </v-row>
      <!--Column Filter-->

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="invoices"
        :options.sync="options"
        :page.sync="currentPage"
        :server-items-length="totalInvoices"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        hide-default-footer
        @page-count="totalPages = $event"
        :item-class="itemRowBackground"
        fixed-header
        height="700px"
        dense>
        <!-- filter -->
        <template v-slot:body.prepend>
          <tr>
            <td v-show="isEnabledColumn('project_type_en') || isEnabledColumn('project_type_jp')"
              class="v-data-table__divider">
              <!-- display:flex -> display:block -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.projectTypes"
                      :item-value="'id'"
                      :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'"
                      multiple
                      chips
                      deletable-chips
                      style="width: 120px"
                      v-bind="attrs"
                      v-model="filterData.projectTypeId"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.projectTypeId ? tooltipItem(filterData.projectTypeId) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('project_name')" class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.projectNames"
                      multiple
                      chips
                      deletable-chips
                      style="width: 200px"
                      v-bind="attrs"
                      v-model="filterData.projectName"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.projectName ? tooltipItem(filterData.projectName) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('name_en') || isEnabledColumn('name_jp')" class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.orderNames"
                      :item-value="'name_jp'"
                      :item-text="'name_jp'"
                      multiple
                      chips
                      deletable-chips
                      v-bind="attrs"
                      style="width: 200px"
                      v-model="filterData.orderNameJp"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.orderNameJp ? tooltipItem(filterData.orderNameJp) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('customer_name')" class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.customerNames"
                      multiple
                      chips
                      deletable-chips
                      v-bind="attrs"
                      style="width: 200px"
                      v-model="filterData.customerName"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.customerName ? tooltipItem(filterData.customerName) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('member_name')" class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.memberNames"
                      :item-value="'id'"
                      :item-text="'name'"
                      multiple
                      chips
                      deletable-chips
                      v-bind="attrs"
                      style="width: 100px"
                      v-model="filterData.memberId"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.memberId ? tooltipItem(filterData.memberId) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('order_status_name_en') || isEnabledColumn('order_status_name_jp')"
              class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.statuses"
                      :item-value="'id'"
                      :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'"
                      multiple
                      chips
                      deletable-chips
                      v-bind="attrs"
                      style="width: 140px"
                      v-model="filterData.statusId"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.statusId ? tooltipItem(filterData.statusId) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('invoice_month')" class="v-data-table__divider">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-combobox
                      dense
                      :items="selectBoxData.invoiceDates"
                      multiple
                      chips
                      deletable-chips
                      v-bind="attrs"
                      style="width: 100px"
                      v-model="filterData.invoiceDate"
                      @input="updateAllFilters"
                    />
                  </div>
                </template>
                <span>{{ filterData.invoiceDate ? tooltipItem(filterData.invoiceDate) : $t('general.tooltip.noSelect') }}</span>
              </v-tooltip>
            </td>
            <td v-show="isEnabledColumn('invoice_amount')" class="v-data-table__divider">
              <v-text-field
                v-model.number="filterData.min_payment_amount"
                dense
                :placeholder="$t('general.crud.minPlaceholder')"
                type="number"
                min="0"
                step="500000"
                @input="updateAllFilters"
                style="font-size: 12px"
                :rules="
                  filterData.max_payment_amount && filterData.min_payment_amount
                    ? rules.paymentCountMin.concat(rules.positiveInteger)
                    : []
                "
              ></v-text-field>
              <v-text-field
                v-model.number="filterData.max_payment_amount"
                dense
                :placeholder="$t('general.crud.maxPlaceholder')"
                type="number"
                min="0"
                step="500000"
                @input="updateAllFilters"
                style="font-size: 12px"
                :rules="
                  filterData.min_payment_amount && filterData.max_payment_amount
                    ? rules.paymentCountMax.concat(rules.positiveInteger)
                    : []
                "
              ></v-text-field>
            </td>
            <td v-show="isEnabledColumn('profit_placeholder')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('profit_rate_placeholder')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('indonesian_labour_cost')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('japan_labour_cost')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('total_outsourcing_cost')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('updated_at')" class="v-data-table__divider"></td>
            <td v-show="isEnabledColumn('action')" class="v-data-table__divider fixed"></td>
          </tr>
        </template>
        <!-- END filter -->

        <template v-slot:header.invoice_amount="{ header }">
          {{ header.text }}
          <hr />
          {{ totalInvoicesAmount.toLocaleString() }}円
        </template>
        <template v-slot:header.profit_placeholder="{ header }">
          {{ header.text }}
          <hr />
          {{ totalProfit.toLocaleString() }}円
        </template>
        <template v-slot:header.profit_rate_placeholder="{ header }">
          {{ header.text }}
          <hr />
          {{ totalInvoicesAmount ? (100 * totalProfit / totalInvoicesAmount).toFixed(0) : 0 }}%
        </template>
        <template v-slot:header.indonesian_labour_cost="{ header }">
          {{ header.text }}
          <hr />
          {{ totalIdnCost.toLocaleString() }}円
        </template>
        <template v-slot:header.japan_labour_cost="{ header }">
          {{ header.text }}
          <hr />
          {{ totalJpnCost.toLocaleString() }}円
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          <div>{{ formatDateTimeHelper(item.updated_at) }}</div>
        </template>
        <template v-slot:[`item.project_name`]="{ item }">
          <div class="" style="max-width: 200px">{{ item.project_name }}</div>
        </template>
        <template v-slot:[`item.customer_name`]="{ item }">
          <div class="" style="max-width: 200px">{{ item.customer_name }}</div>
        </template>
        <template v-slot:[`item.member_name`]="{ item }">
          <div class="" style="max-width: 200px">{{ item.member_name }}</div>
        </template>

        <template v-if="isLocaleJapanese" v-slot:[`item.name_jp`]="{ item }">
          <div class="" style="max-width: 200px">
            {{
              item.type == 1
                ? item.name_jp + ' ' + (item.invoice_date ? monthYearEnglishHelper(item.invoice_date) : '')
                : item.name_jp
            }}
          </div>
        </template>

        <template v-else v-slot:[`item.name_en`]="{ item }">
          <div style="max-width: 200px">
            {{
              item.type == 1
                ? item.name_jp + ' ' + (item.invoice_date ? monthYearEnglishHelper(item.invoice_date) : '')
                : item.name_jp
            }}
          </div>
        </template>

        <!-- <template v-if="isLocaleJapanese" v-slot:[`item.name_jp`]="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en }}
          </div>
        </template>

        <template v-else v-slot:[`item.name_en`]="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en }}
          </div>
        </template> -->

        <template v-if="isLocaleJapanese" v-slot:[`item.order_status_name_jp`]="{ item }">
          <v-chip
            :color="orderStatusColors.bgColor[item.order_status.name_en]"
            :text-color="orderStatusColors.textColor[item.order_status.name_en]"
            label
            x-small>
            {{ isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en }}
          </v-chip>
        </template>

        <template v-else v-slot:[`item.order_status_name_en`]="{ item }">
          <v-chip
            :color="orderStatusColors.bgColor[item.order_status.name_en]"
            :text-color="orderStatusColors.textColor[item.order_status.name_en]"
            label
            x-small>
            {{ isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en }}
          </v-chip>
        </template>

        <template v-slot:[`item.invoice_month`]="{ item }">
          <!-- If japanese local, change the date to MM月YYYY年 format, otherwise leave "as is" -->
          <div>{{ isLocaleJapanese ? getDisplayedTerm(item.invoice_month) : item.invoice_month }}</div>
        </template>
        <template v-slot:[`item.invoice_amount`]="{ item }">
          <div>{{ getDisplayedMoney(item.invoice_amount) }}</div>
        </template>

        <template v-slot:[`item.profit_placeholder`]="{ item }">
          <!-- get overall profit, then display as string  -->
          <div full-width>{{ getDisplayedMoney(item.profit_placeholder) }}</div>
        </template>

        <template v-slot:[`item.profit_rate_placeholder`]="{ item }">
          <!-- get overall profit, then convert to percentage, then reduct to 1 decimal point, then display as string -->
          <div>{{ getDisplayedRate(item.profit_rate_placeholder) }}</div>
        </template>

        <template v-slot:[`item.indonesian_labour_cost`]="{ item }">
          <div>{{ getDisplayedMoney(item.indonesian_labour_cost) }}</div>
        </template>

        <template v-slot:[`item.japan_labour_cost`]="{ item }">
          <div>{{ getDisplayedMoney(item.japan_labour_cost) }}</div>
        </template>

        <template v-slot:[`item.total_outsourcing_cost`]="{ item }">
          <div>{{ getDisplayedMoney(item.total_outsourcing_cost) }}</div>
        </template>

        <template v-slot:[`body.append`]="{ headers }">
          <tr v-if="areTotalsRequested" class="black--text">
            <td v-for="(header, i) in headers" :key="i">
              <tr>
                <div v-if="header.value === 'invoice_month'">
                  {{ $t('general.crud.total') }}
                </div>
                <div v-if="header.value === 'invoice_amount'">
                  {{ getDisplayedMoney(invoices.reduce((a, b) => a + (Number(b['invoice_amount']) || 0), 0)) }}
                </div>
                <div v-if="header.value === 'profit_placeholder'">
                  {{ getDisplayedMoney(invoices.reduce((a, b) => a + (Number(b['profit_placeholder']) || 0), 0)) }}
                </div>
                <div v-if="header.value === 'profit_rate_placeholder'">
                  {{ getDisplayedRate(invoices.reduce((a, b) => a + (Number(b['profit_rate_placeholder']) || 0), 0)) }}
                </div>
                <div v-if="header.value === 'indonesian_labour_cost'">
                  {{ getDisplayedMoney(invoices.reduce((a, b) => a + (Number(b['indonesian_labour_cost']) || 0), 0)) }}
                </div>
                <div v-if="header.value === 'japan_labour_cost'">
                  {{ getDisplayedMoney(invoices.reduce((a, b) => a + (Number(b['japan_labour_cost']) || 0), 0)) }}
                </div>
                <div v-if="header.value === 'total_outsourcing_cost'">
                  {{ getDisplayedMoney(invoices.reduce((a, b) => a + (Number(b['total_outsourcing_cost']) || 0), 0)) }}
                </div>
                <div v-else></div>
              </tr>
            </td>
          </tr>
        </template>

        <template v-if="isLocaleJapanese" v-slot:[`item.project.project_type.name_jp`]="{ item }">
          {{ item.project.project_type.name_jp }}
        </template>
        <template v-else v-slot:[`item.projectTypeId.name_en`]="{ item }">
          {{ item.project.project_type.name_en }}
        </template>

        <!-- edit button -->
        <template v-if="isHQ()" v-slot:[`item.action`]="{ item }">
          <div class="button-row">
            <v-btn
              :disabled="isLoadingTableData"
              :to="{ name: 'orders.edit', params: { id: item.id } }"
              color="primary"
              class="white--text"
              x-small
            >
              <v-icon x-small class="py-4">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              :disabled="isLoadingTableData"
              :to="{ name: 'orders.create', params: { id: item.id, duplicate: true } }"
              color="primary"
              class="white--text"
              x-small
              >
              <v-icon x-small class="py-4 pr-1">mdi-content-copy</v-icon>
              {{ $t('general.crud.btn.duplicate') }}
            </v-btn>
            <v-btn
              :disabled="isLoadingTableData"
              @click="checkSubmit(item.id)"
              color="red"
              class="white--text"
              x-small
            >
              <v-icon small class="py-4">mdi-delete</v-icon>
            </v-btn>
            <v-dialog v-model="dialog[item.id]" max-width="500" :key="item.id">
              <v-card>
                <p class="px-6 py-6 text-center">
                  {{ dialogMessage }}
                </p>
                <v-card-actions class="justify-center">
                  <v-btn dark class="white--text" @click="$set(dialog, item.id, false)">
                    {{ $t('general.validation.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="!cancel"
                    color="primary darken-1"
                    class="white--text"
                    :loading="loadingConfirm"
                    @click="deleteSubmit(item.id)"
                  >
                    {{ $t('general.validation.confirm') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-data-table>

      <!-- <div class="text-center pt-2 mt-3">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="7"
      ></v-pagination>
    </div> -->
    </PageInnerSet>
  </div>
</template>

<script>
import { getAll, getSelectBoxData, destroy } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';

import InputGroup from '@components/form_input/GFilterGroup.vue';
import FilterReset from '@views/_components/datatable_filter/TableFilterReset';
import CheckboxReset from '@views/_components/datatable_filter/TableFilterResetCheckbox';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import FilterSelect from '@views/_components/datatable_filter/TableFilterSelect';
import ColumnFilterModal from '@views/_components/ColumnFilterModal';
import Vue from 'vue';

import { formatDateTimeHelper, formatDateHelper, monthYearOnlyHelper, monthYearEnglishHelper } from '@/helpers';

import constants from '@/const/const';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.invoice.list.metaTitle'),
    };
  },
  components: {
    // InputGroup,
    // FilterReset,
    // CheckboxReset,
    PageInnerSet,
    // FilterTextSelect,
    ColumnFilterModal,
    // FilterSelect,
  },
  data: function() {
    return {
      invoices: [],
      totalInvoices: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        multiSort: true,
        sortBy: [],
        sortDesc: [],
      },
      visibleColumns: [],
      filterData: {
        projectTypeId: '',
        projectName: '',
        customerName: '',
        orderNameJp: '',
        memberId: '',
        statusId: '',
        invoiceDate: '',
        min_payment_amount: null,
        max_payment_amount: null,
      },
      activeFilters: {},
      selectBoxData: {
        projectTypes: [],
        projectNames: [],
        customerNames: [],
        orderNames: [],
        memberNames: [],
        statuses: [],
        invoiceDates: [],
      },
      totalsRequested: false,
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
      rules: {
        paymentCountMax: [
          v =>
            (parseInt(v) >= parseInt(this.filterData.min_payment_amount) && v !== null) ||
            this.$t('general.validation.lessThan'),
        ],
        paymentCountMin: [
          v =>
            (parseInt(v) <= parseInt(this.filterData.max_payment_amount) && v !== null) ||
            this.$t('general.validation.greaterThan'),
        ],
        positiveInteger: [v => v >= 0 || this.$t('general.validation.positiveInteger')],
      },
      orderStatusColors: constants.orderStatusColors,
      dialog: {},
      cancel: false,
      dialogMessage: '',
      loadingConfirm: false,
    };
  },
  created() {
    //this sets "all" as default value for
    this.visibleColumns = this.allHeaders.map((_element, index) => index);
  },
  mounted: function() {
    //this.getAllInvoices();
    this.retrieveSelectBoxData();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
  },
  computed: {
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
    areTotalsRequested: function() {
      return this.totalsRequested === true;
    },
    /**
     * Header of the data table
     */
    headersForSelectBox: function() {
      return this.allHeaders.map(function(element, index) {
        return { value: index, text: element.text };
      });
    },
    headers: function() {
      return this.allHeaders.filter(header => this.visibleColumns.includes(this.allHeaders.indexOf(header)));
    },
    allHeaders: function() {
      return [
        {
          text: this.$t('general.project.type'),
          value: this.isLocaleJapanese ? 'project_type_jp' : 'project_type_en',
          width: '7rem',
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.project.name'),
          value: 'project_name',
          width: 200,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.name'),
          value: this.isLocaleJapanese ? 'name_jp' : 'name_en',
          width: 200,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.project.customer'),
          value: 'customer_name',
          width: 200,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.pm'),
          value: 'member_name',
          width: 140,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.status'),
          value: this.isLocaleJapanese ? 'order_status_name_jp' : 'order_status_name_en',
          width: 160,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.invoiceMonth'),
          value: 'invoice_month',
          width: 110,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.amount'),
          value: 'invoice_amount',
          width: 140,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.projectReport.profit'),
          value: 'profit_placeholder',
          width: 160,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.projectReport.profitRate'),
          value: 'profit_rate_placeholder',
          width: 110,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.projectReport.japanCost'),
          value: 'japan_labour_cost',
          width: 150,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.projectReport.indCost'),
          value: 'indonesian_labour_cost',
          width: 140,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.projectReport.outsourcingCost'),
          value: 'total_outsourcing_cost',
          width: 160,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.crud.updatedAt'),
          value: 'updated_at',
          sortable: true,
          divider: true,
          width: 140,
        },
        {
          text: this.$t('general.crud.action'),
          width: 'auto',
          value: 'action',
          sortable: false,
          divider: true,
          align: 'center fixed',
          class: 'fixed',
        },
      ];
    },
    totalInvoicesAmount: function() {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.invoice_amount;
      });
      return total;
    },
    totalProfit: function() {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.profit_placeholder;
      });
      return total;
    },
    totalIdnCost: function() {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.indonesian_labour_cost;
      });
      return total;
    },
    totalJpnCost: function() {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.japan_labour_cost;
      });
      return total;
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    itemRowBackground: function(item) {
      if (item.is_active == 0) {
        return 'grey';
      } else {
        return false;
      }
    },
    toggleHeaders() {
      this.$nextTick(() => {
        if (this.allHeaders.length === this.visibleColumns.length) {
          this.visibleColumns = [];
        } else {
          this.visibleColumns = this.allHeaders.map((_element, index) => index);
        }
      });
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data
     */
    getAllInvoices: async function() {
      let url = 'managementprojects';
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.invoices = res.invoices.data;
        //this.assignTotals();
        this.totalInvoices = res.invoices.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    // assignTotals: function() {
    //   this.invoices.forEach(function(invoice, index, thisArray) {
    //     let profit = invoice.invoice_amount;
    //     if (invoice.japan_labour_cost) {
    //       profit =
    //         typeof invoice.japan_labour_cost === 'string'
    //           ? profit - Number(invoice.japan_labour_cost)
    //           : profit - invoice.japan_labour_cost;
    //     }
    //     if (invoice.indonesian_labour_cost) {
    //       profit =
    //         typeof invoice.indonesian_labour_cost === 'string'
    //           ? profit - Number(invoice.indonesian_labour_cost)
    //           : profit - invoice.indonesian_labour_cost;
    //     }
    //     if (invoice.total_outsourcing_cost) {
    //       profit =
    //         typeof invoice.total_outsourcing_cost === 'string'
    //           ? profit - Number(invoice.total_outsourcing_cost)
    //           : profit - invoice.total_outsourcing_cost;
    //     }
    //     thisArray[index].profit_placeholder = profit;
    //     // thisArray[index].profit_rate_placeholder = parseFloat((profit / invoice.invoice_amount * 100).toFixed(1));
    //     thisArray[index].profit_rate_placeholder = profit / invoice.invoice_amount * 100;
    //   });

    //
    // },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber = false) {
      this.getAllInvoices();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      // this for filter by form (the form above the table)
      // this.$refs.filter.reset();
      // this for filter by header (under the header table)
      this.filterData = {
        projectTypeId: '',
        projectName: '',
        customerName: '',
        orderNameJp: '',
        memberId: '',
        statusId: '',
        invoiceDate: '',
        min_payment_amount: null,
        max_payment_amount: null,
      };
      this.updateAllFilters();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('projectTypeId', this.filterData.projectTypeId);
      this.updateFilter('projectName', this.filterData.projectName);
      this.updateFilter('orderNameJp', this.filterData.orderNameJp);
      this.updateFilter('customerName', this.filterData.customerName);
      this.updateFilter('memberId', this.filterData.memberId);
      this.updateFilter('statusId', this.filterData.statusId);
      this.updateFilter('invoiceDate', this.filterData.invoiceDate);
      //true overwrites the default date = false parameter
      this.updateFilter('paymentDeadline', this.filterData.paymentDeadline, true);
      this.updateFilter('min_payment_amount', this.filterData.min_payment_amount);
      this.updateFilter('max_payment_amount', this.filterData.max_payment_amount);
      //for debugging the filters
      //console.log("this.activeFilters = " + JSON.stringify(this.activeFilters));
    },
    /**
     * transform each input date.
     * if each Date "YYYY-M" transform "YYYY-0M" add string "0"
     */
    transDates: function(inputDate) {
      if (inputDate) {
        let checkDate = inputDate;
        let year = checkDate.substr(0, checkDate.indexOf('-'));
        let month = checkDate.substr(checkDate.indexOf('-') + 1);
        if (month.length == 1) {
          const formatedDate = year + "-" + month.padStart(2, "0");
          return formatedDate;
        } else {
          return inputDate;
        }
      }
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page for explanation of "if (Array.isArray(val))" (basically it is just in case string values within an array contain commas)
     *
     */
    updateFilter: function(attr, val, date = false) {
      /*
      attr :str :filter title
      val :list :select filter item
      value :list :select and selected filter items 
      */
      if (val) {
        if (Array.isArray(val)) {
          //if date, we need to remove the "-" before sending to the api.
          if (date == true) {
            val.forEach(function(value, index, thisArray) {
              thisArray[index] = value.replace('-', '');
            });
          }
          var value = [];
          if (attr == 'projectTypeId') {
            val.forEach(element => {
              value.push(element.id);
            })
          }else if(attr == 'projectName') {
            val.forEach(element => {
              value.push(element);
            })
          }else if(attr == 'orderNameJp') {
            val.forEach(element => {
              value.push(element.name_jp);
            })
          }else if(attr == 'customerName') {
            val.forEach(element => {
              value.push(element);
            })
          }else if(attr == 'memberId') {
            val.forEach(element => {
              value.push(element.id);
            })
          }else if(attr == 'statusId') {
            val.forEach(element => {
              value.push(element.id);
            })
          }else if(attr == 'invoiceDate') {
            val.forEach(element => {
              value.push(element);
            })
          }else {
            val.forEach(element => {
              value.push(element);
            });
          }
          val = value.join('||');
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    removeChecks: function() {
      this.filterData.statusId = 0;
      //this.$refs.statusbox.reset();
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/managementprojects/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        //map is used when we filter by the value itself.(e.g. map(item => item.name); DISCARDS the ID )
        //if the item is data for a select box do not use map()
        //if the item is date for a combobox, DO use map()
        //Tomas - 2021/07/19
        this.selectBoxData.projectTypes = res.projectTypes;
        this.selectBoxData.projectNames = res.projectNames.map(item => item.name);
        this.selectBoxData.customerNames = res.customerNames.map(item => item.name);
        this.selectBoxData.memberNames = res.memberNames;
        this.selectBoxData.statuses = res.statuses;
        this.selectBoxData.invoiceDates = this.sortDates(this.monthYearOnlyHelper(res.invoiceDates));
        this.selectBoxData.orderNames = res.orderNames;
        //console.log("this.selectBoxData = " + JSON.stringify(this.selectBoxData));
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    sortDates: function(date_array) {
      let res = date_array.map(item => item.date ?? '').sort(
        function(a,b) {
          if (a < b){
            return 1;
          }else if (a > b){
            return -1;
          }else{
            return 0;
          }
        }
      );
      return res;
    },
    getMonthYearOnly: function(dates) {
      //this function converts date strings from the api into year/month strings
      //example 2019-05-13 -> 2019-05
      for (var key in dates) {
        // console.log(dates[key].date);
        //converts the yyyy-mm-dd format to yyyy-mm format
        dates[key].date = dates[key].date
          .split('-')
          .slice(0, 2)
          .join('-');
        // console.log(dates[key].date);
      }
      return dates;
    },
    //returns Japanese date format, monthYear must be  YYYY-MM
    getDisplayedTerm: function(monthYear) {
      if (monthYear) {
        return `${monthYear.split('-')[0]}年${monthYear.split('-')[1]}月`;
      } else {
        return null;
      }
    },
    //Rate is either string or number
    getDisplayedRate: function(rate) {
      //default value of null replaced with 0
      if (!rate) {
        rate = 0;
      }
      if (typeof rate === 'string') {
        return Number(rate).toFixed(1) + "%";
      }
      //if rate is already number
      return rate.toFixed(1) + "%";
    },
    //Money is either string or number
    getDisplayedMoney: function(money) {
      //default value of null replaced with 0
      if (!money) {
        money = 0;
      }
      if (typeof money === 'string') {
        if (this.isLocaleJapanese) {
          //japanese
          return Number(money).toLocaleString() + this.$t('general.invoice.yen');
        } else {
          //english
          return '¥' + Number(money).toLocaleString();
        }
      }
      //if money is already number
      if (this.isLocaleJapanese) {
        return money.toLocaleString() + this.$t('general.invoice.yen');
      } else {
        return '¥' + money.toLocaleString();
      }
    },

    formatDateTimeHelper,
    formatDateHelper,
    monthYearOnlyHelper,
    monthYearEnglishHelper,
    // ----------------------------------
    // Check Submit Dialog
    // ----------------------------------
    async checkSubmit(id) {
      var vm = this;
      var url = '/getOrderMessage/' + id;
      const res = await Vue.axios.get(url);
      if (res) {
        if (res.data != null) {
          if (res.data.data) {
            if (res.data.data.invoice_information) {
              vm.dialogMessage = 'Orders with a registered invoice information cannot be deleted.';
              vm.cancel = true;
            }
            if (res.data.data.report_items) {
              vm.dialogMessage = 'Reports have been made on this order. It can no longer be deleted.';
              vm.cancel = true;
            }
          } else {
            vm.dialogMessage = 'Are you sure you want to delete this order?';
            vm.cancel = false;
          }
        }
      }
      vm.$set(vm.dialog, id, true);
    },
    // ----------------------------------
    // Delete Submit Dialog
    // ----------------------------------
    async deleteSubmit(id) {
      var vm = this;
      vm.loadingConfirm = true;
      try {
        let url = `/order/${id}`;
        var res = await destroy(url);
        if (res) {
          vm.$set(vm.dialog, id, false);
          vm.options.page = 1;
          vm.getAllInvoices();
        }
        vm.loadingConfirm = false;
      } catch (error) {
        console.log(error);
      }
    },

    // for hide select filter if table header is hidden
    isEnabledColumn: function(value) {
      let obj_selectedHeaders = this.headers.find(obj => obj.value == value);
      return obj_selectedHeaders ? true : false;
    },
  },
};
</script>

<style lang="scss">
.profit {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 240px)  !important;
  }
  .v-pagination__navigation {
    display: none;
  }
  .highlight {
    padding: 0.625rem;
    background: red;
    border-radius: 0.25rem;
    color: white;
  }
  .theme--light.v-label {
    color: inherit !important;
  }

  .checkbox-style {
    border-radius: 4px;
  }
  .input-title {
    font-size: 0.9rem;
  }
  .v-text-field__details {
    display: none;
  }
  .v-messages {
    display: none;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  table > tbody > tr > td.fixed:nth-last-child(1),
  table > thead > tr > th.fixed:nth-last-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    z-index: 3;
    background: white;
    -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
    box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  }

  table > thead > tr > th.fixed:nth-last-child(1) {
    z-index: 4;
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }
  
  .v-select__selections::-webkit-scrollbar-track {
    background-color:white;
  }
  
  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
}
</style>
