<template>
  <v-btn dark color="primary" small @click="editModeMethod()" class="mx-1">
    <slot>
      {{ $t('general.crud.editMode') }}
    </slot>
  </v-btn>
</template>

<script>
 export default {
  methods: {
    editModeMethod() {
      this.$emit('editModeMethod');
    }
  },
 }
</script>