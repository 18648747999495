<template>
  <p class="mx-1 mb-0 text-caption text--secondary">
    <Link :toLink="toLink">
      <slot name="toLink"></slot>
    </Link>
    <span class="mx-2">></span>
    <span>
      <slot name="pageTitle"></slot>
    </span>
  </p>
</template>

<script>
  import Link from '@views/_components/atoms/Link.vue'

  export default {
    components: {
      Link,
    },
    props: {
      toLink: String,
    },
  }
</script>
