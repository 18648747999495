var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales"},[_c('p',{staticClass:"mx-1 mb-0 text-caption text--secondary"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(_vm._s(_vm.$t('general.nav.dashboard')))]),_c('span',{staticClass:"mx-2"},[_vm._v(">")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.sales.list.pageTitle')))])],1),_c('PageInnerSet',{attrs:{"title":_vm.$t('general.sales.list.pageTitle')}},[_c('v-form',{ref:"filter"},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('FilterDateRange',{attrs:{"title":_vm.$t('general.sales.invoiceMonth') + '：',"type":"month","rules_start":_vm.start_date_rule,"rules_end":_vm.end_date_rule},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.date),callback:function ($$v) {_vm.$set(_vm.filterData, "date", $$v)},expression:"filterData.date"}})],1),_c('v-col',[_c('div',{staticClass:"pt-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetFilter}},[_vm._v(_vm._s(_vm.$t('general.form.reset')))])],1)])],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('GInputGroup',{attrs:{"name":"checkboxFilter","title":_vm.$t('general.sales.status') + '：',"centered":false}},[_c('v-row',{staticClass:"py-3"},_vm._l((_vm.selectBoxData.statuses),function(ref){
var id = ref.id;
var name_jp = ref.name_jp;
var name_en = ref.name_en;
return _c('v-col',{key:id,staticClass:"py-0 no-pr",attrs:{"sm":"4","md":"3"}},[_c('v-checkbox',{ref:"statusbox",refInFor:true,staticClass:"mb-1 checkbox-style",style:({
                    'background-color': _vm.orderStatusColors.bgColor[name_en],
                    color: _vm.orderStatusColors.textColor[name_en],
                  }),attrs:{"multiple":"","dense":"","hide-details":"","label":_vm.getStatusLocale(name_jp, name_en),"value":id},on:{"click":_vm.updateAllFilters},model:{value:(_vm.filterData.statusId),callback:function ($$v) {_vm.$set(_vm.filterData, "statusId", $$v)},expression:"filterData.statusId"}})],1)}),1)],1)],1)],1)],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.monthlySales,"options":_vm.options,"page":_vm.currentPage,"server-items-length":_vm.totalMonthlySales,"loading":_vm.isLoadingTableData,"loading-text":_vm.$t('general.crud.loading'),"hide-default-footer":"","dense":"","fixed-header":"","height":"700px"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalPages = $event}},scopedSlots:_vm._u([{key:"item.invoice_month",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.isLocaleJapanese ? _vm.monthYearTermJapanHelper(item.invoice_month) : item.invoice_month))])]}},{key:"item.monthly_amount",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.displayedMoneyHelper(parseInt(item.monthly_amount), _vm.isLocaleJapanese)))])]}},{key:"item.monthly_high",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper(parseInt(item.monthly_high), _vm.isLocaleJapanese))+" ")])]}},{key:"item.monthly_moderate",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper(parseInt(item.monthly_moderate), _vm.isLocaleJapanese))+" ")])]}},{key:"item.monthly_low",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper(parseInt(item.monthly_low), _vm.isLocaleJapanese))+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"black--text"},[_c('th',{staticClass:"title"},[_vm._v(_vm._s(this.$t('general.crud.total')))]),_c('th',{staticClass:"title",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper( this.monthlySales.reduce(function (a, b) { return a + (Number(b['monthly_amount']) || 0); }, 0), _vm.isLocaleJapanese ))+" ")]),_c('th',{staticClass:"title",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper( this.monthlySales.reduce(function (a, b) { return a + (Number(b['monthly_high']) || 0); }, 0), _vm.isLocaleJapanese ))+" ")]),_c('th',{staticClass:"title",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper( this.monthlySales.reduce(function (a, b) { return a + (Number(b['monthly_moderate']) || 0); }, 0), _vm.isLocaleJapanese ))+" ")]),_c('th',{staticClass:"title",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.displayedMoneyHelper( this.monthlySales.reduce(function (a, b) { return a + (Number(b['monthly_low']) || 0); }, 0), _vm.isLocaleJapanese ))+" ")])])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }