import Datatable from '@views/main/schedule/Datatable';

const scheduleRoutes = [
  {
    path: 'schedule',
    name: 'schedule',
    component: Datatable,
    props: route => ({ ...route.query, ...route.params }),
    meta: {
      //only Admin users can view
      restricted: [],
    },
  },
];

export default scheduleRoutes;
