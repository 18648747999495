import DataTable from '@views/main/daily_report/Datatable';
import Form from '@views/main/daily_report/Form';

const projectsRoutes = [
  {
    path: 'reports',
    name: 'reports',
    component: DataTable,
    meta: {
        //all can view except restricted user
        restricted: []
      },
  },
  {
    path: 'reports/add',
    name: 'reports.add',
    component: Form,
    meta: {
        //only Admin users can view
        restricted: []
      },
  },
  {
    path: 'reports/:id/edit',
    name: 'reports.edit',
    component: Form,
    meta: {
      editPage: true,
      restricted: []
    }
  }
]

export default projectsRoutes;