<template>
  <div class="calendar-section">
    <!-- Breadcrumbs -->
    <Breadcrumbs :toLink="'dashboard'">
      <template v-slot:toLink>
        {{ $t('general.nav.dashboard') }}
      </template>
      <template v-slot:pageTitle>
        {{ $t('general.nav.schedules') }}
      </template>
    </Breadcrumbs>

    <PageInnerSet :title="$t('general.nav.schedules')">
      <!-- Loading component -->
      <MaskLoader :loading="isLoading" />
      <v-row justify="end" class="mt-0 mb-3">
        <v-col cols="3" class="py-0 d-flex justify-end">
          <!-- member button  -->
          <v-btn class="mr-2" color="blue darken-1" outlined text @click="handleMemberClick()">
            {{ $t('general.schedule.member') }}
          </v-btn>
          <!-- create new report Button  -->
          <v-btn class="mr-2" :disabled="morningReport.isDisabled" color="blue darken-1" outlined text v-if="!morningReport.isPage" @click="createMorningReport()">
            {{ $t('general.schedule.createMorningReport') }}
          </v-btn>
          <v-btn class="mr-2" color="blue darken-1" outlined text v-else @click="cancelMorningReport()">
            {{ $t('general.schedule.cancel') }}
          </v-btn>
          <!-- create new report Button  -->
          <v-btn color="blue darken-1" :disabled="dailyReport.isDisabled" outlined text v-if="!dailyReport.isPage" @click="createDailyReport()">
            {{ $t('general.schedule.createDailyReport') }}
          </v-btn>
          <v-btn color="blue darken-1" outlined text v-else @click="cancelDailyReport()">
            {{ $t('general.schedule.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- fullCalendar  -->
      <div class="calendar-container" :class="{ 'selection-mode': selectionMode }">
        <FullCalendar ref="calendar" :options="calendarOptions" />
      </div>
    </PageInnerSet>

    <v-row justify="center">
      <!-- Dialog add/edit event -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-toolbar color="#FFFFFF" flat>
            <v-toolbar-title>
              <span class="text-h5" v-if="!editPage">{{ $t('general.schedule.addEvent') }}</span>
              <span class="text-h5" v-else>{{ $t('general.schedule.editEvent') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="eventForm" @submit.prevent="submitEvent()">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      dense
                      outlined
                      append-icon="mdi-tag"
                      v-model="form.title"
                      :label="$t('general.schedule.title')"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-autocomplete
                      dense
                      outlined
                      append-icon="mdi-chevron-down"
                      :items="selectBoxData.projects"
                      :item-text="'project_name'"
                      :item-value="'project_id'"
                      v-model="form.projectId"
                      :label="$t('general.schedule.project')"
                      @change="changeByProject"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-autocomplete
                      dense
                      outlined
                      :items="selectBoxData.orders"
                      :item-text="'order_name_en'"
                      :item-value="'id'"
                      append-icon="mdi-chevron-down"
                      v-model="form.orderId"
                      :label="$t('general.schedule.order')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="pb-0 datepicker-theme">
                    <date-picker
                      v-model="form.start"
                      :type="form.allDay == true ? 'date' : 'datetime'"
                      value-type="YYYY-MM-DD HH:mm:ss"
                      :minute-step="15"
                      :show-second="false"
                      :open.sync="openStart"
                      @change="handleChangeDate"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" class="pb-0 datepicker-theme">
                    <date-picker
                      v-model="form.end"
                      :type="form.allDay == true ? 'date' : 'datetime'"
                      value-type="YYYY-MM-DD HH:mm:ss"
                      :minute-step="15"
                      :show-second="false"
                      :open.sync="openEnd"
                      @change="handleChangeDate"
                    ></date-picker>
                  </v-col>
                </v-row>

                <v-row col="12">
                  <v-col cols="9" class="pb-0">
                    <v-textarea
                      v-model="form.description"
                      :rules="rules.description"
                      dense
                      outlined
                      auto-grow
                      row-height="20"
                      append-icon="mdi-pencil-outline"
                      :label="$t('general.schedule.description')"
                      type="text"
                      :messages="form.description == null ? null : messageRemainingCharacter()"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-checkbox v-model="form.allDay" :label="$t('general.schedule.allDay')"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <!-- about action buttons  -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t('general.schedule.close') }}
              </v-btn>
              <v-btn color="blue darken-1" text type="submit" v-if="!editPage">
                {{ $t('general.schedule.keep') }}
              </v-btn>
              <v-btn color="blue darken-1" text type="submit" v-else>
                {{ $t('general.schedule.update') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- End -->

      <!-- Dialog schedule detail event -->
      <v-dialog v-model="dialogDetail" persistent max-width="400px">
        <v-card class="detail-event">
          <v-toolbar color="#FFFFFF" flat>
            <v-toolbar-title>Detail Event</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDetailDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="desc" v-if="detailEvent">
            <h3 class="text-capitalize mb-1">{{ detailEvent.title }}</h3>
            <p>
              {{
                detailEvent.all_day == true
                  ? $moment(detailEvent.start_date).format('YYYY-MM-DD')
                  : detailEvent.start_date
              }}
              -
              {{
                detailEvent.all_day == true ? $moment(detailEvent.end_date).format('YYYY-MM-DD') : detailEvent.end_date
              }}
            </p>

            <p class="mb-1">
              <v-icon left small>
                mdi-briefcase
              </v-icon>
              {{ detailEvent.project == null ? '-' : detailEvent.project.name }}
            </p>
            <p class="mb-1">
              <v-icon left small>
                mdi-receipt
              </v-icon>
              {{ detailEvent.order == null ? '-' : detailEvent.order.name_en }}
            </p>
            <p class="mb-1">
              <v-icon left small>
                mdi-account
              </v-icon>
              {{ detailEvent.member.name }}
            </p>
            <p class="mb-0">
              <v-icon left small>
                mdi-comment-text
              </v-icon>
              {{ detailEvent.description == null ? '-' : detailEvent.description }}
            </p>
          </v-card-text>

          <v-card-actions>
            <div v-if="userSchedule" style="display:flex">
              <v-btn color="blue" text @click="handleEditEvent(detailEvent.id)">
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue" text @click="handleDuplicateEvent(detailEvent.id)">
                <v-icon left>
                  mdi-content-copy
                </v-icon>
                Duplicate
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="(dialogDetail = false), (isOpen = true)">
                <v-icon left>
                  mdi-delete
                </v-icon>
                Delete
              </v-btn>
              <v-dialog v-model="isOpen" max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{ $t('general.alert.youSure') }}
                  </v-card-title>

                  <v-card-text>
                    <h4>
                      {{ $t('general.alert.confirmDelete') }}
                    </h4>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text small @click="handleRemoveEvent(detailEvent.id), (isOpen = false)">
                      {{ $t('general.validation.confirm') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End -->

      <!-- Member dialog, this is filter by member-->
      <MemberDialog
        :dialogOpen="memberDialogOpen"
        @dialogClose="handleMemberClick"
        @getData="getScheduleData"
        @memberChange="changeSelectedMembers"
      />
    </v-row>
  </div>
</template>

<script>
import io from 'lodash';
import Vue from 'vue';
import { getAll, store, getForm, update, destroy } from '@services/crud';
import { pushNotif } from '@/helpers';

import MemberDialog from '@views/_components/molecules/dialogs/MemberDialog.vue';
import Breadcrumbs from '@views/_components/organisms/Breadcrumbs.vue';
import PageInnerSet from '@views/_components/page/PageInnerSet.vue';
import MaskLoader from '@/views/_components/page/MaskLoader';

import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  metaInfo() {
    return {
      title: 'Calendar Schedule',
    };
  },
  components: {
    PageInnerSet,
    MaskLoader,
    FullCalendar,
    DatePicker,
    Breadcrumbs,
    MemberDialog,
  },

  props: {
    selectedMembers: { type: [Number, String, Array], default: undefined },
  },
  data() {
    return {
      memberDialogOpen: false,
      selectionMode: false,
      membersSelected: this.selectedMembers,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin],
        initialView: this.$route.query.mode ?? 'timeGridWeek',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        select: this.handleDateSelect,
        selectable: true,
        dayMaxEvents: true, // when too many events in a day, show the popover
        weekends: true,
        droppable: true,
        editable: true,
        events: [],
        eventTextColor: '#212529',
        eventClick: this.handleDetailEvent,
        eventDrop: this.handelDropEvent,
        eventResize: this.handelResizeEvent,
        locales: allLocales,
        locale: 'en', // th`e initial locale
        datesSet: this.handleChangeDateRange,
        initialDate: this.$route.query.date_start ?? this.$moment().format('YYYY-MM-DD'),
      },
      form: {
        title: '',
        description: '',
        start: null,
        end: null,
        orderId: null,
        projectId: null,
        allDay: false,
        color: '#1976D2',
      },
      rules: {
        description: [v => (v || '').length <= 2000 || this.$t('general.reports.message.progressLength')],
      },
      detailEvent: null,
      selectBoxData: {
        projects: [],
        orders: [],
        members: [],
      },
      dialog: false,
      dialogDetail: false,
      memberDialog: false,
      editPage: false,
      submitUrl: null,
      isLoading: false,
      dailyReport: {
        isPage: false,
        isDisabled: false,
      },
      morningReport: {
        isPage: false,
        isDisabled: false,
      },
      userSchedule: false,
      openStart: false,
      openEnd: false,
      isOpen: false,
    };
  },
  created() {
    this.getSelectBoxData();
  },

  computed: {
    // Selected members
    $selectedMembers() {
      return this.selectedMembers;
    },
  },

  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to) {
  //       const member = this.$props.selectedMembers ?? 1;
  //       console.log('member', this.$props.selectedMembers);
  //       // If start or end date is undefined,
  //       // navigate to the default start and end
  //       // if (!this.$props.selectedMembers) {
  //       //   const query = { ...to.query, ...{ member } };
  //       //   console.log('query', query);
  //       //   this.$router.push({ name: 'schedule', query });
  //       //   return;
  //       // }
  //     },
  //   },
  // },
  methods: {
    changeSelectedMembers: function(newMember) {
      console.log('newMember', newMember);
      this.membersSelected = newMember;

      // Create an array to store member IDs
      const member = [];

      // Loop through the newMember array and extract the member IDs
      // for (let i = 0; i < newMember.length; i++) {
      //   member.push(newMember[i].id);
      // }
      newMember.forEach(element => {
        member.push(element);
      });

      // get current query string member
      const urlParams = new URLSearchParams(window.location.search);
      const currentMemberIds = urlParams.get('member');

      // if not same, push to new query string
      // this will avoid redundant redirect
      if (currentMemberIds !== member.join(',')) {
        this.$router.push({
          name: 'schedule',
          query: {
            ...this.$route.query,
            member: member.join(','),
          },
        });
      }
    },
    closeDetailDialog: function() {
      this.dialogDetail = false;
      this.userSchedule = false;
    },
    getSelectBoxData: async function() {
      let url = '/schedule/select-box';
      try {
        const res = await getAll(url);
        this.selectBoxData.projects = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    getScheduleData: async function() {
      let url = '/schedule/getSchedules';
      // console.log('Active getScheduleData');
      const member = this.membersSelected;

      try {
        const res = await Vue.axios.post(url, {
          member: member,
        });
        const result = res.data.data;
        // console.log('schedule result', result);

        // Format the response data for event calendar
        this.calendarOptions.events = [];
        for (let i = 0; i < result.length; i++) {
          this.calendarOptions.events.push({
            id: result[i].id,
            title: result[i].title,
            start: result[i].start_date,
            end: result[i].end_date,
            allDay: result[i].all_day == 1 ? true : false,
            color: this.scheduleColorChange(result[i].member_id),
            editable: result[i].editable,
          });
        }

        // console.log('calendarOptions', this.calendarOptions);
      } catch (error) {
        console.log('- Error of getScheduleData function');
        console.log(error);
      }
    },
    handleChangeDate(value, type) {
      if (type === 'minute') {
        this.openStart = false;
        this.openEnd = false;
      }
    },
    handleMemberClick: function() {
      this.memberDialogOpen = !this.memberDialogOpen;
    },
    handleDateSelect: function(arg) {
      // Check if create daily report button is clicked
      if (this.dailyReport.isPage == true) {
        // This method for check the daily report already made or not
        this.checkIsSubmittable(arg);
        return;
      }

      if (this.morningReport.isPage == true) {
        // This method for check the morning report already made or not
        this.checkIsMorningReportSubmittable(arg);
        return;
      }

      // Set dialog state
      this.dialog = true;
      // Set editable page state
      this.editPage = false;
      // Set submit url state
      this.submitUrl = 'schedule/create';

      // Approximate 15 minutes date calculation
      var dateStart = this.$moment(arg.startStr).format('YYYY-MM-DD');
      var dateEnd = this.$moment(arg.endStr)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      var minutes = this.$moment().format('mm');
      var timeNow = this.$moment().format('HH:mm');

      const approximate = 15 - (minutes % 15);
      const dateTimeStart = this.$moment(dateStart + ' ' + timeNow)
        .add(approximate, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss');
      const dateTimeEnd = this.$moment(dateEnd + ' ' + timeNow)
        .add(approximate, 'minutes')
        .add(1, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');

      // Set form state based on date select
      this.form = {
        title: '',
        start: arg.allDay == false ? this.$moment(arg.startStr).format('YYYY-MM-DD HH:mm:ss') : dateTimeStart,
        end: arg.allDay == false ? this.$moment(arg.endStr).format('YYYY-MM-DD HH:mm:ss') : dateTimeEnd,
        allDay: false,
        projectId: null,
        orderId: null,
        description: null,
      };
    },
    handelDropEvent: async function(info) {
      console.log(info);
      const eventId = info.event.id;
      const url = '/schedule/' + eventId + '/update';
      try {
        const res = await Vue.axios.post(url, info.event);
        console.log('DropEvent res', res);
      } catch (error) {
        if (error.response.status == 501) {
          pushNotif('You can not change other users schedule', 'warning');
        } else {
          console.log(error.message);
        }
      } finally {
        this.getScheduleData();
      }
      console.log(url);
    },
    handelResizeEvent: async function(info) {
      console.log(info);
      const eventId = info.event.id;
      const url = '/schedule/' + eventId + '/update';
      try {
        const res = await Vue.axios.post(url, info.event);
        console.log('ResizeEvent res', res);
      } catch (error) {
        if (error.response.status == 501) {
          pushNotif('You can not change other users schedule', 'warning');
        } else {
          console.log(error.message);
        }
      } finally {
        this.getScheduleData();
      }
    },
    handleChangeDateRange: function(data) {
      let date_start = this.$moment(data.start).format('YYYY-MM-DD');
      let date_end = this.$moment(data.end).format('YYYY-MM-DD');
      let mode = data.view.type;

      if (data.view.type === 'dayGridMonth') {
        date_start = this.$moment(data.view.currentStart).format('YYYY-MM-DD');
        // currentEnd - 1days
        date_end = this.$moment(data.view.currentEnd)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
      }

      // avoid redundant redirect
      if (
        this.$route.query.mode != mode &&
        this.$route.query.date_start != date_start &&
        this.$route.query.date_end != date_end
      ) {
        this.$router.push({
          name: 'schedule',
          query: {
            ...this.$route.query,
            date_start: date_start,
            date_end: date_end,
            mode: mode,
          },
        });
      } else if (!this.$route.query.mode && !this.$route.query.date_start && !this.$route.query.date_end) {
        this.$router.push({
          name: 'schedule',
          query: {
            ...this.$route.query,
            date_start: date_start,
            date_end: date_end,
            mode: mode,
          },
        });
      }
    },
    // FullCalendar change colors
    scheduleColorChange: function(value) {
      const colorNumber = Number(value) % 11;
      const COLORS = [
        '#D50000',
        '#039BE5',
        '#33B679',
        '#8E24AA',
        '#3F51B5',
        '#E67C73',
        '#0B8043',
        '#F4511E',
        '#7986CB',
        '#F6BF26',
        '#616161',
      ];
      return COLORS[colorNumber];
    },
    submitEvent: async function() {
      // Set dialog state
      this.isLoading = true;

      if (this.$refs.eventForm.validate()) {
        let payload = {
          title: this.form.title,
          start: this.$moment(this.form.start).format('YYYY-MM-DD HH:mm:ss'),
          end: this.$moment(this.form.end).format('YYYY-MM-DD HH:mm:ss'),
          allDay: this.form.allDay,
          projectId: this.form.projectId,
          orderId: this.form.orderId,
          description: this.form.description,
        };

        const res = this.editPage ? await update(this.submitUrl, payload) : await store(this.submitUrl, payload);

        if (res) {
          // Fetch the server data
          this.getScheduleData();
          // Set the calendar event to empty, to avoid double data loaded
          this.calendarOptions.events = [];
          // Set dialog false
          this.dialog = false;
        }
      }

      // Set loading to false
      this.isLoading = false;
    },
    handleDetailEvent: async function(arg) {
      // Set dialog detail state
      this.dialogDetail = true;
      var eventId = parseInt(arg.event.id);

      try {
        let url = `schedule/${eventId}/detail`;
        const res = await getForm(url);
        this.detailEvent = res.data.data;
        this.userSchedule = res.user;
      } catch (error) {
        // Reset dialog detail state
        this.dialogDetail = false;
        this.userSchedule = false;
        console.log(error);
      }
    },
    handleEditEvent: async function(id) {
      // Set dialog/detail state
      this.dialog = true;
      this.dialogDetail = false;
      this.userSchedule = false;

      // Set editable page state
      this.editPage = true;
      // Update submit URL state
      this.submitUrl = `schedule/${id}/update`;

      try {
        let url = `schedule/${id}/edit`;
        const res = await getForm(url);
        var result = res.data;

        // Set the form state based on response data
        this.form = {
          title: result.title,
          start:
            result.all_day == 1 ? result.start_date : this.$moment(result.start_date).format('YYYY-MM-DD HH:mm:ss'),
          end: result.all_day == 1 ? result.end_date : this.$moment(result.end_date).format('YYYY-MM-DD HH:mm:ss'),
          allDay: result.all_day == 1 ? true : false,
          projectId: result.project_id,
          orderId: result.order_id,
          description: result.description,
        };

        // Call this function to automatically selected the project and order on the edit page
        this.changeByProject(result.project_id);
      } catch (error) {
        console.log(error);
      }
    },
    handleRemoveEvent: async function(id) {
      try {
        let url = `schedule/${id}/delete`;
        const res = await destroy(url);
        if (res) {
          this.dialogDetail = false;
          this.userSchedule = false;
          this.getScheduleData();
          this.calendarOptions.events = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleDuplicateEvent: async function() {
      // Set dialog state
      this.isLoading = true;

      let payload = {
        title: this.detailEvent.title,
        start: this.detailEvent.start_date,
        end: this.detailEvent.end_date,
        allDay: this.detailEvent.all_day,
        projectId: this.detailEvent.project_id,
        orderId: this.detailEvent.order_id,
        description: this.detailEvent.description,
      };
      const url = 'schedule/create';
      const res = await store(url, payload);

      if (res) {
        this.dialogDetail = false;
        this.userSchedule = false;
        this.getScheduleData();
        this.calendarOptions.events = [];
      }

      // Set loading to false
      this.isLoading = false;
    },
    createDailyReport: function() {
      // Define the refs calendar
      let arg = this.$refs.calendar.getApi();

      // Set the selection mode and daily report page to true
      this.selectionMode = !this.selectionMode;
      this.dailyReport.isPage = true;
      this.morningReport.isDisabled = true;

      // Define the initial view calendar
      var viewCalendar = arg.view.type;
      // Check based on calendar type view
      if (viewCalendar == 'dayGridMonth') {
        // Set warning notif
        pushNotif(this.$t('general.alert.selectDate'), 'warning');
      } else if (viewCalendar == 'timeGridWeek') {
        pushNotif(this.$t('general.alert.selectDate'), 'warning');
      } else if (viewCalendar == 'timeGridDay') {
        // Define the current date based on view calendar
        var currentDate = this.$moment(arg.view.currentStart).format('YYYY-MM-DD');
        // Set payload as params
        let payload = {
          startStr: currentDate,
        };
        // This method for check the daily report already made or not
        this.checkIsSubmittable(payload);
      }
    },
    cancelDailyReport: function() {
      this.dailyReport.isPage = false;
      this.selectionMode = false;
      this.morningReport.isDisabled = false;
    },
    generateDailyReport: async function(arg) {
      var vm = this;

      try {
        // Set loading state
        this.isLoading = true;

        let url = 'schedule/reports';
        let payload = { date: this.$moment(arg.startStr).format('YYYY-MM-DD') };

        const res = await getAll(url, payload);
        var result = res.data;
        var formItem = [];

        // Check if no event data on the date cell
        if (result.length == 0) {
          this.isLoading = false;
          pushNotif(this.$t('general.validation.noEventExists'), 'error');
          return;
        }

        // Loop the data result based on date select
        for (let i = 0; i < result.length; i++) {
          // Calculate the date, and need to wrap the difference in the duration
          var hours = vm.$moment
            .duration(
              vm
                .$moment(result[i].end_date, 'YYYY-MM-DD HH:mm')
                .diff(vm.$moment(result[i].start_date, 'YYYY-MM-DD HH:mm'))
            )
            .asHours();

          // Push the format report item that will filled automatic to report.add form
          formItem.push({
            order_id: result[i].order_id,
            order_name_en: result[i].order_name_en,
            order_name_jp: result[i].order_name_jp,
            order_names_en: [],
            order_names_jp: [],
            orders: [
              {
                value: result[i].order_id,
                text: result[i].order_name_en,
              },
            ],
            progress: result[i].description,
            project_name: result[i].project_name,
            time: hours,
            date: this.$moment(arg.startStr).format('YYYY-MM-DD'),
          });
        }

        // Set final format to localStorage
        localStorage.setItem('reportItem', JSON.stringify(formItem));
        // Push to the report.add form
        vm.$router.push({ name: 'reports.add' });
      } catch (error) {
        console.log(error);
      }

      // Reset loading state
      this.isLoading = false;
    },
    createMorningReport: function() {
      // Define the refs calendar
      let arg = this.$refs.calendar.getApi();

      // Set the selection mode and morning report page to true
      this.selectionMode = !this.selectionMode;
      this.morningReport.isPage = true;
      this.dailyReport.isDisabled = true;

      // Define the initial view calendar
      var viewCalendar = arg.view.type;
      // Check based on calendar type view
      if (viewCalendar == 'dayGridMonth') {
        // Set warning notif
        pushNotif(this.$t('general.alert.selectDate'), 'warning');
      } else if (viewCalendar == 'timeGridWeek') {
        pushNotif(this.$t('general.alert.selectDate'), 'warning');
      } else if (viewCalendar == 'timeGridDay') {
        // Define the current date based on view calendar
        var currentDate = this.$moment(arg.view.currentStart).format('YYYY-MM-DD');
        // Set payload as params
        let payload = {
          startStr: currentDate,
        };
        // This method for check the daily report already made or not
        this.checkIsMorningReportSubmittable(payload);
      }
    },
    cancelMorningReport: function() {
      this.morningReport.isPage = false;
      this.selectionMode = false;
      this.dailyReport.isDisabled = false;
    },
    generateMorningReport: async function(arg) {
      var vm = this;

      try {
        // Set loading state
        this.isLoading = true;

        let url = 'schedule/reports';
        let payload = { date: this.$moment(arg.startStr).format('YYYY-MM-DD') };

        const res = await getAll(url, payload);
        var result = res.data;
        var formItem = [];

        // Check if no event data on the date cell
        if (result.length == 0) {
          this.isLoading = false;
          pushNotif(this.$t('general.validation.noEventExists'), 'error');
          return;
        }

        // Loop the data result based on date select
        for (let i = 0; i < result.length; i++) {
          // Calculate the date, and need to wrap the difference in the duration
          var hours = vm.$moment
            .duration(
              vm
                .$moment(result[i].end_date, 'YYYY-MM-DD HH:mm')
                .diff(vm.$moment(result[i].start_date, 'YYYY-MM-DD HH:mm'))
            )
            .asHours();

          // Push the format report item that will filled automatic to report.add form
          formItem.push({
            order_id: result[i].order_id,
            order_name_en: result[i].order_name_en,
            order_name_jp: result[i].order_name_jp,
            order_names_en: [],
            order_names_jp: [],
            orders: [
              {
                value: result[i].order_id,
                text: result[i].order_name_en,
              },
            ],
            progress: result[i].description,
            project_name: result[i].project_name,
            time: hours,
            date: this.$moment(arg.startStr).format('YYYY-MM-DD'),
          });
        }

        // Set final format to localStorage
        localStorage.setItem('morningReportItem', JSON.stringify(formItem));
        // Push to the report.add form
        vm.$router.push({ name: 'morningReports.add' });
      } catch (error) {
        console.log(error);
      }

      // Reset loading state
      this.isLoading = false;
    },
    changeByProject: function(projectID) {
      // Filter by project to define the orders
      this.selectBoxData.orders = io.filter(this.selectBoxData.projects, function(items) {
        return items.project_id == projectID;
      });
    },
    getRemainingCharacter() {
      if (this.form.description != null) {
        return 2000 - this.form.description.length;
      } else {
        return 2000;
      }
    },
    messageRemainingCharacter() {
      if (this.form.description.length >= 2000) {
        return this.$t('general.reports.message.progressLength');
      } else {
        if (this.$i18n.locale == 'en') {
          return this.getRemainingCharacter() + this.$t('general.reports.remaining');
        } else {
          return (
            this.$t('general.reports.remaining') + this.getRemainingCharacter() + this.$t('general.reports.letter')
          );
        }
      }
    },
    checkIsSubmittable: async function(val) {
      // Set loading state
      this.isLoading = true;
      let url = '/schedule/reports/isSubmittable';
      let payload = { date: this.$moment(val.startStr).format('YYYY-MM-DD') };

      try {
        const res = await Vue.axios.post(url, payload);
        var result = res.data.data;

        if (result == true) {
          // Call this function, if the daily report is exists
          this.generateDailyReport(val);
        } else {
          // Show the validation error
          pushNotif(this.$t('general.validation.dailyReportExists'), 'error');
        }
      } catch (err) {
        console.log(err);
      }

      // Reset loading state
      this.isLoading = false;
    },
    checkIsMorningReportSubmittable: async function(val) {
      // Set loading state
      this.isLoading = true;
      let url = '/schedule/morning-reports/isSubmittable';
      let payload = { date: this.$moment(val.startStr).format('YYYY-MM-DD') };

      try {
        const res = await Vue.axios.post(url, payload);
        var result = res.data.data;

        if (result == true) {
          // Call this function, if the morning report is exists
          this.generateMorningReport(val);
        } else {
          // Show the validation error
          pushNotif(this.$t('general.validation.morningReportExists'), 'error');
        }
      } catch (err) {
        console.log(err);
      }

      // Reset loading state
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.fc .fc-button .fc-icon {
  display: flex;
}

.fc-toolbar {
  .fc-button {
    text-transform: capitalize;
  }
}

.table-with-border tbody tr {
  border-right: 1px solid #000;
}

.datepicker-theme {
  .mx-datepicker {
    width: 100%;

    .mx-input-wrapper {
      margin-bottom: 1.5rem !important;
    }

    .mx-input {
      height: 2.5rem !important;
      border: 1px solid #9e9e9e !important;
    }
  }
}

.detail-event {
  .desc {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.container--fluid {
  border: 2px solid red !important;
}

.calendar-container {
  .fc-daygrid-day {
    transition: background 0.2s ease;
  }

  &.selection-mode {
    .fc-daygrid-day,
    .fc-timegrid-col {
      position: relative;
      cursor: pointer;
      z-index: 9;

      &:hover {
        box-shadow: 0 0 0 0 #ffcdd28f inset;
        background: #ffcdd28f;
      }
    }
  }

  thead {
    background-color: #f9f9f9;
    position: sticky;
    top: 56px;
    z-index: 4;
  }

  @media (min-width: 992px) {
    thead {
      top: 64px;
    }
  }
}
</style>
