<template>
  <a class="blue--text filter-reset" @click.prevent="$emit('click')" href="#">
    {{ $t('general.form.reset') }}
  </a>
</template>

<script>
export default {};
</script>

<style lang="scss">
.filter-reset {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  padding: 0.25rem 0;
}
</style>
