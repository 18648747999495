import DataTable from '@views/main/project/Datatable';
import Form from '@views/main/project/Form';
import constants from "@/const/const";

const projectsRoutes = [
  {
    path: 'projects',
    name: 'projects',
    component: DataTable,
    meta: {
        //all users can see this page
        restricted: null 
      },
  },
  {
    path: 'projects/create',
    name: 'projects.create',
    component: Form,
    meta: {
        //only Japanese users
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'projects/:id/edit',
    name: 'projects.edit',
    component: Form,
    meta: {
      editPage: true,
      //only Japanese users
      restricted: constants.permissions.nonHQ
    }
  }
]

export default projectsRoutes;