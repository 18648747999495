var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modification_history"},[_c('Breadcrumbs',{attrs:{"toLink":'dashboard'},scopedSlots:_vm._u([{key:"toLink",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.nav.dashboard'))+" ")]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.modificationHistory.list.pageTitle'))+" ")]},proxy:true}])}),_c('PageInnerSet',{attrs:{"title":_vm.$t('general.modificationHistory.list.pageTitle')}},[_c('v-row',{staticClass:"mb-1"},[_c('DateSearch',{attrs:{"col_cols":4,"activeFilters":_vm.activeFilters,"rules":_vm.rules,"DateType":'month'}}),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('ResetButton',{on:{"resetFilter":_vm.resetFilter}},[_vm._v(_vm._s(_vm.$t('general.crud.reset')))]),_c('ColumnFilterModal',{attrs:{"title":this.$t('general.crud.filterColumn'),"openButton":this.$t('general.crud.filterColumn')}},[_c('v-row',_vm._l((_vm.headersForSelectBox),function(item){return _c('v-col',{key:item.value,attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":item.text,"value":item.value,"hide-details":""},model:{value:(_vm.visibleColumns),callback:function ($$v) {_vm.visibleColumns=$$v},expression:"visibleColumns"}})],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs.data,"loading":_vm.isLoadingTableData,"loading-text":_vm.$t('general.crud.loading'),"options":_vm.options,"server-items-length":_vm.logs.total,"dense":"","fixed-header":"","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('date')),expression:"isEnabledColumn('date')"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"110px"},attrs:{"items":_vm.selectBoxData.createdAt,"item-value":'id',"chips":"","deletable-chips":"","dense":"","multiple":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.createdAt),callback:function ($$v) {_vm.$set(_vm.filterData, "createdAt", $$v)},expression:"filterData.createdAt"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.createdAt ? _vm.tooltipItem(_vm.filterData.createdAt) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('type')),expression:"isEnabledColumn('type')"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"110px"},attrs:{"items":_vm.selectBoxData.logTypes,"item-text":_vm.isLocaleJapanese ? 'name_jp': 'name_en',"item-value":'id',"chips":"","deletable-chips":"","dense":"","multiple":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.logTypeId),callback:function ($$v) {_vm.$set(_vm.filterData, "logTypeId", $$v)},expression:"filterData.logTypeId"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.logTypeId ? _vm.tooltipItem(_vm.filterData.logTypeId) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('project')),expression:"isEnabledColumn('project')"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"220px"},attrs:{"items":_vm.selectBoxData.projectNames,"item-text":'name',"item-value":'id',"chips":"","deletable-chips":"","dense":"","multiple":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.projectName),callback:function ($$v) {_vm.$set(_vm.filterData, "projectName", $$v)},expression:"filterData.projectName"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.projectName ? _vm.tooltipItem(_vm.filterData.projectName) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('order')),expression:"isEnabledColumn('order')"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"200px"},attrs:{"items":_vm.selectBoxData.orderNames,"item-text":'name_jp',"item-value":'id',"chips":"","deletable-chips":"","dense":"","multiple":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.orderId),callback:function ($$v) {_vm.$set(_vm.filterData, "orderId", $$v)},expression:"filterData.orderId"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.orderId ? _vm.tooltipItem(_vm.filterData.orderId) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('updatedBy')),expression:"isEnabledColumn('updatedBy')"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"200px"},attrs:{"items":_vm.selectBoxData.updatedBy,"item-text":'name',"item-value":'id',"chips":"","deletable-chips":"","dense":"","multiple":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.updatedBy),callback:function ($$v) {_vm.$set(_vm.filterData, "updatedBy", $$v)},expression:"filterData.updatedBy"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.updatedBy ? _vm.tooltipItem(_vm.filterData.updatedBy) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('invoice_amount')),expression:"isEnabledColumn('invoice_amount')"}],staticStyle:{"width":"200px"}}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('invoice_date')),expression:"isEnabledColumn('invoice_date')"}],staticStyle:{"width":"200px"}})])]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.log_type.name_jp ? item.log_type.name_jp : '')+" ")])]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.project_name ? item.project_name : '')+" ")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.order ? item.order.name_jp : '')+" ")])]}},{key:"item.updatedBy",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.member ? item.member.name : '')+" ")])]}},{key:"item.invoice_amount",fn:function(ref){
var item = ref.item;
return [(item.old_invoice_amount)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.modificationHistory.beforeUpdate') + ': ' + item.old_invoice_amount)+" ")]),_c('hr'),_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.modificationHistory.afterUpdate') + ': ' + item.modified_invoice_amount)+" ")])]):_vm._e()]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [(item.modified_invoice_date)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.modificationHistory.beforeUpdate') + ': ' + item.modified_invoice_date)+" ")]),_c('hr'),_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.modificationHistory.afterUpdate') + ': ' + item.old_invoice_date)+" ")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }