<template>
  <td v-if="member" class="text-center px-0 py-0">
    <i v-if="reportExists" class="far fa-circle fa-lg"></i>
    <i v-else class="far fa-times fa-lg"></i>
  </td>
  <td v-else class="text-center px-0 py-0" :style="styles">
    <strong>{{ totalHours }}H</strong>
  </td>
</template>

<script>
import { filter, sumBy } from 'lodash';

export default {
  props: {
    date: { type: String, default: undefined },
    reports: { type: Array, default: () => [] },
    color: { type: String, default: 'transparent' },
    member: { type: Boolean, default: false },
  },

  computed: {
    todayReports() {
      const today = this.date;
      return filter(this.reports, function(report) {
        return report.date === today;
      });
    },

    totalHours() {
      return sumBy(this.todayReports, 'time') ?? 0;
    },

    reportExists() {
      return this.todayReports.length > 0;
    },

    styles() {
      const backgroundColor = this.totalHours ? this.color : '#F4F4F4';
      return { backgroundColor };
    },
  },
};
</script>
