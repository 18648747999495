<template>
  <div>
    <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"> </v-skeleton-loader>
    <div v-if="!loadingComponent">
      <!-- Breadcrumbs -->
      <p class="mx-1 mb-0 text-caption text--secondary">
        <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
        <span class="mx-2">></span>
        <span>{{ editPage ? $t('general.morningReports.edit.pageTitle') : $t('general.morningReports.create.pageTitle') }}</span>
      </p>

      <!-- Page Title -->
      <PageInnerSet :title="editPage ? $t('general.morningReports.edit.pageTitle') : $t('general.morningReports.create.pageTitle')" class="pb-16">
        <v-form ref="reportForm" @submit.prevent="submit">
          <v-container style="max-width: 1500px; margin-right: auto; margin-left: 0">
            <v-row no-gutters align="center">
              <v-col cols="12" md="1"
                ><span class="pa-2 text-h6 text--secondary">{{ $t('general.morningReports.date') }}</span></v-col
              >
              <v-col xs="4" md="5">
                <g-date-picker
                  v-model="date"
                  :disabled="loadingComponent"
                  :rules="dateRules"
                  type="date"
                ></g-date-picker>
              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col offset="1" cols="4">
                <div v-if="isChecking">
                  <v-progress-circular class="mb-1" :width="2" :size="20" indeterminate />
                  日報の有無をチェック中...
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container style="max-width: 1500px; margin-right: auto; margin-left: 0" v-if="!editPage">
            <v-row no-gutters align="center">
              <v-col xs="12" md="2">
                <v-select
                  :items="previousReport"
                  v-model="selectedReport"
                  item-text="date"
                  item-value="id"
                  no-data-text="No data"
                  clearable
                >
                  <template v-slot:item="{ item }">
                    {{ $moment(item.date).format('YYYY-MM-DD') }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $moment(item.date).format('YYYY-MM-DD') }}
                  </template>
                </v-select>
              </v-col>
              <v-col xs="12" md="3">
                <v-btn depressed color="#ffcccb" class="mx-2" @click="getPreviousReport" :disabled="loadingComponent">
                  Reflect previous report
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <br />
          <span>{{ $t('general.morningReports.projectNotAvailableGuidance') }}</span>
          <br />
          <!-- Table Header -->
          <v-container style="max-width: 1500px; margin-right: auto; margin-left: 0">
            <v-row no-gutter>
              <v-col cols="12" md="3">
                <span class="pa-2 text-h6 text--secondary">{{ $t('general.morningReports.projectName') }}</span></v-col
              >
              <v-col cols="12" md="3"
                ><span class="pa-2 text-h6 text--secondary">{{ $t('general.morningReports.orderName') }}</span></v-col
              >
              <v-col cols="12" md="2"
                ><span class="pa-2 text-h6 text--secondary">{{ $t('general.morningReports.time') }}</span></v-col
              >
              <v-col cols="12" md="3"
                ><span class="pa-2 text-h6 text--secondary">{{ $t('general.morningReports.progress') }}</span></v-col
              >
              <v-col cols="1" md="1"></v-col>
            </v-row>
          </v-container>
          <hr color="#aaaaaa" style="max-width: 1500px" />
          <v-progress-linear
            height="10"
            indeterminate
            v-if="loadingReport"
            style="max-width: 1500px"
          ></v-progress-linear>
          <div v-if="!loadingReport">
            <div v-for="n of numberOfForm" :key="n">
              <v-container style="max-width: 1500px; margin-right: auto; margin-left: 0">
                <v-row no-gutters>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      :rules="rules[n - 1].project_name"
                      outlined
                      dense
                      :items="selectBoxData.project_names"
                      v-model="forms[n - 1].project_name"
                      @change="changeProjectName(n - 1)"
                      class="mx-2 mt-3"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      :rules="rules[n - 1].order_name_en"
                      outlined
                      dense
                      :items="forms[n - 1].orders"
                      v-model="forms[n - 1].order_id"
                      class="mx-2 mt-3 mb-n3"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      :rules="rules[n - 1].time"
                      outlined
                      dense
                      :items="times"
                      v-model="forms[n - 1].time"
                      @change="updateRules(n - 1)"
                      class="mx-2 mt-3"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-textarea
                      :rules="rules[n - 1].progress"
                      label=""
                      auto-grow
                      outlined
                      row-height="20"
                      v-model="forms[n - 1].progress"
                      @change="updateRules(n - 1)"
                      :messages="forms[n - 1].progress == null ? null : messageRemainingCharacter(n - 1)"
                      class="mx-2 mt-3 mb-n3"
                    >
                      <template v-slot:message="{ message }">
                        <span> {{ message }} </span>
                      </template>
                      <!--<template v-slot:counter>
                        <span v-if="$i18n.locale == 'en'">
                          {{ getRemainingCharacter(n - 1) }} {{ $t('general.morningReports.remaining') }}
                        </span>
                        <span v-else>
                          {{ $t('general.morningReports.remaining') }} {{ getRemainingCharacter(n - 1) }}
                          {{ $t('general.morningReports.letter') }}
                        </span>
                      </template>-->
                    </v-textarea>
                  </v-col>
                  <v-col cols="1" align-self="end">
                    <v-btn
                      depressed
                      color="error"
                      class="mx-2 my-5"
                      width="100%"
                      @click="deleteForm(n - 1)"
                      :disabled="loadingComponent"
                    >
                      {{ $t('general.crud.delete') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <hr color="#aaaaaa" style="max-width: 1500px" />
            </div>
          </div>
          <v-container style="max-width: 1500px; margin-right: auto; margin-left: 0">
            <v-row class="mb-2">
              <v-col sm="12" md="6">
                <v-row>
                  <v-col sm="12" md="6" lg="4">
                    <v-btn depressed color="primary" width="100%" @click="saveDraft" :disabled="loadingComponent">
                      {{ $t('general.morningReports.saveDraft') }}
                    </v-btn>
                  </v-col>
                  <v-col sm="12" md="6" lg="4">
                    <v-btn depressed color="primary" width="100%" @click="loadDraft" :disabled="loadingComponent">
                      {{ $t('general.morningReports.loadDraft') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="6">
                <v-row justify="end">
                  <v-col sm="12" md="6" lg="auto">
                    <v-btn depressed color="primary" width="100%" @click="dialog = true">
                      {{ $t('general.morningReports.addNewOrder') }}
                    </v-btn>
                  </v-col>
                  <v-col sm="12" md="6" lg="2">
                    <v-btn depressed color="primary" width="100%" @click="addForm" :disabled="loadingComponent">
                      {{ $t('general.morningReports.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="600px">
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <span class="text-h6 text-center">{{ $t('general.morningReports.addOrder') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        outlined
                        dense
                        :items="newProjectForms.projects"
                        v-model="newProjectForms.project_name"
                        item-text="text"
                        item-value="value"
                        @change="changeNewProjectName()"
                        class="mx-2 mt-3"
                        :label="$t('general.morningReports.newProjectName')"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        outlined
                        dense
                        :items="newProjectForms.orders"
                        v-model="newProjectForms.order_id"
                        item-text="text"
                        item-value="value"
                        class="mx-2 mt-3 mb-n3"
                        :label="$t('general.morningReports.newOrderName')"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog = false" text>
                    {{ $t('general.morningReports.close') }}
                  </v-btn>
                  <v-btn @click="saveNewOrder()" text>
                    {{ $t('general.morningReports.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-row no-gutters>
              <v-col offset="2" cols="10" offset-md="4" md="3">
                <span class="pa-2 text-h6 text--secondary">
                  {{ $t('general.morningReports.total') }}: {{ totalTime != 0 ? totalTime : '-' }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col offset="4" cols="3">
                <v-btn
                  type="submit"
                  depressed
                  color="primary"
                  width="100%"
                  class="mt-2"
                  :disabled="loadingComponent || !isSubmittable"
                >
                  Submit
                </v-btn>
              </v-col>
              <v-col offset="4" cols="8" class="text-notification">
                {{ !isSubmittable ? 'You have already submitted a report for today' : '' }}
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </PageInnerSet>
    </div>
  </div>
</template>

<script>
import { getAll, store, update } from '@services/crud';
import GDatePicker from '../../_components/form_input/GDatePicker.vue';
import Vue from 'vue';
import PageInnerSet from '@views/_components/page/PageInnerSet';

export default {
  components: {
    GDatePicker,
    PageInnerSet,
  },
  metaInfo() {
    return {
      title: this.$t('general.morningReports.create.metaTitle'),
    };
  },
  data: function() {
    return {
      loadingReport: false,
      selectBoxData: {
        data: {},
        project_names: [],
      },
      formDataTemplate: {
        project_name: null,
        order_id: null,
        order_name_jp: null,
        order_names_jp: [],
        order_name_en: null,
        order_names_en: [],
        time: null,
        progress: null,
      },
      defaultRules: {
        date: [
          v => !!v || this.$t('general.morningReports.message.dateRequired'),
          v => this.dateFormat(v) <= this.today() || this.$t('general.morningReports.message.dateLesserThanToday'),
        ],
        project_name: [v => !!v || this.$t('general.morningReports.message.projectNameRequired')],
        order_name_jp: [v => !!v || this.$t('general.morningReports.message.orderNameJPRequired')],
        order_name_en: [v => !!v || this.$t('general.morningReports.message.orderNameENRequired')],
        time: [v => !!v || this.$t('general.morningReports.message.timeRequired')],
        progress: [
          v => !!v || this.$t('general.morningReports.message.progressRequired'),
          v => (v && v.length <= 2000) || this.$t('general.morningReports.message.progressLength'),
        ],
      },
      noRules: {
        date: [],
        project_name: [],
        order_name_jp: [],
        order_name_en: [],
        time: [],
        progress: [],
      },
      dateRules: [
        v => !!v || this.$t('general.morningReports.message.dateRequired'),
        v => this.dateFormat(v) <= this.today() || this.$t('general.morningReports.message.dateLesserThanToday'),
      ],
      rules: [],
      forms: [],
      selectedReport: null,
      previousReport: [],
      date: null,
      numberOfForm: 5,
      times: [],
      isSubmittable: false,
      isChecking: false,
      loadingComponent: false,
      editPage: false,
      dialog: false,
      newProjectForms: {
        orderData: [],
        projects: [],
        project_name: null,
        order_id: null,
        orders: [],
      },
    };
  },
  created: function() {
    this.date = this.today();
    for (let i = 1; i < 24 * 4 + 1; i++) this.times.push(i * 0.25);
    for (let i = 0; i < this.numberOfForm; i++) {
      i == 0 ? this.rules.push(this.defaultRules) : this.rules.push(this.noRules);
      this.forms.push({ ...this.formDataTemplate });
    }
  },
  mounted: async function() {
    await this.retrieveSelectBoxData();
    await this.retrieveAllReport();
    if (this.$route.meta.editPage) {
      this.editPage = true;
      await this.getPreviousReport();
    }
    // console.log(JSON.parse(this.$cookie.get('report')));
    console.log(JSON.parse(localStorage.getItem('morningReport')));
    this.loadReportItem();
    await this.retriveNotAvailableOrder();
  },
  computed: {
    totalTime() {
      let total = 0;
      this.forms.forEach(e => {
        if (e.time != null) {
          total += e.time;
        }
      });
      return total;
    },
  },
  methods: {
    submit: async function() {
      this.loadingComponent = true;
      if (this.$refs.reportForm.validate()) {
        let url = this.$route.path;
        let chatworkUrl = '/chatwork/send-morning-report';
        let payload = {
          date: this.date,
          time: this.totalTime,
          forms: this.filledForms(),
        };
        console.log(payload);
        const res = this.editPage ? await update(url, payload) : await store(url, payload);
        console.log(res);

        // Send to chatwork then
        let morning_report_id = res.morning_report_id;
        if (!this.editPage) {
          const sendChatwork = await update(chatworkUrl, {
            morning_report_id: morning_report_id,
          });
        }

        this.$router.push({ name: 'morningReports' });
      }
      this.loadingComponent = false;
    },
    addForm: function() {
      this.numberOfForm++;
      this.forms.push({ ...this.formDataTemplate });
      this.rules.push(this.noRules);
    },
    deleteForm: function(index) {
      if (this.numberOfForm <= 1) return;
      this.numberOfForm--;
      this.forms.splice(index, 1);
      this.rules.splice(index, 1);
    },
    isFormEmpty: function(index) {
      let emptyForm = true;

      for (const element in this.forms[index]) {
        let item = this.forms[index][element];
        if (item != null && item != '' && !Array.isArray(item)) {
          emptyForm = false;
        }
      }

      return emptyForm;
    },
    updateRules: function(index) {
      let emptyForm = this.isFormEmpty(index);

      if (emptyForm) {
        this.rules[index] = this.noRules;
      } else {
        this.rules[index] = this.defaultRules;
      }
      console.log(this.rules);
    },
    filledForms: function() {
      let forms = [];
      for (let i = 0; i < this.numberOfForm; i++) {
        if (!this.isFormEmpty(i)) {
          forms.push(this.forms[i]);
        }
      }

      return forms;
    },
    changeProjectName: function(index) {
      if (this.forms[index].project_name in this.selectBoxData.data) {
        this.forms[index].orders = [];
        this.selectBoxData.data[this.forms[index].project_name].forEach(e => {
          let orders = {
            value: e.id,
            text: e.order_name_en,
          };

          this.forms[index].orders.push(orders);
        });
      }
      this.forms[index].order_name_jp = '';
      this.forms[index].order_name_en = '';
      this.updateRules(index);
    },
    changeOrderJP: function(index) {
      console.log(index);
      if (this.forms[index].project_name in this.selectBoxData.data) {
        this.selectBoxData.data[this.forms[index].project_name].some(e => {
          if (e.order_name_jp == this.forms[index].order_name_jp) {
            this.forms[index].order_name_en = e.order_name_en;
            this.forms[index].order_id = e.id;
            return true;
          }
        });
      }
      this.updateRules(index);
    },
    changeOrderEN: function(index) {
      if (this.forms[index].project_name in this.selectBoxData.data) {
        this.selectBoxData.data[this.forms[index].project_name].some(e => {
          if (e.order_name_en == this.forms[index].order_name_en) {
            this.forms[index].order_name_jp = e.order_name_jp;
            this.forms[index].order_id = e.id;
            return true;
          }
        });
      }
      this.updateRules();
    },
    dateFormat: function(date) {
      var d = new Date(date);
      var formatted = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
        .getDate()
        .toString()
        .padStart(2, '0')}`.replace(/\n|\r/g, '');
      return formatted;
    },
    today: function() {
      return this.dateFormat(new Date());
    },
    getPreviousReport: async function() {
      let url = this.editPage ? this.$route.path : '/morning-reports/form/previous-report';
      this.loadingReport = true;
      try {
        const res = await getAll(url, { previousReportId: this.selectedReport });
        console.log(res);
        if (res != null) {
          if (this.editPage) {
            this.date = res.date.substr(0, 10);
          }
          this.numberOfForm = 0;
          this.forms = [];
          res.morning_report_items.forEach(element => {
            this.forms.push({
              ...this.formDataTemplate,
            });
            this.forms[this.numberOfForm].project_name = this.selectBoxData.project_names.includes(
              element.order.project.name
            )
              ? element.order.project.name
              : null;
            this.changeProjectName(this.numberOfForm);
            this.forms[this.numberOfForm].order_name_jp = this.forms[this.numberOfForm].order_names_jp.includes(
              element.order.name_jp
            )
              ? element.order.name_jp
              : null;
            this.forms[this.numberOfForm].order_name_en = this.forms[this.numberOfForm].order_names_en.includes(
              element.order.name_en
            )
              ? element.order.name_en
              : null;
            console.log(element.id);
            this.forms[this.numberOfForm].order_id = element.order.id;
            this.forms[this.numberOfForm].time = element.time_in_hours;
            this.forms[this.numberOfForm].progress = element.progress;
            this.numberOfForm++;
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingReport = false;
      }
    },
    retrieveAllReport: async function() {
      let url = '/morning-reports/form/all-previous-report';
      this.loadingReport = true;
      try {
        const res = await getAll(url);
        // console.log(res);
        if (res != null) {
          this.previousReport = res;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingReport = false;
      }
    },
    retrieveSelectBoxData: async function() {
      let url = '/morning-reports/form/select-box';
      this.loadingComponent = true;
      try {
        const res = await getAll(url);
        res.forEach(e => {
          if (!Array.isArray(this.selectBoxData.data[e.project_name])) this.selectBoxData.data[e.project_name] = [];
          this.selectBoxData.data[e.project_name].push({
            id: e.id,
            order_name_jp: e.order_name_jp,
            order_name_en: e.order_name_en,
          });
        });
        this.selectBoxData.project_names = Object.keys(this.selectBoxData.data);
        console.log(this.selectBoxData);
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComponent = false;
      }
    },
    checkIsSubmittable: async function() {
      if (!this.date) return;
      this.isSubmittable = false;
      this.isChecking = true;
      const url = '/morning-reports/form/isSubmittable';
      const payload = {
        id: this.$route.params.id,
        date: this.date,
      };
      // console.log(payload);
      try {
        const res = await Vue.axios.post(url, payload);
        this.isSubmittable = res.data.data;
      } catch (err) {
        console.log(err);
      }
      this.isChecking = false;
    },
    getRemainingCharacter(index) {
      if (this.forms[index].progress != null) {
        return 2000 - this.forms[index].progress.length;
      } else {
        return 2000;
      }
    },
    messageRemainingCharacter(index) {
      this.rules[index] = this.defaultRules;
      if (this.forms[index].progress == null || this.forms[index].progress == '') {
        return this.$t('general.morningReports.message.progressRequired');
      } else if (this.forms[index].progress.length >= 2000) {
        return this.$t('general.morningReports.message.progressLength');
      } else {
        if (this.$i18n.locale == 'en') {
          return this.getRemainingCharacter(index) + this.$t('general.morningReports.remaining');
        } else {
          return (
            this.$t('general.morningReports.remaining') + this.getRemainingCharacter(index) + this.$t('general.morningReports.letter')
          );
        }
      }
    },
    saveDraft() {
      // this.$cookie.set('report', JSON.stringify(this.forms));
      localStorage.setItem('report', JSON.stringify(this.forms));
    },
    loadDraft() {
      // var data = JSON.parse(this.$cookie.get('report'));
      var data = JSON.parse(localStorage.getItem('morningReport'));
      var dataLength = data.length;
      this.forms = [];
      this.rules = [];
      this.forms = data;
      this.numberOfForm = dataLength;
      for (let i = 0; i < dataLength; i++) {
        i == 0 ? this.rules.push(this.defaultRules) : this.rules.push(this.noRules);
      }
    },
    loadReportItem() {
      var data = JSON.parse(localStorage.getItem('morningReportItem'));
      if (data == null) return;
      var dataLength = data.length;
      this.forms = [];
      this.rules = [];
      this.forms = data;
      this.date = data[0].date;
      this.numberOfForm = dataLength;
      for (let i = 0; i < dataLength; i++) {
        i == 0 ? this.rules.push(this.defaultRules) : this.rules.push(this.noRules);
      }

      // Remove reportItem from localStorage
      localStorage.removeItem('reportItem');
    },
    retriveNotAvailableOrder: async function() {
      let url = '/morning-reports/form/get-orders';
      this.loadingComponent = true;
      try {
        const res = await getAll(url);
        this.newProjectForms.orderData = res;
        // group by project id
        res.forEach(e => {
          let project = {
            value: e.project.id,
            text: e.project.name,
          };
          if (this.newProjectForms.projects.length == 0) {
            this.newProjectForms.projects.push(project);
          } else {
            let isExist = false;
            this.newProjectForms.projects.forEach(p => {
              if (p.value == project.text) {
                isExist = true;
              }
            });
            if (!isExist) {
              this.newProjectForms.projects.push(project);
            }
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComponent = false;
      }
    },
    changeNewProjectName() {
      this.newProjectForms.orders = [];
      this.newProjectForms.orderData.forEach(e => {
        if (e.project.id == this.newProjectForms.project_name) {
          this.newProjectForms.orders.push({
            value: e.id,
            text: e.name_en,
          });
        }
      });
    },
    async saveNewOrder() {
      let url = '/morning-reports/form/assign-new-order';
      this.loadingComponent = true;
      try {
        await store(url, {
          order_id: this.newProjectForms.order_id,
        });
        this.newProjectForms.order_id = null;
        this.newProjectForms.project_name = null;
        this.dialog = false;
        await this.retrieveSelectBoxData();
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComponent = false;
      }
    },
  },
  watch: {
    date() {
      this.checkIsSubmittable();
    },
  },
};
</script>

<style scoped>
.v-data-table th {
  border: 0;
}

.v-data-table td {
  border: 0;
}

.v-pagination__navigation {
  display: none;
}

.text-notification {
  color: red;
  font-size: 0.75rem;
}
</style>
