var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"daily-report"},[_c('p',{staticClass:"mx-1 mb-0 text-caption text--secondary"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(_vm._s(_vm.$t('general.nav.dashboard')))]),_c('span',{staticClass:"mx-2"},[_vm._v(">")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.reports.list.pageTitle')))])],1),_c('PageInnerSet',{attrs:{"title":_vm.$t('general.reports.list.pageTitle')}},[_c('v-form',{ref:"filter"},[_c('v-row',{attrs:{"cols":"12"}},[_c('DateSearch',{attrs:{"activeFilters":_vm.activeFilters,"rules":_vm.rules,"DateType":'date'}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('FilterTextSelect',_vm._b({attrs:{"title":_vm.$t('general.reports.projectName') + '：',"title_width":4,"items":_vm.selectBoxData.project_names},model:{value:(_vm.activeFilters.project_names),callback:function ($$v) {_vm.$set(_vm.activeFilters, "project_names", $$v)},expression:"activeFilters.project_names"}},'FilterTextSelect',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.activeFilters.project_names ? _vm.tooltipItem(_vm.activeFilters.project_names) : ''))])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[(_vm.isLocaleJapanese)?_c('FilterTextSelect',_vm._b({attrs:{"title":_vm.$t('general.reports.orderName') + '：',"title_width":2,"items":_vm.selectBoxData.order_names_jp},model:{value:(_vm.activeFilters.order_names_jp),callback:function ($$v) {_vm.$set(_vm.activeFilters, "order_names_jp", $$v)},expression:"activeFilters.order_names_jp"}},'FilterTextSelect',attrs,false)):_c('FilterTextSelect',_vm._b({attrs:{"title":_vm.$t('general.reports.orderName') + '：',"title_width":2,"items":_vm.selectBoxData.order_names_en},model:{value:(_vm.activeFilters.order_names_en),callback:function ($$v) {_vm.$set(_vm.activeFilters, "order_names_en", $$v)},expression:"activeFilters.order_names_en"}},'FilterTextSelect',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isLocaleJapanese ? _vm.tooltipItem(_vm.activeFilters.order_names_jp) : _vm.tooltipItem(_vm.activeFilters.order_names_en)))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"pt-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetFilter}},[_vm._v(_vm._s(_vm.$t('general.form.reset')))])],1)])],1)],1),_c('br'),_c('div',{staticClass:"mb-3 font-weight-bold text-h6"},[_vm._v(" "+_vm._s(_vm.$t('general.reports.totalWorkingTime'))+" : "+_vm._s(_vm.totalWorkingHours)+"H ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"options":_vm.options,"loading":_vm.isLoadingTableData,"loading-text":_vm.$t('general.crud.loading'),"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":"700px","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"title text--black"},[_vm._v(" "+_vm._s(_vm.reports.indexOf(item) != 0 ? _vm.reports[_vm.reports.indexOf(item) - 1].report_id == item.report_id ? '' : item.date : item.date)+" ")]),(_vm.reports.indexOf(item) == 0 || _vm.reports[_vm.reports.indexOf(item) - 1].report_id != item.report_id)?_c('v-btn',{attrs:{"to":{ name: 'reports.edit', params: { id: item.report_id } },"depressed":"","color":"primary","width":"100%","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('general.crud.edit'))+" ")]):_vm._e()]}},{key:"item.total_hours",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"title text--black"},[_vm._v(" "+_vm._s(_vm.reports.indexOf(item) != 0 ? _vm.reports[_vm.reports.indexOf(item) - 1].report_id == item.report_id ? '' : item.total_hours : item.total_hours)+" ")])]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_name)+" ")]}},{key:"item.order_name",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.order_name_jp))]),_c('p',[_vm._v(_vm._s(item.order_name_en))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time)+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.progress)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }