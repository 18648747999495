<template>
  <v-dialog v-model="isOpen" width="1100">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" small>
        {{ openButton }}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="acceptDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <div class="pt-5 px-5">
          <slot></slot>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    /* Specify callback when save is clicked
     * For example, if we want to trigger api call, when save is clicked
     * put the method on cb
     */
    title: String,
    openButton: String,
    accept: {
      type: Object,
      default: function() {
        return {
          text: 'Save',
          cb: () => {},
        };
      },
    },
    form: {
      /* Specify text for open button and title */
      type: Object,
      default: function() {
        return {
          openText: 'Open',
          title: 'Form',
        };
      },
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    async acceptDialog() {
      await this.accept.cb();
      this.isOpen = false;
    },
  },
};
</script>
