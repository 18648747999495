<template>
  <div class="member-dialog">
    <v-dialog v-model="dialogOpen" max-width="800px" scrollable @click:outside="closeDialog">
      <v-card>
        <v-card-title>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="text-h5">{{ $t('general.schedule.member') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- back button  -->
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <!-- filter buttons  -->
          <div class="text-left mt-3">
            <v-btn @click="selectedAll" density="compact" class="mr-3">All</v-btn>
            <v-btn @click="selectedJapanese" density="compact" class="mr-3">JAPAN</v-btn>
            <v-btn @click="selectedIdn" density="compact" class="mr-3">IDN</v-btn>
            <v-btn @click="selectedReset" density="compact">Reset</v-btn>
          </div>
          <!-- data tables  -->
          <div class="mt-3 data-tables">
            <v-simple-table class="table-with-border table table-border" dense>
              <thead>
                <tr>
                  <th>JAPAN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="member in members.japan" :key="member.id">
                  <td>
                    <v-checkbox
                      v-model="selectedMembers"
                      :label="member.name"
                      :value="member.id"
                      density="compact"
                      class="checkbox-with-ellipsis"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table class="table-with-border table table-border" dense>
              <thead>
                <tr>
                  <th>IDN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="member in members.idn" :key="member.id">
                  <td>
                    <v-checkbox
                      v-model="selectedMembers"
                      :label="member.name"
                      :value="member.id"
                      density="compact"
                      class="checkbox-with-ellipsis"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAll } from '@services/crud';

export default {
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      members: {
        japan: [],
        japanIds: [],
        idn: [],
        idnIds: [],
      },
      selectedMembers: [],
    };
  },
  watch: {
    selectedMembers: function(newMember, oldMember) {
      console.log('watching selectedMembers', newMember);
      this.$emit('memberChange', newMember);
      this.$emit('getData');
    },
    deep: true,
  },
  created() {
    this.initSelectedMember();
    this.getMembers();
  },
  methods: {
    closeDialog: function() {
      this.$emit('dialogClose');
    },
    initSelectedMember: async function() {
      console.log('Active initSelectedMember function');
      // const user = await this.getUser();
      // console.log('member dialog user', user);
      // const user_id = user.id;

      // get query string ?member=1,2,3
      const urlParams = new URLSearchParams(window.location.search);
      const memberIds = urlParams.get('member');
      console.log('memberIds', memberIds);
      if (memberIds) {
        // split to array number
        this.selectedMembers = memberIds.split(',').map(Number);
      } else {
        const user = await this.getUser();
        const user_id = user.id;
        this.selectedMembers = [user_id];
      }

      // this.selectedMembers = [];
      const newMember = this.selectedMembers;
      this.$emit('memberChange', newMember);
      this.$emit('getData');
    },
    /**
     * Get user ID. I want to set id in data or vuex but I think it is bad from a security point of view. Please change if you don't mind
     */
    getUser: async function() {
      let url = '/members/get-user';
      console.log('Active getUser function');
      const username = this.$store.state.auth.info.username;
      try {
        const res = await getAll(url, {
          username,
        });
        console.log('res', res);
        return res[0];
      } catch (error) {
        console.log('-Error of getUser function');
        console.log(error);
      }
    },
    /**
     * Get members of initial select box
     */
    getMembers: async function() {
      console.log('Active getMembers2');
      let url = '/schedule/getMembers';

      try {
        const res = await getAll(url);
        console.log('get members res', res);
        this.members.japan = res.japan_members;
        this.members.japanIds = res.japan_members_id;
        this.members.idn = res.idn_members;
        this.members.idnIds = res.idn_members_id;
        console.log('this.members', this.members);
      } catch (error) {
        console.log('-Error of getMembers function');
        console.log(error);
      }
    },
    // filter Functions
    selectedJapanese() {
      this.selectedMembers = this.members.japanIds;
    },
    selectedIdn() {
      this.selectedMembers = this.members.idnIds;
    },
    selectedAll() {
      this.selectedMembers = [...this.members.japanIds, ...this.members.idnIds];
    },
    selectedReset: async function() {
      this.$emit('changeMember', []);
      this.selectedMembers = [];
      const user = await this.getUser();
      const user_id = user.id;
      this.selectedMembers = [user_id];
    },
  },
};
</script>

<style lang="scss">
.data-tables {
  display: flex;
}
.v-card__title {
  background-color: #f5f5f5;
}
header {
  background-color: #f5f5f5 !important;
}
.table-with-border {
  width: 50%;
  thead {
    border: solid;
    border-width: 1px;
    background-color: #d9d9d9;
  }
  tbody {
    border: solid #d9d9d9;
    border-width: 1px;
    td {
      padding: 0;
      max-width: 300px;
      .v-input--selection-controls {
        margin-top: 8px;
      }
      .v-input__slot {
        padding: 0 !important;
      }
      .v-messages {
        display: none;
      }
      .checkbox-with-ellipsis label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
