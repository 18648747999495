<template>
  <router-link :to="{ name: toLink }">
    <slot>link</slot>
  </router-link>
</template>

<script>
  export default {
    props: {
      toLink: String,
    },
  }
</script>