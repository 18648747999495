<template>
  <tr v-if="display" class="table-row" :class="index.id == project.id ? 'border-bottom' : ''">
    <!-- Project name - Start -->
    <th scope="row" class="column-label text-left" :style="styles">
      <div class="row">
        <div class="col-4 align-self-center pl-1 pr-0">
          <i class="fas fa-diamond fa-lg" :style="{ color: color }"></i>
          {{ project.id }}
        </div>
        <div class="col pl-0 pr-1">
          {{ project.name }}
        </div>
      </div>
    </th>
    <!-- Project name - End -->

    <!-- Member hour for each project perday - Start -->
    <MemberProjectCell
      v-for="date in headers"
      :key="date"
      :date="date"
      :color="color"
      :project="project"
      :reports="projectSchedules"
    />
    <!-- Member hour for each project perday - Start -->
  </tr>
</template>

<script>
import { filter } from 'lodash';
import MemberProjectCell from './member/ProjectCell';

export default {
  props: {
    member: { type: Object, default: () => {} },
    headers: { type: Array, default: () => [] },
    project: { type: Object, default: () => {} },
    schedules: { type: Array, default: () => [] },
    color: { type: String, default: 'transparent' },
    index: { type: Object },
    drawerOpen: { type: Boolean, default: true },
    display: { type: Boolean },
  },

  components: {
    MemberProjectCell,
  },

  computed: {
    // Member schedules filtered by project
    projectSchedules() {
      const project = this.project;
      return filter(this.schedules, function(schedule) {
        return schedule.project_id === project.id;
      });
    },

    styles() {
      return {
        // backgroundColor: this.color,
        left: this.drawerOpen == true ? '0' : '0',
      };
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 2px solid rgb(175, 175, 175) !important;
}
</style>
