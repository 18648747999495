import constants from "@/const/const";
import Datatable from '@views/main/unit_prices/Datatable';

let nonHQ = [
    constants.permissions.restrictedUserString, 
    constants.permissions.restrictedAdminString];

const unitPriceRoutes = [
  {
    path: 'unitprices',
    name: 'unit_prices',
    component: Datatable,
    meta: {
      //only Japanese team(HQ) can see this page
      restricted: nonHQ 
    },
  },
];

export default unitPriceRoutes;