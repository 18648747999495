<template>
  <v-row class="nav-arrow" align="center" justify="space-between">
    <v-btn @click.prevent="previousPage">
      <v-icon x-large>mdi-chevron-left</v-icon>
    </v-btn>

    <p class="mb-0">
      <span v-if="loading">Loading...</span>
      <span v-else>{{ $startDate.format('MMMM Do') }} - {{ $endDate.format('MMMM Do') }}</span>
    </p>

    <v-btn @click.prevent="nextPage">
      <v-icon x-large>mdi-chevron-right</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
  export default {
    props: {
      startDate: { type: String, default: undefined },
      endDate: { type: String, default: undefined },
      loading: { type: Boolean, default: false },
    },
    computed: {
      // Start date moment
      $startDate() {
        return this.$moment(this.startDate);
      },
  
      // End date moment
      $endDate() {
        return this.$moment(this.endDate);
      },
    },
    methods: {
      previousPage() {
        const format = 'YYYY-MM-DD';
        const startDate = this.$startDate.subtract(14, 'days').format(format);
        const endDate = this.$endDate.subtract(14, 'days').format(format);

        const query = { ...this.$route.query, ...{ startDate, endDate } };
        this.$router.push({ name: 'assignmentSheet', query });
      },

      // Navigate to next page
      nextPage() {
        const format = 'YYYY-MM-DD';
        const startDate = this.$startDate.add(14, 'days').format(format);
        const endDate = this.$endDate.add(14, 'days').format(format);

        const query = { ...this.$route.query, ...{ startDate, endDate } };
        this.$router.push({ name: 'assignmentSheet', query });
      },
    },
  }
</script>
