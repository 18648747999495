<template>
  <div>
    <!-- {{duplicateCheckInstances}} -->
    <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"> </v-skeleton-loader>

    <div v-if="!loadingComponent">
      <!-- Breadcrumbs -->
      <p class="mx-1 mb-0 text-caption text--secondary">
        <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
        <span class="mx-2">></span>
        <span>{{ editPage ? $t('general.order.edit.pageTitle') : $t('general.order.create.pageTitle') }}</span>
      </p>

      <!-- Page container -->
      <PageInnerSet :title="editPage ? $t('general.order.edit.pageTitle') : $t('general.order.create.pageTitle')" class="pb-16">
        <v-form ref="orderForm" @submit.prevent="submit" id="form">
          <v-container class="px-2 md:px-10" style="max-width: 1500px; margin-right: auto; margin-left: 0">
            <PageInnerSet :title="$t('general.order.projects')" container>
              <!-- project projectName -->
              <g-input-group required :title="$t('general.order.projectName')">
                <v-combobox
                  outlined
                  v-model="inputData.project.name"
                  :items="formData.projects"
                  :rules="rules.projectNameRule"
                  :item-value="'id'"
                  :item-text="'name'"
                  @change="onChangeProjectName()"
                ></v-combobox>
              </g-input-group>

              <!-- project type -->
              <g-input-group :title="$t('general.order.type')">
                <v-select
                  outlined
                  disabled
                  append-icon=""
                  v-model="inputData.project.project_type_id"
                  :items="formData.projectTypes"
                  :item-text="this.isLocaleJapanese() ? 'name_jp' : 'name_en'"
                  item-value="id"
                ></v-select>
              </g-input-group>

              <!-- project customerName -->
              <g-input-group :title="$t('general.order.customer')">
                <v-select
                  outlined
                  disabled
                  append-icon=""
                  v-model="inputData.project.customer_id"
                  :items="formData.customerNames"
                  :item-text="'name'"
                  :item-value="'id'"
                ></v-select>
              </g-input-group>
            </PageInnerSet>

            <PageInnerSet :title="$t('general.order.orders')" container>
              <br />
              <!-- Order Type -->
              <g-input-group required :title="$t('general.order.type')" >
                <div style="display: flex; flex-wrap: wrap;">
                  <div v-for="item in formData.orderTypes" :key="'orderType' + item.id" style="flex-shrink: 0;">
                    <input
                      type="radio"
                      :key="'orderTypeRadio' + item.id"
                      :id="'orderTypeRadio' + item.id"
                      :value="item.id"
                      v-model="inputData.order.type"
                      :disabled="disabledForm"
                      class="mr-2"
                    />
                    <label :key="'orderTypeLabel' + item.id" :for="'orderTypeRadio' + item.id" class="mr-7">{{ isLocaleJapanese() ? item.name_jp : item.name_en }}</label>
                  </div>
                </div>
              </g-input-group>
              <!-- set is_active order of project -->
              <g-input-group :title="$t('general.order.is_active')" classTop="" classBottom="">
                <input type="radio" id="active" value="1" v-model="inputData.order.is_active" class="mr-2" />
                <label for="active" class="mr-7">{{ $t('general.order.active') }}</label>
                <input type="radio" id="unactive" value="0" v-model="inputData.order.is_active" class="mr-2" />
                <label for="unactive">{{ $t('general.order.unactive') }}</label>
              </g-input-group>
              <g-input-group :title="$t('general.order.orderName')" :centered="false">
                <!-- order orderName jp -->
                <g-input-group required :title="$t('general.order.jp')">
                  <v-text-field
                    outlined
                    clearable
                    v-model="inputData.order.name_jp"
                    :rules="rules.orderNameJpRule"
                  ></v-text-field>
                </g-input-group>

                <!-- order orderName en -->
                <g-input-group required :title="$t('general.order.en')">
                  <v-text-field
                    outlined
                    clearable
                    v-model="inputData.order.name_en"
                    :rules="rules.orderNameEnRule"
                  ></v-text-field>
                </g-input-group>
              </g-input-group>

              <!-- order status -->
              <g-input-group required :title="$t('general.order.status')">
                <v-select
                  outlined
                  v-model="inputData.order.order_status_id"
                  :items="formData.orderStatus"
                  :item-text="this.isLocaleJapanese() ? 'name_jp' : 'name_en'"
                  :rules="rules.statusRule"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <v-list>
                      <span
                        class="highlight"
                        :style="{
                          'background-color': orderStatusColors.bgColor[data.item.name_en],
                          color: orderStatusColors.textColor[data.item.name_en],
                        }"
                      >
                        {{ isLocaleJapanese() ? data.item.name_jp : data.item.name_en }}
                      </span>
                    </v-list>
                  </template>
                  <template v-slot:item="data">
                    <v-list>
                      <span
                        class="highlight"
                        :style="{
                          'background-color': orderStatusColors.bgColor[data.item.name_en],
                          color: orderStatusColors.textColor[data.item.name_en],
                        }"
                      >
                        {{ isLocaleJapanese() ? data.item.name_jp : data.item.name_en }}
                      </span>
                    </v-list>
                  </template>
                </v-select>
              </g-input-group>

              <!-- Issuer -->
              <g-input-group :title="$t('general.order.issuer')">
                <v-checkbox v-model="inputData.order.issuer" :label="$t('general.order.officeWork')"></v-checkbox>
              </g-input-group>

              <!-- Order Acceptance Date -->
              <g-input-group :title="$t('general.invoice.acceptanceDate')">
                <CustomDatePicker v-model="inputData.order.acceptance_date"></CustomDatePicker>
              </g-input-group>


              <g-input-group :title="$t('general.order.members')" :centered="false">
                <g-input-group :title="$t('general.order.memberJpn')" :centered="false">
                  <!-- order pm -->
                  <g-input-group required :title="$t('general.order.pm')">
                    <v-autocomplete
                      outlined
                      v-model="inputData.order.pm_id"
                      :items="formData.pm"
                      :item-text="'name'"
                      :item-value="'pm_id'"
                      :rules="rules.pmRule"
                    ></v-autocomplete>
                  </g-input-group>
                  <!-- order jpn member -->
                  <v-row id="jpn">
                    <!-- <v-col cols="12" md="6" v-for="(member, index) in inputData.order.jpnMemberList" :key="index"> -->
                    <v-col cols="12" md="6" v-for="j in jpNumberForm" :key="j">
                      <v-autocomplete
                        outlined
                        clearable
                        v-model="inputData.order.jpnMemberList[j-1].id"
                        :items="formData.jpn_member"
                        :item-text="'name'"
                        :item-value="'id'"
                        :rules="[memberRules(j-1, 1)]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <hr color="#aaaaaa" style="max-width: 1500px" />
                  <v-row>
                    <v-col>
                      <div class="text-right pt-3 pb-5">
                        <v-btn color="primary" @click="addNewMember('jpn')">
                          {{ $t('general.order.add') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </g-input-group>
                <g-input-group :title="$t('general.order.memberIdn')" :centered="false">
                  <!-- order idn member -->
                  <v-row id="idn">
                    <!-- <v-col cols="12" md="6" v-for="(member, index) in inputData.order.idnMemberList" :key="index"> -->
                    <v-col cols="12" md="6" v-for="n in idNumberForm" :key="n">
                      <v-autocomplete
                        outlined
                        clearable
                        v-model="inputData.order.idnMemberList[n-1].id"
                        :items="formData.idn_member"
                        :item-text="'name'"
                        :item-value="'id'"
                        :rules="[memberRules(n-1, 2)]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <hr color="#aaaaaa" style="max-width: 1500px" />
                  <v-row>
                    <v-col>
                      <div class="text-right pt-3 pb-5">
                        <v-btn color="primary" @click="addNewMember('idn')">
                          {{ $t('general.order.add') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </g-input-group>
              </g-input-group>
            </PageInnerSet>

            <PageInnerSet :title="$t('general.order.invoicingInfomation')" container>
              <br />
              <div v-for="(invoicing, index) in inputData.invoiceInfomationList" :key="index" class="form">
                <!-- invoicingInfomation Price -->
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- invoicingInfomation invoiceAmount -->
                    <g-input-group :required="isInvoiceInput()" :title="$t('general.order.invoiceAmount')">
                      <v-row id="invoiceAmount">
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            clearable
                            v-model="invoicing.invoice_amount"
                            :rules="[invoiceAmountRule]"
                            @focus="inputInvoiceBeforeTax($event.target.value, index, true)"
                            @input="inputInvoiceBeforeTax($event, index, true)"
                            @change="inputInvoiceBeforeTax($event, index)"
                            append-icon="円"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="2">{{ $t('general.order.yen') }}</v-col> -->
                      </v-row>
                    </g-input-group>
                  </v-col>

                  <!-- invoicingInfomation invoiceAmountWithTax -->
                  <v-col cols="12" md="6">
                    <div class="d-flex flex-column">
                      <g-input-group :required="isInvoiceInput()" :title="$t('general.order.invoiceAmountWithTax')">
                        <v-row id="invoiceAmountWithTax">
                          <v-col cols="12" class="pb-0">
                            <v-text-field
                              outlined
                              clearable
                              hide-details
                              v-model="invoicing.invoice_amount_with_tax"
                              :rules="[invoiceAmountRule]"
                              :disabled="!invoicing.invoice_amount_with_tax_is_manual"
                              @focus="thousandFormatInvoiceTax($event.target.value, index, true)"
                              @change="thousandFormatInvoiceTax($event, index)"
                            append-icon="円"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="2">{{ $t('general.order.yen') }}</v-col> -->
                        </v-row>
                      </g-input-group>
                      <g-input-group>
                        <v-row class="pt-0 mt-0">
                          <v-col cols="12" class="pt-0">
                            <div>
                              <v-checkbox
                                hide-details
                                :label="$t('general.order.manuallyInputPriceWithTax')"
                                v-model="invoicing.invoice_amount_with_tax_is_manual"
                                @change="toggleTaxInput(index)"
                                class="py-0 my-0"
                              ></v-checkbox>
                              <p class="text-caption py-0 my-0">{{ $t('general.order.invoiceAmountWithTaxWarning') }}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </g-input-group>

                    </div>
                  </v-col>
                  <!-- End - invoicingInfomation invoiceAmountWithTax -->
                </v-row>
                <!-- End - invoicingInfomation Price -->

                <!-- invoicingInfomation Dates -->
                <v-row>
                  <v-col cols="12" md="4">
                    <!-- invoicingInfomation invoiceDate -->
                    <g-input-group
                      :required="isInvoiceInput()"
                      :title="$t('general.order.invoiceDate')"
                    >
                      <CustomDatePicker
                        v-model="invoicing.invoice_date"
                        :rules=[invoiceDateRule]
                      ></CustomDatePicker>
                    </g-input-group>
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- invoicingInfomation paymentDeadline -->
                    <g-input-group
                      :required="isInvoiceInput()"
                      :title="$t('general.order.paymentDeadline')"
                    >
                      <CustomDatePicker
                          v-model="invoicing.payment_deadline"
                          :rules=[paymentDeadlineRule]
                      ></CustomDatePicker>
                    </g-input-group>
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- invoicingInfomation paymentDate -->
                    <g-input-group :title="$t('general.order.paymentDate')">
                      <CustomDatePicker
                          v-model="invoicing.payment_date"
                      ></CustomDatePicker>
                    </g-input-group>
                  </v-col>
                </v-row>
                <!-- End - invoicingInfomation Dates -->

                <v-row>
                  <v-col>
                    <!-- invoicingInfomation PurchaseOrderUrl -->
                    <g-input-group :title="$t('general.order.purchaseOrderUrl')">
                      <v-text-field
                      outlined
                      clearable
                      v-model="invoicing.purchase_order_url"
                      :rules="[purchaseOrderUrlRule]"
                      ></v-text-field>
                    </g-input-group>
                  </v-col>
                  <v-col>
                    <!-- invoicingInfomation invoiceUrl -->
                    <g-input-group :title="$t('general.order.invoiceUrl')">
                      <v-text-field
                      outlined
                      clearable
                      v-model="invoicing.invoice_url"
                      :rules="[invoiceUrlRule]"
                      ></v-text-field>
                    </g-input-group>
                  </v-col>
                </v-row>

                <!-- outsourcingCost -->
                <g-input-group :title="$t('general.order.outsourcingCost')" :centered="false">
                  <div v-for="(outsourcing, index) in invoicing.outsourcingCostList" :key="outsourcing.id" class="form">
                    <g-input-group :title="$t('general.order.itemName')"
                      :required="isOutsourcingCostInput(outsourcing)">
                      <v-text-field
                        outlined
                        clearable
                        v-model="outsourcing.item_name"
                        :rules="[outsourcingItemNameRule(outsourcing)]"
                      ></v-text-field>
                    </g-input-group>

                    <g-input-group :title="$t('general.order.paymentAmount')">
                      <v-row id="invoiceAmount">
                        <v-col>
                          <v-text-field
                          outlined
                          clearable
                          :rules="[outsourcingAmountRule]"
                          v-model="outsourcing.payment_amount"
                          @focus="thousandFormatOutCost(outsourcing.payment_amount, index, true)"
                          @change="thousandFormatOutCost(outsourcing.payment_amount, index)"
                          append-icon="円"
                          >
                        </v-text-field>
                        </v-col>
                        <!-- <v-col>{{ $t('general.order.yen') }}</v-col> -->
                      </v-row>
                    </g-input-group>

                    <g-input-group :title="$t('general.order.paymentDate')">
                      <CustomDatePicker
                          v-model="outsourcing.payment_date"
                        ></CustomDatePicker>
                    </g-input-group>

                    <g-input-group :title="$t('general.order.invoiceUrl')">
                      <v-text-field
                        outlined
                        clearable
                        v-model="outsourcing.invoice_url"
                        :rules="[outsourcingInvoiceUrlRule]"
                      ></v-text-field>
                    </g-input-group>
                  </div>
                </g-input-group>

                <div class="text-right pt-3 pb-5">
                  <v-btn v-on:click="addOutsoucingCost(index)">{{ $t('general.order.add') }}</v-btn>
                </div>

                <v-divider class="mb-3 pb-5"></v-divider>
              </div>

              <div class="text-right pb-10" v-if="inputData.order.type == 1">
                <v-btn v-on:click="addInvoicingInformation">{{ $t('general.order.add') }}</v-btn>
              </div>
            </PageInnerSet>

            <g-input-group :title="$t('general.order.remarks')">
              <v-textarea outlined clearable v-model="inputData.order.remarks" :rules="rules.remarksRule"></v-textarea>
            </g-input-group>

            <div class="pt-10">
              <v-btn type="submit" :disabled="disabledButton">{{ $t('general.order.submit') }}</v-btn>
            </div>
          </v-container>
        </v-form>
      </PageInnerSet>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { store, getForm, update } from '@services/crud';
import GInputGroup from '@components/form_input/GInputGroup.vue';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import moment from 'moment';

import constants from '@/const/const';
import colors from '../assignment-v2/colors';

import CustomDatePicker from '@components/form_input/CustomDatePicker.vue';

export default {
  data() {
    return {
      //defined rules for order page.
      rules: {
        projectNameRule: [
          (v) => !!v || this.$t('general.order.message.projectNameRequired'),
          (v) => (v && this.matchProjectName(v)) || this.$t('general.order.message.projectNameCheck'),
        ],
        orderNameJpRule: [
          (v) => !!v || this.$t('general.order.message.jpRequired'),
          (v) => (v && v.length >= 3) || this.$t('general.order.message.jpMinCharacters'),
        ],
        orderNameEnRule: [(v) => (v && v.length >= 8) || this.$t('general.order.message.enMinCharacters')],
        statusRule: [(v) => !!v || this.$t('general.order.message.statusRequired')],
        acceptanceDateRule: [(v) => !!v || this.$t('general.order.message.statusRequired')],
        pmRule: [(v) => !!v || this.$t('general.order.message.pmRequired')],
        invoiceAmountRule: [(v) => /^[0-9,]+$/.test(v) || this.$t('general.order.message.invoiceAmountMinCharacters')],
        remarksRule: [
          (v) => (!v ? true : v.length <= 510 ? true : this.$t('general.order.message.remarksMaxCharacters')),
        ],
      },
      //formData is for items
      formData: {},
      disabledButton: false,
      disabledForm: false,

      inputData: {
        project: {
          id: null,
          project_type_id: null,
          name: '',
          customer_id: null,
        },
        order: {
          type: 0,
          is_active: 1,
          issuer: 0,
          //jpnMemberList,idnMemberList: create at created()
        },
        invoiceInfomationList: [
          {
            invoice_amount: '',
            invoice_amount_with_tax: '',
            invoice_amount_with_tax_is_manual: false,
            invoice_date: '',
            payment_deadline: '',
            payment_date: '',
            purchase_order_url: '',
            invoice_url: '',
            outsourcingCostList: [
              {
                item_name: '',
                payment_amount: '',
                payment_date: '',
                invoice_url: '',
              },
            ],
          },
        ],
      },
      editPage: false,
      submitUrl: '',
      loadingComponent: false,
      orderStatusColors: constants.orderStatusColors,
      datePickerLanguageSetting: constants.datePickerLanguageSetting,
      log_type: 3,
      jpNumberForm: 8,
      idNumberForm: 8,
    };
  },
  methods: {
    //When user click 'submit' button, this method will be used.
    async submit() {
      if (this.$refs.orderForm.validate()) {
        this.disabledButton = true;
        let options = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        let jpnMemberIdList = this.inputData.order.jpnMemberList.map((element) => {
          return element.id ?? null;
        });
        let idnMemberIdList = this.inputData.order.idnMemberList.map((element) => {
          return element.id ?? null;
        });
        // let outsourcingCostList = this.inputData.outsourcingCostList.map(element =>{ return element ?? null });
        let invoiceInformationList = this.inputData.invoiceInfomationList.map((element) => {
          return element ?? null;
        });

        let orderId;
        if (this.editPage) {
          orderId = this.$route.params.id;
          this.log_type = 1;
        }

        let payload = {
          project_id: this.inputData.project.id, // projectId: 3,
          name_jp: this.inputData.order.name_jp, // name_jp: sample order name,
          name_en: this.inputData.order.name_en, // name_en: sample order name,
          order_status_id: this.inputData.order.order_status_id, // order_status_id: 1,
          acceptance_date: this.inputData.order.acceptance_date, // acceptance_date,
          pm_id: this.inputData.order.pm_id, // pm_id: 2,
          remarks: this.inputData.order.remarks, // remarks: sample remarks,
          type: this.inputData.order.type, // remarks: sample remarks,
          is_active: this.inputData.order.is_active, // is_active: default true on create,
          invoice_information_id: this.inputData.order.invoice_information_id, // is_active: default true on create,
          jpnMemberIdList: jpnMemberIdList, // jpnMemberIdList: [1,2,12,14]
          idnMemberIdList: idnMemberIdList, // idnMemberIdList: [1,2,12,14]
          invoiceInformationList: invoiceInformationList,
          id: orderId,
          issuer: this.inputData.order.issuer,
          log_types: this.log_type,
          updated_by: this.$store.state.auth.info.username,
        };
        const res = this.editPage
          ? await update(this.submitUrl, payload, options)
          : await store(this.submitUrl, payload, options);
        this.loadingComponent = false;
        this.disabledButton = false;
        if (res) {
          this.$router.push({ name: 'management.invoices', query: this.$route.query });
        }
      } else {
        // Scroll to error input
        this.$nextTick(() => {
          const el = document.querySelector('.v-messages.error--text'); //get the first error
          let elementPosition = el.getBoundingClientRect().top; // get element top position
          let offsetPosition = elementPosition + 2000;
          window.scrollTo({ top: offsetPosition, behavior: 'smooth' }); //scroll to target
        });
      }
    },
    isLocaleJapanese: function () {
      return this.$store.state.global.locale === 'ja';
    },
    //When user click 'add' button, this method will be used.
    addOutsoucingCost: function (index) {
      const outsourcingForm = {
        item_name: '',
        payment_amount: '',
        payment_date: '',
        invoice_url: '',
      };
      this.inputData.invoiceInfomationList[index].outsourcingCostList.push(outsourcingForm);
    },

    addInvoicingInformation: function () {
      const invoicingForm = {
        invoice_amount: '',
        invoice_date: '',
        payment_deadline: '',
        payment_date: '',
        purchase_order_url: '',
        invoice_url: '',
        outsourcingCostList: [
          {
            item_name: '',
            payment_amount: '',
            payment_date: '',
            invoice_url: '',
          },
        ],
      };
      this.inputData.invoiceInfomationList.push(invoicingForm);
    },

    // project_type and customer will be changed depend on project name.
    // The processing is branched depending on whether the input method is combo box selection or text input.
    onChangeProjectName: async function () {
      let inputDataId = "";
      this.loadingComponent = true;
      // If the input is text
      if ((typeof this.inputData.project.name) !== 'object') {
        if (this.matchProjectName(this.inputData.project.name)) {
          //get data from project table with project_id.
          inputDataId = this.inputData.project.id;
        }
      } else {
        // If the input is combo box selection or NULL
        let inputData = {...this.inputData.project.name};
        //get data from project table with project_id.
        if (inputData.id) {
          inputDataId = inputData.id;
        }
      }
      const resultProject = await Vue.axios.get(`orders/getProject?id=${inputDataId}`);
      if (resultProject.data) {
        //set data to project_type and customer.
        this.$set(this.inputData.project, 'id', resultProject.data.id);
        this.$set(this.inputData.project, 'name', resultProject.data.name);
        this.$set(this.inputData.project, 'project_type_id', resultProject.data.project_type_id);
        this.$set(this.inputData.project, 'customer_id', resultProject.data.customer_id);
      }

      if (!this.editPage) {
        //set empty for all member first before check previous order project
        this.$set(this.inputData.order, 'pm_id', null);
        this.inputData.order.jpnMemberList.forEach((value, index) => {
          this.$set(this.inputData.order.jpnMemberList[index], 'id', null);
        });
        this.inputData.order.idnMemberList.forEach((value, index) => {
          this.$set(this.inputData.order.idnMemberList[index], 'id', null);
        });
        this.$set(this.inputData.order, 'name_jp', null);
        this.$set(this.inputData.order, 'name_jp', null);
        this.$set(this.inputData.order, 'remarks', null);
        this.$set(this.inputData.order, 'order_status_id', null);
        //---------------------------------------------------------------------

        //if Order project type == 2 / Maintenance type then auto fill only order names, pm members and remarks
        const resultOrder = await Vue.axios.get(`orders/getOrder?id=${inputDataId}`);
        if (resultProject.data.project_type_id === 2) {
          //Get data from APi to get previous Order
          this.$set(this.inputData.order, 'name_jp', resultOrder.data.name_jp);
          this.$set(this.inputData.order, 'name_en', resultOrder.data.name_en);
          //this.$set(this.inputData.order, 'order_status_id', resultOrder.data.order_status_id);
          this.$set(this.inputData.order, 'remarks', resultOrder.data.remarks);

          //if(resultOrder.data.invoice_information){
          //this.$set(this.inputData.invoiceInfomationList[0], 'invoice_amount', resultOrder.data.invoice_information.invoice_amount);
          //this.$set(this.inputData.invoiceInfomationList[0], 'invoice_date', resultOrder.data.invoice_information.invoice_date);
          //this.$set(this.inputData.invoiceInfomationList[0], 'payment_deadline', resultOrder.data.invoice_information.payment_deadline);
          //this.$set(this.inputData.invoiceInfomationList[0], 'payment_date', resultOrder.data.invoice_information.payment_date);
          //this.$set(this.inputData.invoiceInfomationList[0], 'purchase_order_url', resultOrder.data.invoice_information.purchase_order_url);
          //this.$set(this.inputData.invoiceInfomationList[0], 'invoice_url', resultOrder.data.invoice_information.invoice_url);
          //}
        }

        //Set PM
        this.$set(this.inputData.order, 'pm_id', resultOrder.data.pm_id);

        //Set member to previous order member
        if (resultOrder.data.members) {
          // Get members
          const jpnMembers = resultOrder.data.members.filter((value) => value.team_id == 1);
          const idnMembers = resultOrder.data.members.filter((value) => value.team_id == 2);

          // Set members
          this.initMemberList(jpnMembers, 'jpn');
          this.initMemberList(idnMembers, 'idn');
        }
      }
      this.loadingComponent = false;
    },

    // Check if the entered data is in the combobox selection
    matchProjectName: function(v) {
      let match = false;
      if (v) {
        this.formData.projects.forEach(element => {
          // If it exist, record the project ID and return True.
          if (element.name === v) {
            this.inputData.project.id = element.id;
            match = true;
          }
        });
      }
      return match;
    },

    toggleTaxInput: function (index) {
      if (!this.inputData.invoiceInfomationList[index].invoice_amount_with_tax_is_manual) {
        this.calculateInvoiceAmountWithTax(index);
      }
    },

    calculateInvoiceAmountWithTax: function (index) {
      if (!this.inputData.invoiceInfomationList[index].invoice_amount_with_tax_is_manual) {
        if (this.inputData.invoiceInfomationList[index].invoice_amount) {
          const withoutTaxValue = parseFloat(this.inputData.invoiceInfomationList[index].invoice_amount.toString().replace(/,/g, "")) || 0;
          const withTaxValue = Math.floor(withoutTaxValue * constants.taxRate).toString();

          this.inputData.invoiceInfomationList[index].invoice_amount_with_tax = withTaxValue;

          // Update format
          this.thousandFormatInvoiceTax(
            this.inputData.invoiceInfomationList[index].invoice_amount_with_tax,
            index
          )
        } else {
          this.inputData.invoiceInfomationList[index].invoice_amount_with_tax = '';
        }
      }
    },

    inputInvoiceBeforeTax(num, index, skipFormat = false) {
      this.calculateInvoiceAmountWithTax(index)
      this.thousandFormatInvoice(num, index, skipFormat)
    },

    //rules for invoices amount
    thousandFormatInvoice: function (num, index, skipFormat = false) {
      if (num) {
        num = num.toString().replaceAll(',', '');
        let price = Number.isInteger(Number(num));
        if (price) {
          price = Number(num).toLocaleString();
          if (skipFormat) {
            price = price.replaceAll(',', '');
          } else {
            price = price.replaceAll('.', ',');
          }
          this.$set(this.inputData.invoiceInfomationList[index], 'invoice_amount', price);
        }
      }
    },

    thousandFormatInvoiceTax: function (num, index, skipFormat = false) {
      if (num) {
        num = num.toString().replaceAll(',', '');
        let price = Number.isInteger(Number(num));
        if (price) {
          price = Number(num).toLocaleString();
          if (skipFormat) {
            price = price.replaceAll(',', '');
          } else {
            price = price.replaceAll('.', ',');
          }
          this.$set(this.inputData.invoiceInfomationList[index], 'invoice_amount_with_tax', price);
        }
      }
    },

    thousandFormatOutCost: function (num, index, skipFormat = false) {
      if (num) {
        num = num.toString().replaceAll(',', '');
        let price = Number.isInteger(Number(num));
        if (price) {
          price = Number(num).toLocaleString();
          if (skipFormat) {
            price = price.replaceAll(',', '');
          } else {
            price = price.replaceAll('.', ',');
          }
          this.$set(this.inputData.invoiceInfomationList[0].outsourcingCostList[index], 'payment_amount', price);
        }
      }
    },

    checkDuplicate: function (value, index, type) {
      //Check if already selected in PM member
      if (value) {
        let checkPM = this.inputData.order.pm_id == value;
        let mapMember = null;
        //type 1 = check japan member selected
        //type 2 = check idn member selected
        if (type == 1) {
          mapMember = this.inputData.order.jpnMemberList.map((item) => item.id).indexOf(value) !== index;
        } else {
          mapMember = this.inputData.order.idnMemberList.map((item) => item.id).indexOf(value) !== index;
        }
        //if Already in Pm or in Member list return warning duplicate
        if (checkPM || mapMember) {
          return false;
        }
      }
      return true;
    },

    //Rulse for member and check duplicate value
    memberRules: function (maxNum, type) {
      return (v) => this.checkDuplicate(v, maxNum, type) || this.$t('general.order.message.duplicateMember');
    },

    isInvoiceInput: function () {
      // If any one is inputted, return true.
      if (
        !this.inputData.invoiceInfomationList[0] ||
        (!this.inputData.invoiceInfomationList[0].invoice_date &&
          !this.inputData.invoiceInfomationList[0].payment_deadline &&
          !this.inputData.invoiceInfomationList[0].invoice_amount)
      ) {
        return false;
      } else {
        return (
          Boolean(this.inputData.invoiceInfomationList[0].invoice_date) ||
          Boolean(this.inputData.invoiceInfomationList[0].payment_deadline) ||
          Boolean(this.inputData.invoiceInfomationList[0].invoice_amount) ||
          Boolean(this.inputData.invoiceInfomationList[0].payment_date) ||
          Boolean(this.inputData.invoiceInfomationList[0].purchase_order_url) ||
          Boolean(this.inputData.invoiceInfomationList[0].invoice_url)
        );
      }
    },

    //For Accepted Date Input Required
    acceptedDateRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v ? true : !this.isInvoiceInput() || this.$t('general.order.message.acceptedDateRequired');
    },

    invoiceAmountRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v
        ? /^[0-9,]+$/.test(v) || this.$t('general.order.message.invoiceAmountMinCharacters')
        : !this.isInvoiceInput() || this.$t('general.order.message.invoiceAmountRequired');
    },
    invoiceDateRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v ? true : !this.isInvoiceInput() || this.$t('general.order.message.invoiceDateRequired');
    },
    paymentDeadlineRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v
        ? !!v || this.$t('general.order.message.paymentDateRequired')
        : !this.isInvoiceInput() || this.$t('general.order.message.paymentDeadlineRequired');
    },
    purchaseOrderUrlRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v
        ? true
        : /https?:\/{2}[\w/:%#$&?()~.=+-]+^$|/.test(v) || this.$t('general.order.message.purchaseOrderUrlCheck');
    },
    invoiceUrlRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v
        ? true
        : /https?:\/{2}[\w/:%#$&?()~.=+-]+^$|/.test(v) || this.$t('general.order.message.invoiceUrlCheck');
    },
    isOutsourcingCostInput: function (outsourcingCost) {
      // Called on loop of outsourcingCost.
      // If any one is inputted, return true.
      return (
        Boolean(outsourcingCost.item_name) ||
        Boolean(outsourcingCost.payment_amount) ||
        Boolean(outsourcingCost.payment_date) ||
        Boolean(outsourcingCost.invoice_url)
      );
    },
    outsourcingItemNameRule: function (outsourcingCost) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return outsourcingCost.item_name
        ? true
        : !this.isOutsourcingCostInput(outsourcingCost) || this.$t('general.order.message.itemNameRequired');
    },
    outsourcingAmountRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v ? /^[0-9,]+$/.test(v) || this.$t('general.order.message.invoiceAmountMinCharacters') : true;
    },
    outsourcingInvoiceUrlRule: function (v) {
      // [note] Since calling methods is necessary for condition, this rule have to make as methods.
      return v ? /https?:\/{2}[\w/:%#$&?()~.=+-]+/.test(v) || this.$t('general.order.message.invoiceUrlCheck') : true;
    },
    // ------------------------------------
    // add date
    // ------------------------------------
    addDate(date) {
      var invoiceDate = moment(date).add(1, 'months').format('YYYY-MM-DD');
      return invoiceDate;
    },
    addNewMember(type) {
      if (type === 'jpn') {
        this.inputData.order.jpnMemberList.push({ id: null, name: '' });
        this.inputData.order.jpnMemberList.push({ id: null, name: '' });
        this.jpNumberForm += 2;
      }
      else if (type === 'idn') {
        this.inputData.order.idnMemberList.push({ id: null, name: '' });
        this.inputData.order.idnMemberList.push({ id: null, name: '' });
        this.idNumberForm += 2;
      }
    },

    /**
     * Initializes the member list based on the provided members array and type.
     * Updates this.inputData.order.jpnMemberList or this.inputData.order.idnMemberList.
     * @param {Array} members - An array of objects representing members.
     * @param {string} type - A string indicating the type, either "jpn" or "idn".
     */
    initMemberList(members, type) {
      const defaultLength = type === 'jpn' ? this.jpNumberForm : this.idNumberForm;
      let memberList;

      if (type === 'jpn') {
        memberList = members.filter((value) => value.team_id == 1);
      } else {
        memberList = members.filter((value) => value.team_id == 2);
      }

      if (memberList.length < defaultLength) { // Ensure that the number of members at least the defaultLength
        // Add empty members
        memberList = memberList.concat(
          [...Array(defaultLength - memberList.length)].map(() => ({ id: null, name: '' }))
        )
      } else if (memberList.length % 2 == 1) { // Check if the number of members is odd
        // Add one empty member
        memberList = memberList.concat([{ id: null, name: '' }])
      }

      // Update inputData
      if (type === 'jpn') {
        this.inputData.order.jpnMemberList = memberList;
        this.jpNumberForm = memberList.length;
      } else {
        this.inputData.order.idnMemberList = memberList;
        this.idNumberForm = memberList.length;
      }
    }
  },
  async created() {
    const jpnNumberFormDefault = this.jpNumberForm
    const idNumberFormDefault = this.idNumberForm

    var vm = this;
    this.loadingComponent = true;
    //fetches the form data, submitURL and this.item(edit only) from the api
    const form = await getForm(this.$route.path);
    //edit(see router/selectedProject.js file)
    if (this.$route.meta.editPage) {
      this.editPage = true;
      let { formData, submitUrl, project, order, invoiceInformation, outsourcingCost } = form;
      this.inputData.project = project;
      this.inputData.order = order;

      // Set members
      this.initMemberList(order.jpnMembers, 'jpn');
      this.initMemberList(order.idnMembers, 'idn');

      this.inputData.invoiceInfomationList = invoiceInformation;
      // this.inputData.outsourcingCostList = outsourcingCost;

      Object.keys(this.inputData.invoiceInfomationList).map(function (key, index) {
        if (vm.inputData.invoiceInfomationList[key].invoice_amount) {
          vm.thousandFormatInvoice(vm.inputData.invoiceInfomationList[key].invoice_amount, index)
        }

        if (vm.inputData.invoiceInfomationList[key].invoice_amount_with_tax) {
          vm.thousandFormatInvoiceTax(vm.inputData.invoiceInfomationList[key].invoice_amount_with_tax, index)
        }

        // Loop outsourcingCostList with foreach
        if (vm.inputData.invoiceInfomationList[key].outsourcingCostList) {
          Object.keys(vm.inputData.invoiceInfomationList[key].outsourcingCostList).map(function (key2, index2) {
            if (vm.inputData.invoiceInfomationList[key].outsourcingCostList[key2].payment_amount) {
              vm.thousandFormatOutCost(
                vm.inputData.invoiceInfomationList[key].outsourcingCostList[key2].payment_amount,
                index2
              )
            }
          })
        }
      })

      this.formData = formData;
      this.submitUrl = submitUrl;
      if (order.type == 1) { // LABO
        //this.disabledButton = true;
        this.disabledForm = true;
      }
    }
    // duplicate
    else if (this.$route.params.duplicate == true) {
      var url = '/orders/' + this.$route.params.id + '/edit';
      const res = await getForm(url);
      let { formData, project, order, invoiceInformation, outsourcingCost } = res;
      this.inputData.project = project;
      this.inputData.order = order;
      this.inputData.order.order_status_id = null;
      this.inputData.order.acceptance_date = null;

      // Set members
      this.initMemberList(order.jpnMembers, 'jpn');
      this.initMemberList(order.idnMembers, 'idn');

      this.inputData.invoiceInfomationList = invoiceInformation;
      Object.keys(invoiceInformation).map(function (key, index) {
        invoiceInformation[key].payment_date = null;
        invoiceInformation[key].purchase_order_url = null;
        invoiceInformation[key].invoice_url = null;
        invoiceInformation[key].invoice_date = invoiceInformation[key].invoice_date ? vm.addDate(invoiceInformation[key].invoice_date) : null;
        invoiceInformation[key].payment_deadline = invoiceInformation[key].payment_deadline ? vm.addDate(invoiceInformation[key].payment_deadline) : null;
        invoiceInformation[key].outsourcingCostList = [];
      });
      this.formData = formData;
      this.submitUrl = '/orders';
      if (order.type == 1) {
        this.disabledForm = true;
      }
    }
    // create
    else {
      let { formData, submitUrl } = form;
            this.inputData.order.type = 0;
      this.formData = formData;
      this.submitUrl = submitUrl;

      this.initMemberList([], 'jpn');
      this.initMemberList([], 'idn');
    }
    this.loadingComponent = false;
  },
  components: {
    GInputGroup,
    PageInnerSet,
    CustomDatePicker,
  },
  watch: {
    inputData: {
      handler(val) {
        if (val.order.type) {
          if (val.order.type == 0) {
            //watch order type if type is orders / 0 remove all other invoice information and left 1
            val.invoiceInfomationList.length = 1;
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .highlight {
    padding: 0.625rem 2rem;
    background: red;
    border-radius: 0.25rem;
    color: white;
  }
</style>
