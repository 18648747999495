<template>
  <v-autocomplete
    v-model="valueModel"
    :label="label"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :rules="rules"
    clearable
    dense
    outlined
  ></v-autocomplete>
</template>

<script>
  export default {
    props: {
      value: {type: [Boolean, String, Number, Array]},
      items: {type: Array, default: () => []},
      label: {type: String, default: ""},
      color: {type: String, default: "primary"},
      itemValue: {type: String, default: "id"},
      itemText: {type: String, default: "name"},
      rules: { type: Array, default: null},
    },
    computed: {
      valueModel: {
        get(){return this.value},
        set( value ){this.$emit('input', value)}
      }
    }
  }
</script>