import Form from '@views/main/member/Form';
import DataTable from '@views/main/member/Datatable';
import constants from "@/const/const";

const membersRoutes = [
    {
    path: 'members',
    name: 'members',
    component: DataTable,
    meta: {
        //all can view except restricted user
        restricted: [constants.permissions.restrictedUserString]
      },
  },
  {
    path: 'members/create',
    name: 'members.create',
    component: Form,
    meta: {
      restricted: constants.permissions.exceptAdmin
    },
  },
  {
    path: 'members/:id/edit',
    name: 'members.edit',
    component: Form,
    meta: {
      editPage: true,
      restricted: constants.permissions.exceptAdmin
    }
  }
]

export default membersRoutes;
