<template>
  <InputGroup :title="title" :title_width="title_width" :required="required" :optional="optional">
    <div class="row mx-n1">
      <!-- start date -->
      <div class="px-1 col-sm py-0 mb-1 mb-sm-0">
        <slot name="min"></slot>
      </div>
      <div class="px-1 col-sm-auto py-0 d-none d-sm-block">
        <div class="py-2">
          <span class="center">~</span>
        </div>
      </div>
      <!-- end date -->
      <div class="px-1 col-sm py-0">
        <slot name="max"></slot>
      </div>
    </div>
  </InputGroup>
</template>

<script>
  import InputGroup from '@views/_components/molecules/form_input/GInputGroup.vue';
  export default {
    components: { InputGroup },
    props: {
      optional: {default: false},
      required: {default: false},
      title: {required: true},
      title_width: {required: false},
    },
  };
</script>