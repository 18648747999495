<template>
  <v-checkbox
    v-model=valueModel
    :label=label
    :color=color
    dense
  >
  </v-checkbox>
</template>

<script>
  export default {
    props: {
      value: {type: [Boolean, String]},
      label: {type: String, default: ""},
      color: {type: String, default: "primary"}
    },
    computed: {
      valueModel: {
        get(){return this.value},
        set( value ){this.$emit('input', value)}
      }
    }
  }
</script>
