<template>
  <div class="error-401">
    <h1>You are not allowed to access this page</h1>
  </div>
</template>

<script>
export default {
  name: 'ErrorUnauthorized',
};
</script>
