const constants = {
    taxRate: 1.1,
    //these values can be compared against member.getPermissionLevel() method.
    permissions: {
        adminString: "ADMIN",
        restrictedAdminString: "RESTRICTED_ADMIN",
        userString: "USER",
        restrictedUserString: "RESTRICTED_USER",
        //the below are just to save time
        nonHQ: ["RESTRICTED_ADMIN", "RESTRICTED_USER"],
        exceptAdmin: ["USER", "RESTRICTED_USER"],
        HQ: ["ADMIN", "USER"],
        admins: ["ADMIN", "RESTRICTED_ADMIN"],
        exceptRestrictedUser: ["ADMIN", "USER", "RESTRICTED_ADMIN"],
        exceptMainAdmin: ["USER", "RESTRICTED_ADMIN", "RESTRICTED_USER"]

    },
    orderStatusColors: {
        bgColor: {
            "booking": "#b6d7a8",
            "ordered": "#ffe599",
            "delivered": "#ea9999",
            "sent bill": "#a4c2f4",
            "confirmed receipt": "#d9d9d9",
            "prepayment": "#b4a7d6",
            "delayed payment": "#FB554E",
            "potential order (high chance)": "#4c1130",
            "potential order (moderate chance)": "#a64d79",
            "potential order (low chance)": "#c27ba0",
            "sent bill (deposit)": "#77a4ed",
            "confirmed receipt (deposit)": "#b3b2b2",
        },
        textColor: {
            "booking": "#000",
            "ordered": "#000",
            "delivered": "#000",
            "sent bill": "#000",
            "confirmed receipt": "#000",
            "prepayment": "#000",
            "delayed payment": "#000",
            "potential order (high chance)": "#fff",
            "potential order (moderate chance)": "#fff",
            "potential order (low chance)": "#fff",
            "sent bill (deposit)": "#000",
            "confirmed receipt (deposit)": "#000",
        }
    },
    // https://github.com/mengxiong10/vue2-datepicker/blob/master/locale.md
    datePickerLanguageSetting: { 
        ja: {
            // the locale of formatting and parsing function
            formatLocale: {
                months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                weekdays: ['日', '月', '火', '水', '木', '金', '土'],
                weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
                weekdaysMin: ['日', '月', '火', '水', '木', '金', '土'],
                // first day of week
                firstDayOfWeek: 0,
                // first week contains January 1st.
                firstWeekContainsDate: 1,
                // format 'a', 'A'
                meridiem: (h, _, isLowercase) => {
                    const word = h < 12 ? 'AM' : 'PM';
                    return isLowercase ? word.toLocaleLowerCase() : word;
                },
                // parse ampm
                meridiemParse: /[ap]\.?m?\.?/i,
                // parse ampm
                isPM: (input) => {
                    return `${input}`.toLowerCase().charAt(0) === 'p';
                }
            },
            // the calendar header, default formatLocale.weekdaysMin
            // days: [],
            // the calendar months, default formatLocale.monthsShort
            // months: [],
            // the calendar title of year
            // yearFormat: 'YYYY 年',
            // the calendar title of month
            // monthFormat: 'MMM',
            // the calendar title of month before year
            monthBeforeYear: false,
        },
        en: {
            // the locale of formatting and parsing function
            formatLocale: {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                // first day of week
                firstDayOfWeek: 0,
                // first week contains January 1st.
                firstWeekContainsDate: 1,
                // format 'a', 'A'
                meridiem: (h, _, isLowercase) => {
                    const word = h < 12 ? 'AM' : 'PM';
                    return isLowercase ? word.toLocaleLowerCase() : word;
                },
                // parse ampm
                meridiemParse: /[ap]\.?m?\.?/i,
                // parse ampm
                isPM: (input) => {
                    return `${input}`.toLowerCase().charAt(0) === 'p';
                }
            },
            // the calendar header, default formatLocale.weekdaysMin
            // days: [],
            // the calendar months, default formatLocale.monthsShort
            // months: [],
            // the calendar title of year
            // yearFormat: 'YYYY',
            // the calendar title of month
            // monthFormat: 'MMM',
            // the calendar title of month before year
            monthBeforeYear: false,
        },
    },
}


export default constants;