<template>
  <div class="pm">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{$t("general.nav.dashboard")}}</router-link>
      <span class="mx-2">></span>
      <span>{{$t("general.PMS.list.pageTitle")}}</span>
    </p> 
    <!--page container -->   
    <PageInnerSet :title="$t('general.PMS.list.pageTitle')">

    <!-- searching form -->
    <v-form ref="filter">
      <!-- <span class="text-h6 text--secondary">> {{ $t('general.reports.condition') }}</span> <br /> -->
      <!-- <FilterReset @click="resetFilter" class="pb-10"></FilterReset> -->
      
      <v-row>
        <v-col cols="6">
          <FilterDateRange
            :title="$t('general.PMS.term')+'：'"
            v-model="searchingFormData.date"
            type="month"
            classTop="pt-4 mb-0"
            @input="updateAllFilters"
          />
        </v-col>
        <v-col>
          <div class="pt-4">
            <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
          </div>
        </v-col>
      </v-row>

    </v-form>
    <!-- searching form -->

    <!-- <v-row class="pt-5">
      <v-col cols="12">
        <span class="text-h6 text--secondary">> {{ $t('general.reports.result') }}</span>
      </v-col>
    </v-row> -->
      <br />

    <!-- data table -->
    <v-data-table
      :headers="headers"
      :items="members"
      :options.sync="options"
      :page.sync="currentPage"
      :server-items-length="totalMembers"
      :loading="isLoadingTableData"
      :loading-text="$t('general.crud.loading')"
      hide-default-footer
      @page-count="totalPages = $event"
      fixed-header
      height="700px"
      dense
    >

      <template v-slot:[`item.total_invoice_amount`]="{ item }">
          <div>{{ displayedMoneyHelper(item.total_invoice_amount, isLocaleJapanese) }} </div>
      </template>

      <template v-slot:[`item.profit`]="{ item }">
          <div>{{ displayedMoneyHelper(item.profit, isLocaleJapanese) }} </div>
      </template>

    </v-data-table>
    <!-- data table -->


    <!-- Pagination -->
    <!-- <div class="text-center pt-2 mt-3">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="7"
      ></v-pagination>
    </div> -->
    <!-- Pagination -->

   </PageInnerSet>
  </div>
</template>

<script>
/**
 * 29 June 2021 documented by Satoshi Sugai 
 * 
 * How searching form works
 * 1. As soon as one of the searching form items gets input, run "updateAllFilters()" method runs
 * 2. Inside "updateAllFilters()", "updateFilter()" method gets called. It sets/clears filter("activeFilters")) to be sent to api
 * 3. Once "activeFilters" gets changed, retrieve list data from api again. This happens also when reset text gets pressed.
 * 
 * How pagination works
 * - While you are in say page 2 and use a filter, the page will be automatically set to 1.   
 * 
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 * 
 */

/* July 1st 2021 documented by Tomas Sullivan
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 * 
 */

import { getAll, getSelectBoxData } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import { displayedMoneyHelper } from '@/helpers';

import FilterReset from '@views/_components/datatable_filter/TableFilterReset';
//import FilterDateRange from '@views/_components/datatable_filter/TableFilterDateRange';
//import FilterDateRange from '@views/_components/form_input/GDatePicker';
import FilterDateRange from '@views/_components/datatable_filter/TableFilterDateRange2';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.PMS.list')
    }
  },
  components: {
    // FilterReset,
    FilterDateRange,
    PageInnerSet,
  },
  data: function() {
    return {
      members: [], // main data
      totalMembers: 0, // total main data
      options: {
        itemsPerPage: 10,
        page: 1,
        multiSort: true,
        sortBy: [],
        sortDesc: [],
      },
      searchingFormData: {
        date: null
      }, // all the data filter used will place in here
      activeFilters: {},
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false,   // Check if it's loading data from api
      timer: null,
    }
  },
  // mounted: function() {
  //   // this.getAllProjects(); //run getallprojects function when mounted or page loaded
  // },
  created() {
    // set initial filter
    const current = new Date()
    let startDate, endDate = ''
    let startYear, endYear = ''
    if (current.getMonth() < 10) {
      startYear = current.getFullYear() - 1
      endYear = current.getFullYear()
    } else {
      startYear = current.getFullYear()
      endYear = current.getFullYear() + 1
    }
    startDate = startYear + '-11'
    endDate = endYear + '-10'
    this.searchingFormData.date = [startDate, endDate]
    this.updateAllFilters()
    // this.getAllProjects(); //run getallprojects function when mounted or page loaded
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler(){
        this.updateDataTable();
      },
      deep: true
    },
  },
  computed: {
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === "ja";
    },
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.PMS.pm'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('general.PMS.sales'),
          value: 'total_invoice_amount',
          sortable: true,
        },
        {
          text: this.$t('general.PMS.profit'),
          value: 'profit',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data 
     */
    getAllProjects: async function() {
      let url = 'pms'
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.members = res.members.data; // get the main data
        this.totalMembers= res.members.total; // get the total of main data
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber=false) {
      this.getAllProjects();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      this.$refs.filter.reset();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('date', this.searchingFormData.date); // data for date filter
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters" 
     * See notes on Customer Datatable page
     * Not all of our filters are arrays so 
     */
    updateFilter: function(attr, val) {
      if (val) {
        if (Array.isArray(val)){
        val = val.join("||");
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    /**
    *List OF Helper Functions must set first in mehtods
    */
    displayedMoneyHelper,
    
  },
}
</script>

<style lang="scss">
.pm {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 300px)  !important;
  }
  .v-pagination__navigation {
    display: none;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
}
</style>