<template>
  <div class="morning-report">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.morningReports.list.pageTitle') }}</span>
    </p>
    <!-- Page Title -->
    <PageInnerSet :title="$t('general.morningReports.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <v-row cols="12">
          <DateSearch :activeFilters="activeFilters" :rules="rules" :DateType="'date'"></DateSearch>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    :title="$t('general.morningReports.projectName') + '：'"
                    :title_width="4"
                    :items="selectBoxData.project_names"
                    v-bind="attrs"
                    v-model="activeFilters.project_names"
                  />
                </div>
              </template>
              <span>{{ activeFilters.project_names ? tooltipItem(activeFilters.project_names) : '' }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    v-if="isLocaleJapanese"
                    :title="$t('general.morningReports.orderName') + '：'"
                    :title_width="2"
                    :items="selectBoxData.order_names_jp"
                    v-bind="attrs"
                    v-model="activeFilters.order_names_jp"
                  />
                  <FilterTextSelect
                    v-else
                    :title="$t('general.morningReports.orderName') + '：'"
                    :title_width="2"
                    :items="selectBoxData.order_names_en"
                    v-model="activeFilters.order_names_en"
                    v-bind="attrs"
                  />
                </div>
              </template>
              <span>{{
                isLocaleJapanese ? tooltipItem(activeFilters.order_names_jp) : tooltipItem(activeFilters.order_names_en)
              }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto">
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <br />

      <div class="mb-3 font-weight-bold text-h6">
        {{ $t('general.morningReports.totalWorkingTime') }} : {{ totalWorkingHours }}H
      </div>

      <!-- data talbe  -->
      <v-data-table
        :headers="headers"
        :items="morningReports"
        :options.sync="options"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        :items-per-page="-1"
        hide-default-footer
        fixed-header
        height="700px"
        dense
      >
        <template v-slot:[`item.date`]="{ item }">
          <div class="title text--black">
            {{
              morningReports.indexOf(item) != 0
                ? morningReports[morningReports.indexOf(item) - 1].morning_report_id == item.morning_report_id
                  ? ''
                  : item.date
                : item.date
            }}
          </div>
          <v-btn
            v-if="morningReports.indexOf(item) == 0 || morningReports[morningReports.indexOf(item) - 1].morning_report_id != item.morning_report_id"
            :to="{ name: 'morningReports.edit', params: { id: item.morning_report_id } }"
            depressed
            color="primary"
            width="100%"
            x-small
          >
            {{ $t('general.crud.edit') }}
          </v-btn>
        </template>
        <template v-slot:[`item.total_hours`]="{ item }">
          <div class="title text--black">
            {{
              morningReports.indexOf(item) != 0
                ? morningReports[morningReports.indexOf(item) - 1].morning_report_id == item.morning_report_id
                  ? ''
                  : item.total_hours
                : item.total_hours
            }}
          </div>
        </template>
        <template v-slot:[`item.project_name`]="{ item }">
          {{ item.project_name }}
        </template>
        <template v-slot:[`item.order_name`]="{ item }">
          <p>{{ item.order_name_jp }}</p>
          <p>{{ item.order_name_en }}</p>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          {{ item.time }}
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          {{ item.progress }}
        </template>
      </v-data-table>

      <!-- <div class="text-center pt-2 mt-3">
        <v-pagination v-model="options.page" :length="totalPages" total-visible="7"></v-pagination>
      </div> -->
    </PageInnerSet>
  </div>
</template>

<script>
import { getAll } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import FilterSelect from '@views/_components/datatable_filter/TableFilterSelect';
import DateSearch from '@views/_components/organisms/search/DateSearch.vue';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';

export default {
  metaInfo() {
    return {
      title: this.$t('general.morningReports.list.metaTitle'),
    };
  },
  components: {
    DateSearch,
    PageInnerSet,
    // FilterSelect,
    // GDatePicker,
    // FilterReset,
    // FilterRange,
    FilterTextSelect,
  },
  data: function() {
    return {
      rules: {
        start_date: [
          v =>
            new Date(v).getTime() <= new Date().getTime() ||
            this.$t('general.morningReports.message.start_date.greaterThanToday'),

          v =>
            this.activeFilters.end_date == null ||
            this.activeFilters.start_date == null ||
            new Date(this.activeFilters.start_date).getTime() <= new Date(this.activeFilters.end_date).getTime() ||
            this.$t('general.morningReports.message.start_date.greaterThanDateEnd'),
        ],
        end_date: [
          v =>
            new Date(v).getTime() <= new Date().getTime() ||
            this.$t('general.morningReports.message.end_date.greaterThanToday'),

          v =>
            this.activeFilters.start_date == null ||
            this.activeFilters.end_date == null ||
            new Date(this.activeFilters.end_date).getTime() >= new Date(this.activeFilters.start_date).getTime() ||
            this.$t('general.morningReports.message.end_date.lessThanDateStart'),
        ],
      },
      morningReports: [],
      activeFilters: {
        start_date: null,
        end_date: null,
        project_names: [],
        order_names_jp: [],
        order_names_en: [],
      },
      selectBoxData: {
        project_names: [],
        order_names_jp: [],
        order_names_en: [],
      },
      options: {
        itemsPerPage: -1,
        page: 1,
        sortBy: ['date'],
        sortDesc: [true],
      },
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false,
    };
  },
  mounted: function() {
    this.getAllMorningReports();
    this.retrieveSelectBoxData();
  },
  watch: {
    options: {
      handler() {
        this.getAllMorningReports();
      },
      deep: true,
    },
    activeFilters: {
      handler() {
        if (!this.$refs.filter.validate()) return;
        this.options.page = 1;
        this.getAllMorningReports();
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.morningReports.date'),
          value: 'date',
          width: '14%',
          sortable: true,
        },
        {
          text: this.$t('general.morningReports.total'),
          value: 'total_hours',
          width: '10%',
          sortable: false,
          divider: true,
        },
        { text: this.$t('general.morningReports.projectName'), value: 'project_name', width: '20%', sortable: false },
        { text: this.$t('general.morningReports.orderName'), value: 'order_name', width: '20%', sortable: false },
        { text: this.$t('general.morningReports.time'), value: 'time', width: '6%', sortable: false },
        { text: this.$t('general.morningReports.progress'), value: 'progress', width: '20%', sortable: false },
      ];
    },
    date1: () => {
      return;
    },
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
    totalWorkingHours: function() {
      let total = 0;
      this.morningReports.forEach(element => {
        total += element.time;
      });
      return total;
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    resetFilter: function() {
      this.$refs.filter.reset();
    },
    getAllMorningReports: async function() {
      console.log('getAllMorningReports', this.$store.state.auth.info.role);
      let url = '/morning-reports';
      this.isLoadingTableData = true;
      try {
        const { itemsPerPage, page, sortBy, sortDesc } = this.options;
        const res = await getAll(url, { itemsPerPage: 10, page, sortBy, sortDesc, ...this.activeFilters });
        this.morningReports = res.data;
        this.totalPages = res.last_page;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    retrieveSelectBoxData: async function() {
      let url = '/morning-reports/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getAll(url);
        this.selectBoxData.project_names = res.project_names;
        this.selectBoxData.order_names_jp = res.order_names_jp;
        this.selectBoxData.order_names_en = res.order_names_en;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
  },
};
</script>

<!-- <style lang="scss">
.morning-report {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 330px)  !important;
  }
  .v-data-table__wrapper {
    border: 0 !important;
  }
  .v-data-table th {
    border: 0;
  }
  .v-data-table td {
    border: 0;
  }
  .v-pagination__navigation {
    display: none;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color: white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  .v-chip {
    overflow: initial;
    font-size: 11px !important;
  }

  .v-select__selection--comma {
    overflow: hidden;
  }
}

.v-input__control {
  .v-input__slot {
    padding-bottom: 5px !important;
  }
}
</style> -->
