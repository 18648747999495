var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"working-hour"},[_c('p',{staticClass:"mx-1 mb-0 text-caption text--secondary"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(_vm._s(_vm.$t("general.nav.dashboard")))]),_c('span',{staticClass:"mx-2"},[_vm._v(">")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.workingHour.list.pageTitle")))])],1),_c('PageInnerSet',{attrs:{"title":_vm.$t('general.workingHour.list.pageTitle')}},[_c('v-form',{ref:"filter"},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"2","sm":"12","lg":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('FilterTextSelect',_vm._b({ref:"datePicker",attrs:{"title":_vm.$t('general.workingHour.targetMonth')+'：',"title_width":5,"items":_vm.selectBoxData.targetMonths,"margin":"0"},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.targetMonth),callback:function ($$v) {_vm.$set(_vm.filterData, "targetMonth", $$v)},expression:"filterData.targetMonth"}},'FilterTextSelect',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.targetMonth ? _vm.tooltipItem(_vm.filterData.targetMonth) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('v-col',{attrs:{"sm":"12","lg":"3"}},[_c('div',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetFilter}},[_vm._v(_vm._s(_vm.$t('general.crud.reset')))])],1)])],1)],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"options":_vm.options,"page":_vm.currentPage,"server-items-length":_vm.totalInvoices,"loading":_vm.isLoadingTableData,"loading-text":_vm.$t('general.crud.loading'),"hide-default-footer":"","dense":"","item-class":_vm.itemRowBackground,"fixed-header":"","height":"650px"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalPages = $event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('project_type_en') || _vm.isEnabledColumn('project_type_jp')),expression:"isEnabledColumn('project_type_en') || isEnabledColumn('project_type_jp')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-select',_vm._b({staticStyle:{"width":"120px"},attrs:{"dense":"","items":_vm.selectBoxData.projectTypes,"item-value":'id',"item-text":_vm.isLocaleJapanese ? 'name_jp' : 'name_en',"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.projectTypeId),callback:function ($$v) {_vm.$set(_vm.filterData, "projectTypeId", $$v)},expression:"filterData.projectTypeId"}},'v-select',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.projectTypeId ? _vm.tooltipItemForTypes(_vm.filterData.projectTypeId, _vm.selectBoxData.projectTypes) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('project_name')),expression:"isEnabledColumn('project_name')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"180px"},attrs:{"dense":"","items":_vm.selectBoxData.projectNames,"item-value":'id',"item-text":'name',"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.projectName),callback:function ($$v) {_vm.$set(_vm.filterData, "projectName", $$v)},expression:"filterData.projectName"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.projectName ? _vm.tooltipItem(_vm.filterData.projectName) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('name_en') || _vm.isEnabledColumn('name_jp')),expression:"isEnabledColumn('name_en') || isEnabledColumn('name_jp')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"180px"},attrs:{"dense":"","items":_vm.selectBoxData.orderNames,"item-value":'name_jp',"item-text":'name_jp',"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.orderNameJp),callback:function ($$v) {_vm.$set(_vm.filterData, "orderNameJp", $$v)},expression:"filterData.orderNameJp"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.orderNameJp ? _vm.tooltipItem(_vm.filterData.orderNameJp) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('member_name')),expression:"isEnabledColumn('member_name')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"100px"},attrs:{"dense":"","items":_vm.selectBoxData.projectManagers,"item-value":'id',"item-text":'name',"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.pmId),callback:function ($$v) {_vm.$set(_vm.filterData, "pmId", $$v)},expression:"filterData.pmId"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.pmId ? _vm.tooltipItem(_vm.filterData.pmId) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('order_status_name_en') || _vm.isEnabledColumn('order_status_name_jp')),expression:"isEnabledColumn('order_status_name_en') || isEnabledColumn('order_status_name_jp')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"120px"},attrs:{"dense":"","items":_vm.selectBoxData.statuses,"item-value":'id',"item-text":_vm.isLocaleJapanese ? 'name_jp' : 'name_en',"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.statusId),callback:function ($$v) {_vm.$set(_vm.filterData, "statusId", $$v)},expression:"filterData.statusId"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.statusId ? _vm.tooltipItem(_vm.filterData.statusId) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('invoice_month')),expression:"isEnabledColumn('invoice_month')"}],staticClass:"v-data-table__divider"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-combobox',_vm._b({staticStyle:{"width":"110px"},attrs:{"dense":"","items":_vm.selectBoxData.invoiceDates,"multiple":"","chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.invoiceDate),callback:function ($$v) {_vm.$set(_vm.filterData, "invoiceDate", $$v)},expression:"filterData.invoiceDate"}},'v-combobox',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterData.invoiceDate ? _vm.tooltipItem(_vm.filterData.invoiceDate) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('invoice_amount')),expression:"isEnabledColumn('invoice_amount')"}],staticClass:"v-data-table__divider"},[_c('v-text-field',{attrs:{"dense":"","placeholder":_vm.$t('general.crud.minPlaceholder'),"type":"number","min":"0","step":"500000","rules":_vm.filterData.max_payment_amount && _vm.filterData.min_payment_amount
                ? _vm.rules.paymentCountMin.concat(_vm.rules.positiveInteger)
                : []},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.min_payment_amount),callback:function ($$v) {_vm.$set(_vm.filterData, "min_payment_amount", _vm._n($$v))},expression:"filterData.min_payment_amount"}}),_c('v-text-field',{attrs:{"dense":"","placeholder":_vm.$t('general.crud.maxPlaceholder'),"type":"number","min":"0","step":"500000","rules":_vm.filterData.min_payment_amount && _vm.filterData.max_payment_amount
                ? _vm.rules.paymentCountMax.concat(_vm.rules.positiveInteger)
                : []},on:{"input":_vm.updateAllFilters},model:{value:(_vm.filterData.max_payment_amount),callback:function ($$v) {_vm.$set(_vm.filterData, "max_payment_amount", _vm._n($$v))},expression:"filterData.max_payment_amount"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('japan_labour_cost')),expression:"isEnabledColumn('japan_labour_cost')"}],staticClass:"v-data-table__divider"}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('indonesian_labour_cost')),expression:"isEnabledColumn('indonesian_labour_cost')"}],staticClass:"v-data-table__divider"}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('japan_labour_hour')),expression:"isEnabledColumn('japan_labour_hour')"}],staticClass:"v-data-table__divider"}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('indonesian_labour_hour')),expression:"isEnabledColumn('indonesian_labour_hour')"}],staticClass:"v-data-table__divider"}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('total_cost')),expression:"isEnabledColumn('total_cost')"}],staticClass:"v-data-table__divider"}),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnabledColumn('action')),expression:"isEnabledColumn('action')"}],staticClass:"v-data-table__divider fixed"})])]},proxy:true},{key:"header.invoice_amount",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalInvoicesAmount.toLocaleString())+"円 ")]}},{key:"header.indonesian_labour_cost",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalIdnCost.toLocaleString())+"円 ")]}},{key:"header.indonesian_labour_hour",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalIdnHour.toLocaleString())+"H ")]}},{key:"header.japan_labour_cost",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalJpnCost.toLocaleString())+"円 ")]}},{key:"header.japan_labour_hour",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalJpnHour.toLocaleString())+"H ")]}},{key:"header.total_cost",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('hr'),_vm._v(_vm._s(_vm.totalCost.toLocaleString())+"円 ")]}},{key:"item.project_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(item.project_name))])]}},{key:"item.member_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(item.member_name))])]}},(_vm.isLocaleJapanese)?{key:"item.name_jp",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.type == 1 ? item.name_jp + ' ' + (item.invoice_date ? _vm.monthYearEnglishHelper(item.invoice_date) : '') : item.name_jp)+" ")])]}}:{key:"item.name_en",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.type == 1 ? item.name_jp + ' ' + (item.invoice_date ? _vm.monthYearEnglishHelper(item.invoice_date) : '') : item.name_jp)+" ")])]}},(_vm.isLocaleJapanese)?{key:"item.order_status_name_jp",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.orderStatusColors.bgColor[item.order_status.name_en],"text-color":_vm.orderStatusColors.textColor[item.order_status.name_en],"label":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en)+" ")])]}}:{key:"item.order_status_name_en",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.orderStatusColors.bgColor[item.order_status.name_en],"text-color":_vm.orderStatusColors.textColor[item.order_status.name_en],"label":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.isLocaleJapanese ? item.order_status.name_jp : item.order_status.name_en)+" ")])]}},{key:"item.invoice_month",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.isLocaleJapanese ? _vm.getDisplayedTerm(item.invoice_month) : item.invoice_month)+" ")])]}},{key:"item.invoice_amount",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getDisplayedMoney(item.invoice_amount))+" ")])]}},{key:"item.indonesian_labour_cost",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getDisplayedMoney(item.indonesian_labour_cost))+" ")])]}},{key:"item.japan_labour_cost",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getDisplayedMoney(item.japan_labour_cost))+" ")])]}},{key:"item.indonesian_labour_hour",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.indonesian_labour_hour ? item.indonesian_labour_hour.toLocaleString() : '0')+"H")])]}},{key:"item.japan_labour_hour",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.japan_labour_hour ? item.japan_labour_hour.toLocaleString() : '0')+"H")])]}},{key:"item.total_cost",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getDisplayedMoney(item.indonesian_labour_cost + item.japan_labour_cost))+" ")])]}},{key:"body.append",fn:function(ref){
                var headers = ref.headers;
return [(_vm.areTotalsRequested)?_c('tr',{staticClass:"black--text"},_vm._l((headers),function(header,i){return _c('td',{key:i},[_c('tr',[(header.value === 'invoice_month')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.crud.total'))+" ")]):_vm._e(),(header.value === 'invoice_amount')?_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayedMoney(_vm.invoices.reduce(function (a, b) { return a + (Number(b["invoice_amount"]) || 0); }, 0)))+" ")]):_vm._e(),(header.value === 'japan_labour_cost')?_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayedMoney(_vm.invoices.reduce(function (a, b) { return a + (Number(b["japan_labour_cost"]) || 0); }, 0)))+" ")]):_vm._e(),(header.value === 'indonesian_labour_cost')?_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayedMoney(_vm.invoices.reduce(function (a, b) { return a + (Number(b["indonesian_labour_cost"]) || 0); }, 0)))+" ")]):_vm._e(),(header.value === 'japan_labour_hour')?_c('div',[_vm._v(" "+_vm._s(_vm.invoices.reduce(function (a, b) { return a + (Number(b["japan_labour_hour"]) || 0); }, 0))+" ")]):_vm._e(),(header.value === 'indonesian_labour_hour')?_c('div',[_vm._v(" "+_vm._s(_vm.invoices.reduce(function (a, b) { return a + (Number(b["indonesian_labour_hour"]) || 0); }, 0))+" ")]):_vm._e(),(header.value === 'total_cost')?_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayedMoney(_vm.invoices.reduce(function (a, b) { return a + ((Number(b["japan_labour_cost"]) + Number(b["indonesian_labour_cost"])) || 0); }, 0)))+" ")]):_c('div')])])}),0):_vm._e()]}},(_vm.isLocaleJapanese)?{key:"item.project.project_type.name_jp",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.project.project_type.name_jp)+" ")]}}:{key:"item.projectTypeId.name_en",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.project.project_type.name_en)+" ")]}},(_vm.isHQ())?{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2 white--text",attrs:{"disabled":_vm.isLoadingTableData,"to":{ name: 'orders.edit', params: { id: item.id} },"color":"primary","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('general.crud.edit'))+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.isLoadingTableData,"color":"red","x-small":""},on:{"click":function($event){return _vm.checkSubmit(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.crud.btn.delete'))+" ")]),_c('v-dialog',{key:item.id,attrs:{"max-width":"500"},model:{value:(_vm.dialog[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog, item.id, $$v)},expression:"dialog[item.id]"}},[_c('v-card',[_c('p',{staticClass:"px-6 py-6 text-center"},[_vm._v(" "+_vm._s(_vm.dialogMessage)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"white--text",attrs:{"dark":""},on:{"click":function($event){return _vm.$set(_vm.dialog, item.id, false)}}},[_vm._v(" "+_vm._s(_vm.$t('general.validation.cancel'))+" ")]),(!_vm.cancel)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary darken-1","loading":_vm.loadingConfirm},on:{"click":function($event){return _vm.deleteSubmit(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.validation.confirm'))+" ")]):_vm._e()],1)],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }