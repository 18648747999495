<template>
  <div class="assignment-dialog">
    <v-row justify="center">
      <!-- Dialog assignment add -->
      <v-dialog v-model="dialogOpen" max-width="600px" persistent>
        <v-card>
          <!-- header  -->
          <v-toolbar color="#FFFFFF" flat>
            <v-toolbar-title>
              <span>{{ $t('general.assignmentSheet.dialogAdd.title') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- main form -->
          <v-form ref="form" @submit="saveAssignment()">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" class="pb-0 pt-0">
                    <Autocomplete
                      v-model="dataAssignmentForm.project_id"
                      :items="projects"
                      :label="$t('general.assignmentSheet.project')"
                      :rules="rules.projectId"
                    />
                  </v-col>
                  <v-col cols="6" class="pb-0 pt-0">
                    <Autocomplete
                      :value="dataAssignmentForm.member_id"
                      :items="members"
                      :label="$t('general.assignmentSheet.member')"
                      :rules="rules.memberId"
                      @input="dataAssignmentForm.member_id = $event"
                    />
                  </v-col>
                  <v-col cols="6" class="pb-0 pt-0">
                    <DatePicker
                      v-model="dataAssignmentForm.start_date"
                      :rules="rules.startDate"
                      :label="$t('general.assignmentSheet.dialogAdd.startDate')"
                    />
                  </v-col>
                  <v-col cols="6" class="pb-0 pt-0">
                    <DatePicker
                      v-model="dataAssignmentForm.end_date"
                      :rules="rules.endDate"
                      :label="$t('general.assignmentSheet.dialogAdd.endDate')"
                    />
                  </v-col>
                  <v-col cols="12" class="pb-0 pt-0">
                    <Autocomplete
                      v-model="dataAssignmentForm.duration_in_hours"
                      :items="duration"
                      :rules="rules.duration"
                      :label="$t('general.assignmentSheet.dialogAdd.workingHourPerDay')"
                    />
                  </v-col>
                  <v-col cols="3" class="py-0 pt-0">
                    <Checkbox
                      v-model="dataAssignmentForm.is_weekend"
                      :label="$t('general.assignmentSheet.dialogAdd.weekend')"
                    />
                  </v-col>
                  <v-col cols="12" class="pb-0 pt-0">
                    <Textarea
                      v-model="dataAssignmentForm.note"
                      :rule="rules.note"
                      :label="$t('general.assignmentSheet.dialogAdd.note')"
                      :appendIcon="'mdi-pencil-outline'"
                      :counter="true"
                    ></Textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              {{ $t('general.schedule.close') }}
            </v-btn>
            <v-btn color="blue darken-1" text type="submit" @click="saveAssignment">
              {{ $t('general.schedule.keep') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { store, update } from '@services/crud';
import Textarea from '@views/_components/atoms/Inputs/Textarea.vue';
import Checkbox from '@views/_components/atoms/Inputs/Checkbox.vue';
import DatePicker from '@views/_components/atoms/Inputs/DatePicker.vue';
import Autocomplete from '@views/_components/atoms/Inputs/Autocomplete.vue';

export default {
  components: {
    Textarea,
    Checkbox,
    DatePicker,
    Autocomplete,
  },
  props: {
    dialogOpen: { type: Boolean },
    selectedMember: { type: Number },
    members: {
      type: Array,
      default: function() {
        return [];
      },
    },
    projects: {
      type: Array,
      default: function() {
        return [];
      },
    },
    duration: {
      type: Array,
      default: function() {
        return [];
      },
    },
    assignmentForm: {
      type: Object,
      default: function() {
        return {
          project_id: '',
          member_id: '',
          start_date: '',
          end_date: '',
          duration_in_hours: '',
          is_weekend: false,
          note: '',
          is_edit: false,
          id: undefined,
        };
      },
    },
  },
  data: function() {
    return {
      dialogAssignment: true,
      rules: {
        startDate: [
          v => !!v || this.$t('general.assignmentSheet.dialogAdd.validation.required'),
          v => {
            if (this.assignmentForm.end_date == '' || this.assignmentForm.start_date == '') {
              return true;
            }
            return (
              new Date(this.assignmentForm.start_date).getTime() <= new Date(this.assignmentForm.end_date).getTime() ||
              this.$t('general.reports.message.start_date.greaterThanDateEnd')
            );
          },
        ],
        endDate: [
          v => !!v || this.$t('general.assignmentSheet.dialogAdd.validation.required'),
          v => {
            if (this.assignmentForm.start_date == '' || this.assignmentForm.end_date == '') {
              return true;
            }
            return (
              new Date(this.assignmentForm.end_date).getTime() >= new Date(this.assignmentForm.start_date).getTime() ||
              this.$t('general.reports.message.end_date.lessThanDateStart')
            );
          },
        ],
        duration: [
          v =>
            (!!v && (Array.isArray(v) ? v.length > 0 : true)) ||
            this.$t('general.assignmentSheet.dialogAdd.validation.required'),
        ],
        memberId: [
          v =>
            (!!v && (Array.isArray(v) ? v.length > 0 : true)) ||
            this.$t('general.assignmentSheet.dialogAdd.validation.required'),
        ],
        projectId: [
          v =>
            (!!v && (Array.isArray(v) ? v.length > 0 : true)) ||
            this.$t('general.assignmentSheet.dialogAdd.validation.required'),
        ],
        note: [
          v => !!v || this.$t('general.assignmentSheet.dialogAdd.validation.required'),
          v => (v || '').length <= 2000 || this.$t('general.reports.message.progressLength'),
        ],
      },
      dataAssignmentForm: {
        start_date: '',
        end_date: '',
        duration_in_hours: [],
        is_weekend: false,
        member_id: [],
        project_id: [],
        note: '',
        is_edit: false,
        id: undefined,
      },
    };
  },
  watch: {
    // watch which member of assignment add button was clicked
    selectedMember: {
      immediate: true,
      handler: function(newMemberId) {
        this.selectItem(newMemberId);
      },
    },
    assignmentForm: {
      immediate: true,
      handler: function(newAssignmentForm) {
        this.dataAssignmentForm = newAssignmentForm;
      },
    },
  },
  methods: {
    // Save assignment data
    async saveAssignment() {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        let url = '/assignment-sheet/create';
        try {
          if (this.dataAssignmentForm.is_edit) {
            url = '/assignment-sheet/update';
            await update(url, this.dataAssignmentForm);
          } else {
            await store(url, this.dataAssignmentForm);
          }
          this.initValues();
          this.$emit('close-dialog');
        } catch (error) {
          console.log('saveAssignment error', error);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$refs.form.resetValidation();
      this.initValues();
      this.$emit('close-dialog');
    },
    // Set init value of member
    selectItem(memberId) {
      this.dataAssignmentForm.member_id = memberId;
    },
    initValues() {
      this.dataAssignmentForm = {
        start_date: '',
        end_date: '',
        duration_in_hours: [],
        is_weekend: false,
        member_id: [],
        project_id: [],
        note: '',
        is_edit: false,
        id: undefined,
      };
    },
  },
};
</script>
