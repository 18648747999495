<template>
  <div class="input-row" :class="{center_element: centered}">
    <div class="input-title">
      <strong class="input-title">{{ title }}</strong>
      <br>
      <span class="input-title-label required" v-if="required">
        {{ $t('general.validation.required') }}
      </span>
      <span class="input-title-label optional" v-if="optional">
        {{ $t('general.validation.optional') }}
      </span>
    </div>
    <div :class="classBottom">
      <slot></slot>
    </div>
  </div>
  <!-- <v-row :class="{center_element: centered}">
    <v-col lg="2" md="2" cols="12" :class="classTop">
        <strong class="input-title">{{ title }}</strong>
        <br>
        <span class="input-title-label required" v-if="required">
          {{ $t('general.validation.required') }}
        </span>
        <span class="input-title-label optional" v-if="optional">
          {{ $t('general.validation.optional') }}
        </span>
    </v-col>
    <v-col md="10" cols="12" :class="classBottom">
      <slot></slot>
    </v-col>
  </v-row> -->
</template>

<script>
export default {
  props: {
    title: {
      required: true,
    },
    centered: {
      default: true,
      type: Boolean
    },
    classTop: {
      default: "pt-0 mb-0",
      type: String
    },
    classBottom: {
      default: "pb-0 mb-0",
      type: String
    },
    required: Boolean,
    optional: Boolean,
  },
};
</script>

<style lang="scss">
.input-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: start !important;
  justify-content: start;
}
.input-row > div:first-of-type {
  flex-grow: 0;
  flex-shrink: 0;
  width: min(calc(100vw / 12), 100px);
  margin-right: 0;
  padding-top: 3px;
}

.input-row > div:last-of-type {
  flex: 1;
  margin-right: 0;
}

.v-input__icon--append > .v-icon {
  font-size: 16px !important;
  font-style: normal !important;
}

@media screen and (max-width: 960px) {
  .input-row {
    flex-wrap: wrap;
  }
  .input-row > div:first-of-type, .input-row > div:last-of-type {
    flex-grow: 1;
    width: 100%;
  }
}


.center_element{
  display: flex;
  align-items: center;
}
.input-title {
  font-size: 20px;
  padding:0;
}
.input-title-label {
  font-size: 9px;
  position: relative;
  padding: 1px 4px;
  padding-top: 2px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 0px;
  margin-top: 4px;
}
.input-title-label.required {
  color: red;
  border: solid 1px red;
}
.input-title-label.optional {
  color: green;
  border: solid 1px green;
}
</style>
