import Form from '@views/main/order/Form';
import constants from "@/const/const";

const ordersRoutes = [
  {
    path: 'orders/create',
    name: 'orders.create',
    component: Form,
    meta: {
        //only Japanese users
        restricted: constants.permissions.nonHQ
      },
  },
  {
    path: 'orders/:id/edit',
    name: 'orders.edit',
    component: Form,
    meta: {
      editPage: true,
      //only Japanese users
      restricted: constants.permissions.nonHQ
    }
  }
]

export default ordersRoutes;