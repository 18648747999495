<template>
  <div class="row" :class="{center_element: centered}">
    <div class="col-sm-12 d-flex pt-0 pr-0 align-items-left" :class="textColumn">
      <strong class="input-title md-4 pr-0">{{ title }}</strong>
    </div>
    <div class="col-sm-12 pa-0 ma-0" :class="inputColumn">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
    },
    title_width: {
      default: 2,
    },
    centered: {
      default: true,
      type: Boolean
    },
    required: Boolean,
    optional: Boolean,
  },
  computed: {
    textColumn: function() {
      return ['col-md-'+this.title_width];
    },
    inputColumn: function() {
    return ['col-md-'+(12-this.title_width)];
    }
  }
};
</script>

<style lang="scss">
  .center_element{
      display: flex;
      align-items: center;
  }
  .input-title {
    font-size: 11px !important;
  }
</style>
