<template>
  <div class="member">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.member.list.pageTitle') }}</span>
    </p>
    <!-- Page container -->
    <PageInnerSet :title="$t('general.member.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter" class="mb-10">
        <!-- <span class="text-h6 text--secondary">> {{ $t('general.reports.condition') }}</span> <br /> -->
        <!-- <FilterReset @click="resetFilter" class="pb-10"></FilterReset> -->

        <v-row cols="12">
          <v-col cols="3">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div multiple v-on="on">
                      <FilterTextSelect
                        :title="$t('general.member.team') + '：'"
                        :title_width="3"
                        :items="selectBoxData.teams"
                        v-bind="attrs"
                        v-model="searchingFormData.team"
                        @input="updateAllFilters"
                      />
                    </div>
                </template>
                <span>{{ searchingFormData.team ? tooltipItem(searchingFormData.team) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div multiple v-on="on">
                      <FilterTextSelect
                        :title="$t('general.member.name') + '：'"
                        :title_width="4"
                        :items="selectBoxData.memberNames"
                        v-bind="attrs"
                        v-model="searchingFormData.memberName"
                        @input="updateAllFilters"
                      />
                    </div>
                </template>
                <span>{{ searchingFormData.memberName ? tooltipItem(searchingFormData.memberName) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div multiple v-on="on">
                      <FilterTextSelect
                        :title="$t('general.member.status') + '：'"
                        :title_width="4"
                        :items="selectBoxData.memberStatuses"
                        v-model="searchingFormData.memberStatus"
                        v-bind="attrs"
                        @input="updateAllFilters"
                      />
                    </div>
                </template>
                <span>{{ searchingFormData.memberStatus ? tooltipItem(searchingFormData.memberStatus) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <!-- <v-row class="pt-5">
        <v-col cols="12">
          <span class="text-h6 text--secondary">> {{ $t('general.reports.result') }}</span>
        </v-col>
      </v-row> -->
      <br />
      <div class="text">
        {{ isLocaleJapanese() ? tabelAboveText.jp : tabelAboveText.en }}
      </div>
      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="members"
        :options.sync="options"
        :page.sync="currentPage"
        :server-items-length="totalMembers"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        hide-default-footer
        @page-count="totalPages = $event"
        fixed-header
        height="700px"
        dense
      >
        <template v-if="checkSortBy" v-slot:body="props">
          <draggable v-model="props.items" tag="tbody" :move="onMove" @change="changeOrder(props.items)">
            <tr class="all-scroll" v-for="(member, index) in props.items" :key="index">
              <td>{{ member.team_name }}</td>
              <td>{{ member.name }}</td>
              <td>{{ member.role_title }}</td>
              <td>{{ member.permission_name }}</td>
              <td>{{ member.member_status }}</td>
              <td v-if="isAdmin()">
                <v-btn
                  v-if="isJpnTeam() || (!isJpnTeam() && member.team_id == 2)"
                  :disabled="isLoadingTableData"
                  :to="{ name: 'members.edit', params: { id: member.id } }"
                  color="primary"
                  class="mx-2 white--text"
                  x-small
                >
                  {{ $t('general.crud.edit') }}
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
        <template v-if="isAdmin() && !checkSortBy" v-slot:[`item.action`]="{ item }">
          <v-btn
            v-if="isJpnTeam() || (!isJpnTeam() && item.team_id == 2)"
            :disabled="isLoadingTableData"
            :to="{ name: 'members.edit', params: { id: item.id } }"
            color="primary"
            class="mx-2 white--text"
            x-small
            >{{ $t('general.crud.edit') }}</v-btn
          >
        </template>
      </v-data-table>

      <!-- <div class="text-center pt-2 mt-3">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="7"
        ></v-pagination>
      </div> -->

      <v-overlay v-if="isLoading" value="Loading...">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </PageInnerSet>
  </div>
</template>

<script>
import { getAll, getSelectBoxData, store } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import draggable from 'vuedraggable';
import io from 'lodash';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.member.list'),
    };
  },
  components: {
    FilterTextSelect,
    PageInnerSet,
    draggable,
  },
  data: function() {
    return {
      tabelAboveText: {
        jp: 'メンバーのソート順が変更されていなければ、ドラッグ＆ドロップでメンバーの位置を編集することができます。',
        en:
          'When the sorting order of the members is unaltered, the position of the members can be edited by drag & drop.',
      },
      members: [],
      totalMembers: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        multiSort: true,
        sortBy: [],
        sortDesc: [],
      },
      searchingFormData: {
        team: 0,
        memberName: '',
        memberStatus: '',
      },
      activeFilters: {},
      selectBoxData: {
        teams: [],
        memberNames: [],
        memberStatuses: [],
      },
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
      isLoading: false,
    };
  },
  mounted: function() {
    this.getAllMembers();
    this.retrieveSelectBoxData();
  },
  created() {
    let test = this.members.sort(function(a, b) {
      return a.team_id > b.team_id ? 1 : -1;
    });
    this.members = test;
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.member.team'),
          value: 'team_name',
          sortable: true,
        },
        {
          text: this.$t('general.member.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('general.member.role'),
          value: 'role_title',
          sortable: true,
        },
        {
          text: this.$t('general.member.permission'),
          value: 'permission_name',
          sortable: true,
        },
        {
          text: this.$t('general.member.status'),
          value: 'member_status',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'center fixed',
          class: 'fixed',
        },
      ];
    },
    checkSortBy() {
      if (this.options.sortBy.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    onMove: function(e) {
      if (this.members[e.draggedContext.index].team_id !== this.members[e.draggedContext.futureIndex].team_id)
        return false;
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    isAdmin: function() {
      return this.$store.state.auth.info.permission == 1 ? true : false;
    },
    isJpnTeam: function() {
      return this.$store.state.auth.info.team == 1 ? true : false;
    },
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of members
     * - sets retrieved data to corresponding data
     */
    getAllMembers: async function() {
      let url = 'members';
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of members from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.members = res.members.data;
        this.totalMembers = res.members.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber = false) {
      this.getAllMembers();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      this.$refs.filter.reset();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('team', this.searchingFormData.team);
      this.updateFilter('memberName', this.searchingFormData.memberName);
      this.updateFilter('memberStatus', this.searchingFormData.memberStatus);
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page
     * Not all of our filters are arrays so
     */
    updateFilter: function(attr, val) {
      if (val) {
        if (Array.isArray(val)) {
          val = val.join('||');
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/members/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        this.selectBoxData.teams = res.teams.map(item => item.name);
        this.selectBoxData.memberNames = res.memberNames.map(item => item.name);
        this.selectBoxData.memberStatuses = res.memberStatus.map(item => item.name);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    async changeOrder(item) {
      try {
        var vm = this;
        vm.isLoading = true;
        let payload = new FormData();
        let data = [];
        const { itemsPerPage, page, sortBy, sortDesc } = vm.options;
        if (!io.isEmpty(item)) {
          var i = 1;
          io.forEach(item, function(value) {
            data.push({
              id: value.id,
              order: value.order,
              name: value.name,
              key: i++,
            });
          });
          payload.append('dataset', JSON.stringify(data));
          payload.append(
            'filter',
            JSON.stringify({
              itemsPerPage,
              page,
              sortBy,
              sortDesc,
              ...vm.activeFilters,
            })
          );
          var url = '/membersOrder';
          const res = await store(url, payload);
          vm.members = res;
        }
      } catch (error) {
        console.log(error);
      } finally {
        vm.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.member {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 340px)  !important;
  }
  .v-data-table__wrapper {
    border: 0 !important;
  }
  .all-scroll {
    cursor: all-scroll;
  }
  .v-pagination__navigation {
    display: none;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
  }
  // setting scrollbar
  /* .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  } */
  
  .v-select__selections::-webkit-scrollbar-track {
    background-color:white;
  }
  
  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  .v-chip {
    overflow: initial;
    font-size: 11px !important;
  }
  .v-select__selection--comma{
    overflow: visible;
  }
}

.v-input__control {
  .v-input__slot {
    height: 48px;
    /* padding-bottom: 5px !important; */
  }
  
}

.text {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
