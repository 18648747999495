<!-- https://mengxiong10.github.io/vue2-datepicker/index.html -->
<template>
    <div class="custom-date-picker">
        <DatePicker
            required
            v-model="inputVal"
            format="YYYY-MM-DD"
            value-type="format"
            class="mb-1 datepicker"
            :lang="this.isLocaleJapanese() ? datePickerLanguageSetting.ja : datePickerLanguageSetting.en"
        ></DatePicker>
          <v-text-field
            clearable
            readonly
            v-model="inputVal"
            :rules="rules"
          ></v-text-field>
    </div>
</template>

<script>
import constants from '@/const/const';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  props: {
    value: String,
    rules: Array,
    disabled: { default: false },
    type: { default: '' },
  },
  data() {
    return {
      datePickerLanguageSetting: constants.datePickerLanguageSetting,
    };
  },
  methods: {
    isLocaleJapanese: function () {
      return this.$store.state.global.locale === 'ja';
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: {
    DatePicker,
  },
}
</script>

<style>
.custom-date-picker .v-input__slot {
  display: none;
}

.custom-date-picker .v-text-field {
  padding-top: 0;
}

.custom-date-picker .datepicker {
  max-width: 200px;
  width: 100%;
  height: 56px;
}

.custom-date-picker .datepicker .mx-input-wrapper,
.custom-date-picker .datepicker  .mx-input-wrapper input {
  width: 100%;
  height: 100%;
}

.custom-date-picker .datepicker  .mx-input-wrapper input {
  font-size: 16px;
}

.custom-date-picker .datepicker  .mx-input-wrapper input:focus {
  border-width: 2px !important;
}
</style>