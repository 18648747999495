<template>
  <td class="text-center px-0 py-0" :style="styles">
    <v-tooltip top :disabled="tooltip === undefined">
      <template v-slot:activator="{ on }">
        <div multiple v-on="on" @click="openAssignmentDialog">
          <span v-if="totalHours">{{ totalHours }}H</span>
          <span v-else></span>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </td>
</template>

<script>
import { filter, sumBy } from 'lodash';

export default {
  props: {
    date: { type: String, default: undefined },
    color: { type: String, default: 'transparent' },
    project: { type: Object, default: () => {} },
    reports: { type: Array, default: () => [] },
    member: { type: Object, default: () => {} },
    assignmentDialog: { type: Function, default: () => {} },
  },

  data: function() {
    return {
      dialogAssignment: true,
      assignmentForm: {
        start_date: '',
        end_date: '',
        duration_in_hours: [],
        is_weekend: false,
        member_id: [],
        project_id: [],
        note: '',
        id: undefined,
        is_edit: false,
      },
    };
  },

  computed: {
    todayReports() {
      const today = this.date;
      const projectID = this.project.id;
      return filter(this.reports, function(report) {
        return report.date === today && report.project_id === projectID;
      });
    },

    totalHours() {
      return sumBy(this.todayReports, 'time');
    },

    tooltip() {
      const note = this.todayReports.map(report => report.note);
      return note[0];
    },

    styles() {
      const backgroundColor = this.totalHours ? this.color : '#F4F4F4';
      return { backgroundColor };
    },
  },

  methods: {
    openAssignmentDialog() {
      const currentReport = this.todayReports[0];
      this.$props.assignmentDialog(this.member.id);
      if (this.assignmentDialog !== undefined) {
        this.assignmentForm.start_date = currentReport.start_date;
        this.assignmentForm.end_date = currentReport.end_date;
        this.assignmentForm.duration_in_hours = currentReport.time;
        this.assignmentForm.is_weekend = currentReport.is_weekend;
        this.assignmentForm.note = currentReport.note;
        this.assignmentForm.member_id = this.member.id;
        this.assignmentForm.project_id = this.project.id;
        this.assignmentForm.is_edit = true;
        this.assignmentForm.id = currentReport.id;

        this.$emit('assignment-form', this.assignmentForm);
      }
    },
  },
};
</script>
