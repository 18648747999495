import vuexStore from '@/store';
import moment from 'moment';
export const convArrToObj = function(arr, keyBy = 'value') {
  return arr.reduce(function(obj, next) {
    let { [keyBy]: index, ...rest } = next;
    obj[index] = rest;
    return obj;
  }, {});
};

export const pushNotif = function(msg, type) {
  let notif = {
    text: msg,
    isOpen: true,
    color: type,
    multiLine: true,
  };
  vuexStore.commit('global/setNotification', notif);
};

export const formatDateHelper = function(dates) {
  if (!dates) {
    return '';
  } else {
    // const date = new Date(dates);
    // const year = date.getFullYear();
    // const month = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    // const day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();

    // return year + "/" + month + "/" + day;
    return moment(dates).format('YYYY/MM/DD');
  }
};

export const formatDateTimeHelper = function(datetime) {
  if (!datetime) {
    return '';
  }

  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 <= 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
  const hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
  const minute = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

  return `${year}/${month}/${day} ${hour}:${minute}`;
};

export const displayedMoneyHelper = function(money, locale) {
  /*if (money === null) {
    return money;
  }
  //converts to number if not already
  if (typeof money === "string") {
    money = Number(money);
  }
  return money.toLocaleString() + currency;*/

  //default value of null replaced with 0
  if (!money) {
    money = 0;
  }
  if (typeof money === 'string') {
    if (locale) {
      //japanese
      return Number(money).toLocaleString() + this.$t('general.invoice.yen');
    } else {
      //english
      return '¥' + Number(money).toLocaleString();
    }
  }
  //if money is already number
  if (locale) {
    return money.toLocaleString() + this.$t('general.invoice.yen');
  } else {
    return '¥' + money.toLocaleString();
  }
};

export const monthYearOnlyHelper = function(dates) {
  //this function converts date strings from the api into year/month strings
  //example 2019-05-13 -> 2019-05
  if (!dates) {
    return '';
  }
  if (dates) {
    for (var key in dates) {
      //converts the yyyy-mm-dd format to yyyy-mm format
      if (dates[key].date) {
        dates[key].date = dates[key].date
          .split('-')
          .slice(0, 2)
          .join('-');
      } else {
        dates[key].date = '';
      }
    }
    return dates;
  }
};

export const monthYearTermJapanHelper = function(monthYear) {
  //convert month and year format 2021-09 into 2021年09月
  return `${monthYear.split('-')[0]}年${monthYear.split('-')[1]}月`;
};

export const monthYearEnglishHelper = function(date) {
  //convert month and year format 2021-09 into 2021年09月
  if (date) {
    return `${date.split('-')[0]}-${date.split('-')[1]}`;
  } else {
    return '';
  }
};
