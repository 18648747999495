import DataTable from '@views/main/assignment-v2/Datatable.vue';

const assignmentRoutes = [
  {
    path: 'assignment-sheet',
    name: 'assignmentSheet',
    component: DataTable,
    props: route => ({ ...route.query, ...route.params }),
    meta: {
      //only Admin users can view
      restricted: [],
    },
  },
];

export default assignmentRoutes;
