import DataTable from '@views/main/morning_report/Datatable';
import Form from '@views/main/morning_report/Form';

const projectsRoutes = [
  {
    path: 'morning-reports',
    name: 'morningReports',
    component: DataTable,
    meta: {
        //all can view except restricted user
        restricted: []
      },
  },
  {
    path: 'morning-reports/add',
    name: 'morningReports.add',
    component: Form,
    meta: {
        //only Admin users can view
        restricted: []
      },
  },
  {
    path: 'morning-reports/:id/edit',
    name: 'morningReports.edit',
    component: Form,
    meta: {
      editPage: true,
      restricted: []
    }
  }
]

export default projectsRoutes;
