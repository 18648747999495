import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './localization';

import vuetify from './plugins/vuetify';
import '@/plugins/axios.js';
import '@/plugins/v-currency-field.js';
import '@/plugins/vue-columns-resizable-vuetify.js';
import '@/plugins/cookie.js';
// import '@fortawesome/fontawesome-free/css/all.css';
// import '@fortawesome/fontawesome-free/js/all.js';
import '@/plugins/moment.js';
import './assets/style/global.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
