<template>
  <div class="page-innerset">
    <div class="innerset-header">
      
      <PageTitle>{{title}}</PageTitle>
      <PageHorizon v-if="divider"></PageHorizon>

      <slot name="subtitle"></slot>
    </div>
    <div class="innerset-body py-2 px-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@views/_components/organisms/PageTitle.vue'
  import PageHorizon from '@views/_components/organisms/PageHorizon.vue';
  export default {
    components: {
      PageTitle,
      PageHorizon,
    },
    props: {
      title: { default: 'Page' },
      divider: { default: true },
    },
  };
</script>

<style lang="scss">
  // @import "@/assets/style/mixins";

  html, body {
    overflow: initial !important
  }

  .page-innerset {
    height: auto;
    /* border: 2px solid orange; */
    display: flex;
    flex-direction: column;
    flex: 1;

    .innerset-header {
      background-color: #fff;
      flex: 0;
    }
    .innerset-body {
      flex: 1;
      /* border: 2px solid lime; */
      display: flex;
      flex-direction: column;
    }
  }
  .v-chip {
    max-width: max-content;
    overflow: initial;
  } 
</style>