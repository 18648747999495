<template>
  <div>
    <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"></v-skeleton-loader>

    <div v-if="!loadingComponent">
      <!-- Breadcrumbs -->
      <p class="mx-1 mb-0 text-caption text--secondary">
        <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
        <span class="mx-2">></span>
        <span>{{ editPage ? $t('general.project.edit.pageTitle') : $t('general.project.create.pageTitle') }}</span>
      </p>
      <!-- Page container -->
      <PageInnerSet :title="editPage ? $t('general.project.edit.pageTitle') : $t('general.project.create.pageTitle')" class="pb-16">
        <v-form ref="projectForm" @submit.prevent="submit">
          <g-input-group required :title="$t('general.project.type')">
            <v-select
              v-if="this.isLocaleJapanese()"
              outlined
              v-model="item.typeId"
              :items="formData.projectTypes"
              item-text="name_jp"
              item-value="id"
              :rules="rules.type"
            ></v-select>

            <v-select
              v-else
              outlined
              v-model="item.typeId"
              :items="formData.projectTypes"
              item-text="name_en"
              item-value="id"
              :rules="rules.type"
            ></v-select>
          </g-input-group>
          <g-input-group required :title="$t('general.project.name')">
            <v-text-field
              v-if="editPage"
              outlined
              clearable
              v-model="item.name">
            </v-text-field>
            <v-combobox
              v-else
              outlined
              clearable
              v-model="item.name"
              :items="formData.projectNames"
              item-text="name"
              item-value="name"
              @input="$v.item.name.$touch()"
              @blur="$v.item.name.$touch()"
              @change="resetProjectExists"
              :hide-details="'auto'"
              :error="projectExists ? true : projectNameError.length > 0"
              :error-messages="projectExists ? projectExists : ''"
            >
            </v-combobox>
            <div v-if="item.name && projectExists == ''">
              <small>{{ $t('general.project.projectNameMessage') }}</small>
            </div>
            <div v-for="error in projectNameError" :key="error" class="text-danger error--text">
              <small>{{ error }}</small>
            </div>
          </g-input-group>
          <g-input-group required :title="$t('general.project.customer')">
            <v-combobox
              outlined
              clearable
              v-model="item.customerId"
              :items="formData.customerNames"
              :item-text="'name'"
              :item-value="'name'"
              :rules="rules.customer"
            ></v-combobox>
          </g-input-group>
          <g-input-group required :title="$t('general.project.color')">
            <v-color-picker
              dot-size="25"
              mode="hexa"
              swatches-max-height="200"
              v-model="color"
            ></v-color-picker>
          </g-input-group>
          <div class="pt-10">
            <v-btn :loading="loadingBtn" type="submit">SUBMIT</v-btn>
          </div>
        </v-form>
      </PageInnerSet>
    </div>
  </div>
</template>

<script>
import { store, getForm, update } from '@services/crud';
import GInputGroup from '@components/form_input/GInputGroup.vue';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { pushNotif } from '@/helpers';
import Vue from 'vue';

export default {
  // ------------------------------------------------------
  // MIXINS AND VALIDATIONS
  // ------------------------------------------------------
  mixins: [validationMixin],
  validations: {
    item: {
      name: {
        required,
      },
    },
  },
  data() {
    return {
      rules: {
        customer: [
          (v) => !!v || this.$t('general.project.customerValidation'),
          (v) => typeof v !== 'string' || this.$t('general.project.customerValidation'),
        ],
        type: [
          (v) => !!v || this.$t('general.project.typeValidation'),
          (v) => typeof v !== 'string' || this.$t('general.project.typeValidation'),
        ],
      },
      item: {
        id: null,
        typeId: null,
        name: null,
        customerId: null,
        color: '#FFFFFF',
      },
      formData: {},
      editPage: false,
      submitUrl: '',
      loadingComponent: false,
      loadingBtn: false,
      projectExists: '',
    };
  },
  computed: {
    // ------------------------------------------------------
    // SEND NAME ERROR
    // ------------------------------------------------------
    projectNameError() {
      const errors = [];
      if (!this.$v.item.name.$dirty) return errors;
      !this.$v.item.name.required && errors.push(this.$t('general.project.customerValidation'));
      return errors;
    },
    color: {
      get () {
        return this.item.color
      },
      set (v) {
        this.item.color = v
      },
    },
  },
  methods: {
    async submit() {
      var vm = this;
      vm.$v.item.name.$touch();
      if (vm.$refs.projectForm.validate() && !vm.$v.item.name.$invalid) {
        vm.loadingBtn = true;
        let options = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        let payload = new FormData();
        //note, these must match the db columns we want to populate
        payload.append('project_type_id', vm.item.typeId);
        //if the user select a new value for combobox
        if (typeof vm.item.name.name === 'string') {
          payload.append('name', vm.item.name.name);
        }
        //if the user didn't select a new value for combobox
        else {
          payload.append('name', vm.item.name);
        }
        payload.append('customer_id', vm.item.customerId.id);
        payload.append('color', vm.item.color);

        payload.append('log_type', 2);
        payload.append('updated_by', this.$store.state.auth.info.username)

        console.log("this is submit");
        console.log(vm.submitUrl);
        console.log(payload);
        const res = await Vue.axios.post(vm.submitUrl, payload);
        if (res && !res.data.data.isExists) {
            pushNotif(res.data.message, 'success');
            vm.projectExists = '';
            vm.$router.push({ name: 'projects' });
        } else {
          vm.projectExists = this.$t('general.validation.projectExists');
        }
        vm.loadingBtn = false;
      } else {
        vm.$nextTick(() => {
          let domRect = document.querySelector('.error--text').getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop - 200
          );
        });
        vm.loadingBtn = false;
      }
    },
    isLocaleJapanese: function () {
      return this.$store.state.global.locale === 'ja';
    },
    findCustomerbyId: function (data, id) {
      for (const customer of data) {
        if (customer.id == id) {
          return customer;
        }
      }
    },
    resetProjectExists() {
      this.projectExists = '';
    },
  },
  async created() {
    this.loadingComponent = true;
    //fetches the form data, submitURL and this.item(edit only) from the api
    const form = await getForm(this.$route.path);
    //edit(see router/project.js file)
    if (this.$route.meta.editPage) {
      this.editPage = true;
      let { formData, submitUrl, item } = form;

      this.item = {
        ...this.item,
        typeId: item.project_type_id,
        name: item.name,
        customerId: this.findCustomerbyId(formData.customerNames, item.customer_id),
        color: item.color == null ? '#FFFFFF' : item.color,
      };
      this.formData = formData;
      this.submitUrl = submitUrl;
    } //create
    else {
      let { formData, submitUrl } = form;
      this.formData = formData;
      this.submitUrl = submitUrl;
    }
    this.loadingComponent = false;
  },
  components: {
    GInputGroup,
    PageInnerSet,
  },
};
</script>