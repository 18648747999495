import ErrorNotFound from '@views/error/ErrorNotFound';
import ErrorUnexpected from '@views/error/ErrorUnexpected';
import ErrorUnauthorized from '@views/error/ErrorUnauthorized';
const errors = [
  {
    path: '/404',
    name: 'errorNotFound',
    component: ErrorNotFound,
  },
  {
    path: '/500',
    name: 'errorUnexpected',
    component: ErrorUnexpected,
  },
  {
    path:'/401',
    name: 'ErrorUnauthorized',
    component: ErrorUnauthorized
  }
];

export default errors;
