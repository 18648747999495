var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('p',{staticClass:"mx-1 mb-0 text-caption text--secondary"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(_vm._s(_vm.$t('general.nav.dashboard')))]),_c('span',{staticClass:"mx-2"},[_vm._v(">")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.project.list.pageTitle')))])],1),_c('PageInnerSet',{attrs:{"title":_vm.$t('general.project.list.pageTitle')}},[_c('v-form',{ref:"filter"},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[(_vm.isLocaleJapanese())?_c('FilterSelect',{attrs:{"title":_vm.$t('general.project.type') + '：',"title_width":3,"items":_vm.selectBoxData.projectTypes,"option":['id', 'name_jp'],"chips":"","deletable-chips":""},on:{"input":_vm.updateAllFilters},model:{value:(_vm.searchingFormData.projectTypeId),callback:function ($$v) {_vm.$set(_vm.searchingFormData, "projectTypeId", $$v)},expression:"searchingFormData.projectTypeId"}}):_c('FilterSelect',{attrs:{"title":_vm.$t('general.project.type') + '：',"title_width":3,"items":_vm.selectBoxData.projectTypes,"option":['id', 'name_en']},on:{"input":_vm.updateAllFilters},model:{value:(_vm.searchingFormData.projectTypeId),callback:function ($$v) {_vm.$set(_vm.searchingFormData, "projectTypeId", $$v)},expression:"searchingFormData.projectTypeId"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('FilterTextSelect',_vm._b({attrs:{"title":_vm.$t('general.project.name') + '：',"title_width":4,"items":_vm.selectBoxData.projectNames},on:{"input":_vm.updateAllFilters},model:{value:(_vm.searchingFormData.projectName),callback:function ($$v) {_vm.$set(_vm.searchingFormData, "projectName", $$v)},expression:"searchingFormData.projectName"}},'FilterTextSelect',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.searchingFormData.projectName ? _vm.tooltipItem(_vm.searchingFormData.projectName) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('FilterTextSelect',_vm._b({attrs:{"title":_vm.$t('general.project.customer') + '：',"title_width":3,"items":_vm.selectBoxData.customerNames},on:{"input":_vm.updateAllFilters},model:{value:(_vm.searchingFormData.customerName),callback:function ($$v) {_vm.$set(_vm.searchingFormData, "customerName", $$v)},expression:"searchingFormData.customerName"}},'FilterTextSelect',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.searchingFormData.customerName ? _vm.tooltipItem(_vm.searchingFormData.customerName) : _vm.$t('general.tooltip.noSelect')))])])],1),_c('v-col',[_c('div',{staticClass:"pt-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetFilter}},[_vm._v(_vm._s(_vm.$t('general.form.reset')))])],1)])],1)],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"options":_vm.options,"page":_vm.currentPage,"server-items-length":_vm.totalProjects,"loading":_vm.isLoadingTableData,"loading-text":_vm.$t('general.crud.loading'),"hide-default-footer":"","fixed-header":"","height":"700px","dense":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalPages = $event}},scopedSlots:_vm._u([(_vm.isLocaleJapanese())?{key:"item.project_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_type.name_jp)+" ")]}}:{key:"item.project_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_type.name_en)+" ")]}},(_vm.isHQ())?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2 white--text",attrs:{"disabled":_vm.isLoadingTableData,"to":{ name: 'projects.edit', params: { id: item.id } },"color":"primary","x-small":""}},[_vm._v(_vm._s(_vm.$t('general.crud.edit')))])]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }