<template>
  <v-menu
    min-width="290px"
    max-width="350px"
    offset-y
    transition="scale-transition"
    v-model="isOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    >
    <template v-slot:activator="{ on, attrs }">
      <!-- setting input text and icon -->
      <v-text-field
        clearable
        readonly
        prepend-icon="mdi-calendar"
        v-bind="{ ...attrs, ...tfAttrs }"
        v-model="inputVal"
        v-on="on"
        :rules="rules"
      ></v-text-field>
    </template>
    <!-- this is like a calender -->
    <v-date-picker
      v-model="inputVal"
      v-bind="dpAttrs"
      :disabled="disabled"
      :locale="$i18n.locale"
      :type="type"
      @input="isOpen = false"
      @change="$emit('change', $event)"
    ></v-date-picker>
  </v-menu>
</template> 

<script>
  export default {
    inheritAttrs: false,
    props: {
      value: String,
      rules: Array,
      disabled: { default: false },
      type: { default: '' },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      /* value is a select items */
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      dpAttrs() {
        return Object.entries(this.$attrs).reduce((acc, [key, val]) => {
          if (key.substring(0, 2) == 'dp') {
            return { [key.substring(3, key.length)]: val, ...acc};
          }else {
            return acc;
          }
        }, {});
      },
      tfAttrs() {
        return Object.entries(this.$attrs).reduce((acc, [key, val]) => {
          if (key.substring(0, 2) == 'tf') {
            return { [key.substring(3, key.length)]: val, ...acc };
          } else {
            return acc;
          }
        }, {});
      },
    },
  }
</script>