<template>
  <v-textarea
    outlined
    dense
    clearable
    v-model="valueModel"
    :append-icon="appendIcon"
    :label="label"
    :rows="rows"
    :row-height="rowHeight"
    :message="message == null ? null : message"
    :rules="rule"
    :readonly="readonly"
    :counter="counter"
    :full-width="fullWidth"
  ></v-textarea>
</template>

<script>
  export default{
  /*
  * label: Setting of label
  * rows: How many rows to display first
  * rowHeight: Height of each row
  */
    props:{
      value: { type: String, default: '' },
      label: { type: String, default: 'undefined' },
      rows: { type: String, default: '5' },
      rowHeight: { type: String, default: '24' },
      appendIcon: { type: String, default: '' },
      message: { type: String, default: null },
      rule: { type: Array, default: null },
      readonly: { type: Boolean, default: false },
      counter: { type: [Boolean, Number, String], default: 0 },
      fullWidth: { type: Boolean, default: false }
    },
    computed: {
      valueModel: {
        get() {
          return this.value
        },
        set(value){
          this.$emit('input', value);
        }
      },
    },
  };
</script>
