<!-- document 
(e.g.) 
<FilterDateRange title="登録日時:" v-model="registrationDate" @input="onChangeDate"/>

required:
- title : caption

options:
- v-model: [minDate, maxDate]. Its type is 'date' (because of javascript implementation, it's returned as 'object' type)
      see 'value-type' of 'Props' section in the official document (https://www.npmjs.com/package/vue2-datepicker)
- @input: call input as soon as it gets changed 

--> 
<template>
  <InputGroup :title="title" :required="required" :optional="optional" class="mt-3" :classTop="classTop" :classBottom="classBottom">
    <!-- Multiple input mode - Start -->
    <div class="row">
      <div class="col-sm py-0">
        <GDatePicker 
                v-model="minDate" 
                :type="type"
                :format="format"
                :rules="rules_start"
                value-type="format"
              ></GDatePicker>
      </div>
      <div class="col-sm-auto py-0 d-none d-sm-block">
        <div class="pt-5 px-5">
          <span class="center">~</span>
        </div>
      </div>
      <div class="col-sm py-0">
        <GDatePicker 
                :type="type"
                v-model="maxDate" 
                :format="format"
                :rules="rules_end"
                value-type="format"
              ></GDatePicker>
      </div>
    </div>
    <!-- Multiple input mode - End -->

  </InputGroup>
</template>

<script>
  import io from 'lodash';
  import GDatePicker from '@components/form_input/GDatePicker.vue';
  import InputGroup from '@views/_components/form_input/GInputGroup';

  export default {
    components: { InputGroup, GDatePicker },
    data(){
      return {
        minDate: io.get( this, 'value[0]') || null,
        maxDate: io.get( this, 'value[1]') || null
      }
    },
    props: {
      rules_start: Array,
      rules_end: Array,
      title: { required: true },
      value: { required: true },
      format: { default: 'YYYY-MM' },
      required: { default: false },
      optional: { default: false },
      disabled: { default: false },
      type: { default: "date" },
      single: { type: Boolean, default: false },
      classTop: {
        default: "pt-0 mb-0",
        type: String
      },
      classBottom: {
        default: "pb-0 mb-0",
        type: String
      },
    },
    watch: {
      minDate() {
        this.$emit( 'input', [ this.minDate, this.maxDate ]);
      },
      maxDate(){
        this.$emit( 'input', [ this.minDate, this.maxDate ]);
      },
    }
  };
</script>
