import Form from '@views/main/customer/Form';
import DataTable from '@views/main/customer/Datatable.vue';
import constants from "@/const/const";


const customersRoutes = [
  {
    path: 'customers',
    name: 'customers',
    component: DataTable,
    meta: {
        //only Japanese team(HQ) can see this page
        restricted: constants.permissions.nonHQ 
      },
  },
  {
    path: 'customers/create',
    name: 'customers.create',
    component: Form,
    meta: {
        //only Japanese admin can see this page
        restricted: constants.permissions.exceptMainAdmin
      },
  },
  {
    path: 'customers/:id/edit',
    name: 'customers.edit',
    component: Form,
    meta: {
      editPage: true,
      //only Japanese admin can see this page
      restricted: constants.permissions.exceptMainAdmin
    },
  },
];


export default customersRoutes;