<template>
  <InputGroup
    :title="title"
    :title_width="title_width"
    :required="required"
    :optional="optional"
    :class="'mt-' + margin"
  >
    <v-select
      outlined
      dense
      :items="items"
      :multiple="multiple"
      chips
      deletable-chips
      v-model="selectModel"
      :item-value="itemValue"
      :item-text="itemName"
      :no-data-text="$t('general.filter.noItem')"
      @change="$emit('change', $event)"
    ></v-select>
  </InputGroup>
</template>

<script>
import io from 'lodash';
import InputGroup from '@views/_components/form_input/GFilterGroup';

export default {
  components: { InputGroup },
  props: {
    title_width: { required: false },
    title: { required: true },
    value: { required: true },
    items: { required: true, type: Array, default: () => [] },
    option: { default: 'name' },
    multiple: { default: false },
    required: { default: false },
    optional: { default: false },
    disabled: { default: false },
    margin: { required: false, default: 3 },
  },
  computed: {
    selectModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    itemPair() {
      var option = this.option;
      if (!io.isArray(option)) option = [option, option];
      return option;
    },
    itemValue() {
      return this.itemPair[0];
    },
    itemName() {
      return this.itemPair[1];
    },
  },
};
</script>
