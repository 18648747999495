<template>
  <div>
    <!-- {{duplicateCheckInstances}} -->
    <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"> </v-skeleton-loader>
    <v-sheet v-if="!loadingComponent" elevation="1" min-height="70vh" width="100%" :rounded="'sm'">
      <v-container class="px-10">
        <!-- Breadcrumbs -->
        <p class="mx-1 mb-0 text-caption text--secondary">
          <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
          <span class="mx-2">></span>
          <span>{{ editPage ? $t('general.member.edit.pageTitle') : $t('general.member.create.pageTitle') }}</span>
        </p>
        <!-- Page Title -->
        <PageInnerSet :title="editPage ? $t('general.member.edit.pageTitle') : $t('general.member.create.pageTitle')">
          <v-container class="px-10" style="max-width:800px;margin-right: auto;margin-left: 0;">
            <v-form ref="memberForm" @submit.prevent="submit">
              <g-input-group :title="$t('general.member.team')">
                <v-select
                  :rules="rules.team"
                  outlined
                  style="min-width:200px"
                  v-model="formData.teamId"
                  :items="data.team"
                  item-text="name"
                  item-value="id"
                  @change="formData.roleId = null"
                  :disabled="idnAdmin"
                ></v-select>
              </g-input-group>

              <g-input-group :title="$t('general.member.name')">
                <v-text-field
                  :rules="rules.name"
                  outlined
                  v-model="formData.name"
                  @input="duplicateCheckInstances.name.isUnique = true"
                  @blur="duplicateCheck('name')"
                  :disabled="duplicateCheckInstances.name.isChecking"
                ></v-text-field>
                <div v-if="duplicateCheckInstances.name.isChecking">
                  <v-progress-circular class="mb-1" :width="2" :size="20" indeterminate />
                  {{ $t('general.member.message.duplicateChecking') }}
                </div>
                <div v-else-if="!duplicateCheckInstances.name.isUnique">
                  <v-icon class="mb-1 orange--text">mdi-alert</v-icon
                  >{{ $t('general.member.message.nameDuplicateWarning') }}
                </div>
              </g-input-group>

              <!-- Login section-->
              <span class="pa-2 text-h6 text--secondary">{{ $t('general.member.loginInfo') }}</span>
              <hr color="#aaaaaa" style="max-width: 1500px" class="mt-3 mb-6" />

              <g-input-group :title="$t('general.member.username')">
                <v-text-field
                  ref="usernameForm"
                  :rules="rules.username"
                  outlined
                  v-model="formData.username"
                  @input="duplicateCheckInstances.username.isUnique = true"
                  @blur="duplicateCheck('username')"
                  :disabled="duplicateCheckInstances.username.isChecking"
                ></v-text-field>
                <div v-if="duplicateCheckInstances.username.isChecking">
                  <v-progress-circular class="mb-1" :width="2" :size="20" indeterminate />
                  {{ $t('general.member.message.duplicateChecking') }}
                </div>
              </g-input-group>

              <g-input-group :title="$t('general.member.password')" :centered="false">
                <g-password-input
                  :rules="rules.password"
                  outlined
                  v-model="formData.password"
                  v-if="showPasswordForm"
                ></g-password-input>
                <v-btn
                  depressed
                  color="primary"
                  class="mx-2 mb-5"
                  @click="passwordFormDisplay"
                  :disabled="loadingComponent"
                  v-model="passwordButton"
                  v-if="editPage"
                >
                  {{ passwordButtonLabel }}
                </v-btn>
              </g-input-group>
              <hr color="#aaaaaa" style="max-width: 1500px" class="mt-3 mb-7" />

              <g-input-group :title="$t('general.member.role')">
                <v-select
                  :rules="rules.role"
                  outlined
                  style="min-width:200px"
                  v-model="formData.roleId"
                  :items="formData.teamId + -1 != -1 ? data.team[formData.teamId + -1].roles : []"
                  item-text="title"
                  item-value="id"
                ></v-select>
              </g-input-group>

              <g-input-group :title="$t('general.member.permission')">
                <v-select
                  :rules="rules.permission"
                  outlined
                  style="min-width:200px"
                  v-model="formData.permissionId"
                  :items="data.permission"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </g-input-group>

              <g-input-group :title="$t('general.member.status')" classTop="" classBottom="">
                <input type="radio" id="active" value="1" v-model="formData.status" class="mr-2" />
                <label for="active" class="mr-7">Active</label>
                <input type="radio" id="stopped" value="2" v-model="formData.status" class="mr-2" />
                <label for="stopped">Stopped</label>
              </g-input-group>

              <g-input-group title="">
                <v-btn
                  type="submit"
                  color="primary"
                  width="100%"
                  :disabled="duplicateCheckInstances.username.isChecking || duplicateCheckInstances.name.isChecking"
                  >{{ $t('general.member.submit') }}</v-btn
                >
              </g-input-group>
            </v-form>
          </v-container>
        </PageInnerSet>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { getAll, store, update } from '@services/crud';
import GInputGroup from '@components/form_input/GInputGroup.vue';
import GPasswordInput from '@components/form_input/GPasswordInput.vue';
import Vue from 'vue';
import { handleApiError } from '@/plugins/axios';
import PageInnerSet from '@views/_components/page/PageInnerSet';

export default {
  data() {
    return {
      loadingComponent: false,
      idnAdmin: false,
      editPage: false,
      passwordButton: false,
      duplicateCheckInstances: {
        name: {
          isUnique: true,
          checkedValue: null,
          isChecked: true,
          isChecking: false,
        },
        username: {
          isUnique: true,
          checkedValue: null,
          isChecked: true,
          isChecking: false,
        },
      },
      data: {},
      formData: {
        teamId: null,
        name: null,
        username: null,
        password: null,
        status: 1,
        roleId: null,
        permissionId: null,
      },
      rules: {
        team: [v => !!v || this.$t('general.member.message.teamRequired')],
        name: [
          v => !!v || this.$t('general.member.message.nameRequired'),
          v => (v && v.length <= 125) || this.$t('general.member.message.nameMaxCharacters'),
        ],
        username: [
          v => !!this.duplicateCheckInstances.username.isUnique || this.$t('general.member.message.usernameDuplicate'),
          v => !!v || this.$t('general.member.message.usernameRequired'),
          v => (v && v.length >= 4) || this.$t('general.member.message.usernameMinCharacters'),
          v => (v && v.length <= 25) || this.$t('general.member.message.usernameMaxCharacters'),
          v => /^[0-9a-zA-Z.*-_]+$/.test(v) || this.$t('general.member.message.usernameAlphanumeric'),
        ],
        password: [
          v => !!v || this.$t('general.member.message.passwordRequired'),
          v => (v && v.length >= 12) || this.$t('general.member.message.passwordMinCharacters'),
          v => (v && v.length <= 25) || this.$t('general.member.message.passwordMaxCharacters'),
          // v => /^[0-9a-zA-Z!@#$%^&*_+\-*()={}\\|:;"'<>,.?/]+$/.test(v) || this.$t('general.member.message.passwordAlphanumeric'),
          v => /.*[a-z].*/.test(v) || this.$t('general.member.message.passwordLowerCase'),
          v => /.*[A-Z].*/.test(v) || this.$t('general.member.message.passwordUpperCase'),
          v => /.*[0-9]+.*/.test(v) || this.$t('general.member.message.passwordNumber'),
          v =>
            /.*[!@#$%^&*_+\-*()={}\\|:;"'<>,.?/`˜]+.*/.test(v) ||
            this.$t('general.member.message.passwordSpecialCharacter'),
        ],
        role: [v => !!v || this.$t('general.member.message.roleRequired')],
        permission: [v => !!v || this.$t('general.member.message.permissionRequired')],
      },
    };
  },
  computed: {
    showPasswordForm: function() {
      return !this.editPage || (this.editPage && this.passwordButton);
    },
    passwordButtonLabel: function() {
      return this.passwordButton
        ? this.$t('general.member.pressedPasswordButton')
        : this.$t('general.member.defaultPasswordButton');
    },
  },
  methods: {
    passwordFormDisplay() {
      if (this.passwordButton) this.formData.password = null;
      this.passwordButton = !this.passwordButton;
    },
    async submit() {
      this.loadingComponent = true;
      // duplicate check
      const columns = ['name', 'username'];
      columns.forEach(column => {
        this.duplicateCheckInstances[column].isChecked =
          this.duplicateCheckInstances[column].checkedValue == this.formData[column];
      });
      if (this.$refs.memberForm.validate()) {
        let payload = this.formData;
        const res = this.editPage ? await update(this.$route.path, payload) : await store(this.$route.path, payload);
      }
      this.loadingComponent = false;
    },
    async duplicateCheck(column) {
      if (this.duplicateCheckInstances[column].checkedValue == this.formData[column]) return;
      this.duplicateCheckInstances[column].isChecking = true;
      const url = 'members/check';
      let payload = {
        column: column,
        value: this.formData[column],
      };
      if (this.editPage) payload['id'] = this.$route.params.id;
      try {
        const res = await Vue.axios.post(url, payload);
        this.duplicateCheckInstances[column].isUnique = res.data.data;
      } catch (err) {
        throw handleApiError(err, true);
      }
      this.duplicateCheckInstances[column].checkedValue = this.formData[column];
      this.duplicateCheckInstances[column].isChecking = false;
      if (column == 'username') this.$refs.usernameForm.validate();
    },
  },
  async created() {
    this.loadingComponent = true;
    this.idnAdmin = this.$store.state.auth.info.role === 'RESTRICTED_ADMIN';
    if (this.$route.meta.editPage) {
      this.editPage = true;
      const res = await getAll(this.$route.path);
      if (res.item.teamId != 2 && this.idnAdmin) this.$router.push({ name: 'ErrorUnauthorized' });
      this.formData.teamId = res.item.teamId;
      this.formData.name = res.item.name;
      this.formData.username = res.item.username;
      this.formData.password = res.item.password;
      this.formData.status = res.item.status;
      this.formData.roleId = res.item.roleId;
      this.formData.permissionId = res.item.permissionId;
      this.duplicateCheckInstances.name.checkedValue = '' + res.item.name;
      this.duplicateCheckInstances.username.checkedValue = '' + res.item.username;
      this.data = res;
    } else {
      const res = await getAll(this.$route.path);
      this.data = res;
      if (this.idnAdmin) this.formData.teamId = 2;
    }
    this.loadingComponent = false;
  },
  components: {
    GInputGroup,
    GPasswordInput,
    PageInnerSet,
  },
};
</script>
