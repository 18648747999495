<template>
  <div class="sales">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.sales.list.pageTitle') }}</span>
    </p>
    <!--page container -->
    <PageInnerSet :title="$t('general.sales.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <!-- <span class="text-h6 text--secondary">> {{ $t('general.reports.condition') }}</span> <br /> -->
        <!-- <FilterReset @click="resetFilter" class="pb-10"></FilterReset> -->

        <v-row cols="12">
          <v-col cols="8">
            <!-- Term filter -->
            <FilterDateRange
              :title="$t('general.sales.invoiceMonth') + '：'"
              v-model="filterData.date"
              type="month"
              :rules_start="start_date_rule"
              :rules_end="end_date_rule"
              @input="updateAllFilters"
            />
          </v-col>
          <v-col>
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row cols="12">
          <v-col cols="12">
            <GInputGroup name="checkboxFilter" :title="$t('general.sales.status') + '：'" :centered="false">
              <v-row class="py-3">
                <v-col
                  v-for="{ id, name_jp, name_en } in selectBoxData.statuses"
                  :key="id"
                  sm="4"
                  md="3"
                  class="py-0 no-pr"
                >
                  <v-checkbox
                    multiple
                    dense
                    hide-details
                    v-model="filterData.statusId"
                    v-bind:label="getStatusLocale(name_jp, name_en)"
                    :value="id"
                    ref="statusbox"
                    @click="updateAllFilters"
                    class="mb-1 checkbox-style"
                    :style="{
                      'background-color': orderStatusColors.bgColor[name_en],
                      color: orderStatusColors.textColor[name_en],
                    }"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </GInputGroup>
          </v-col>
        </v-row>
      </v-form>

      <!-- <v-row class="pt-5">
        <v-col cols="12">
          <span class="text-h6 text--secondary">> {{ $t('general.reports.result') }}</span>
        </v-col>
      </v-row> -->
      <br />

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="monthlySales"
        :options.sync="options"
        :page.sync="currentPage"
        :server-items-length="totalMonthlySales"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        hide-default-footer
        dense
        @page-count="totalPages = $event"
        fixed-header
        height="700px"
      >
        <template v-slot:[`item.invoice_month`]="{ item }">
          <!-- If japanese local, change the date to MM月YYYY年 format, otherwise leave "as is" -->
          <div>{{ isLocaleJapanese ? monthYearTermJapanHelper(item.invoice_month) : item.invoice_month }}</div>
        </template>

        <template v-slot:[`item.monthly_amount`]="{ item }">
          <div>{{ displayedMoneyHelper(parseInt(item.monthly_amount), isLocaleJapanese) }}</div>
        </template>

        <template v-slot:[`item.monthly_high`]="{ item }">
          <div>
            {{ displayedMoneyHelper(parseInt(item.monthly_high), isLocaleJapanese) }}
          </div>
        </template>

        <template v-slot:[`item.monthly_moderate`]="{ item }">
          <div>
            {{ displayedMoneyHelper(parseInt(item.monthly_moderate), isLocaleJapanese) }}
          </div>
        </template>

        <template v-slot:[`item.monthly_low`]="{ item }">
          <div>
            {{ displayedMoneyHelper(parseInt(item.monthly_low), isLocaleJapanese) }}
          </div>
        </template>

        <!-- totals row at end of each page -->
        <template slot="body.append">
          <tr class="black--text">
            <th class="title">{{ this.$t('general.crud.total') }}</th>
            <!-- below code below 1)converts monthly amount to number,
              2) sums monthlySales together on each page
              3) formats it with getDisplayedMoney -->
            <th class="title" style="text-align:right">
              {{
                displayedMoneyHelper(
                  this.monthlySales.reduce((a, b) => a + (Number(b['monthly_amount']) || 0), 0),
                  isLocaleJapanese
                )
              }}
            </th>
            <th class="title" style="text-align:right">
              {{
                displayedMoneyHelper(
                  this.monthlySales.reduce((a, b) => a + (Number(b['monthly_high']) || 0), 0),
                  isLocaleJapanese
                )
              }}
            </th>
            <th class="title" style="text-align:right">
              {{
                displayedMoneyHelper(
                  this.monthlySales.reduce((a, b) => a + (Number(b['monthly_moderate']) || 0), 0),
                  isLocaleJapanese
                )
              }}
            </th>
            <th class="title" style="text-align:right">
              {{
                displayedMoneyHelper(
                  this.monthlySales.reduce((a, b) => a + (Number(b['monthly_low']) || 0), 0),
                  isLocaleJapanese
                )
              }}
            </th>
          </tr>
        </template>
      </v-data-table>
      <!-- end data table -->

      <!-- Pagination table -->
      <!-- <div class="text-center pt-2 mt-3">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="7"
        ></v-pagination>
      </div> -->
      <!-- end Pagination table -->
    </PageInnerSet>
  </div>
</template>

<script>
/**
 * Note on default value
 * This application sets the default value of 5 (入金確認済)
 */

import { getAll, getSelectBoxData } from '@services/crud';
import { displayedMoneyHelper, monthYearOnlyHelper, monthYearTermJapanHelper } from '@/helpers';
import PageInnerSet from '@views/_components/page/PageInnerSet';
//import FilterContainer from '@views/_components/datatable_filter/TableFilterContainer';
//import DatePicker from 'vue2-datepicker';
//import 'vue2-datepicker/index.css';
//import EnglishDates from 'vue2-datepicker/locale/en';
//import JapaneseDates from 'vue2-datepicker/locale/ja.js';
import GInputGroup from '@views/_components/form_input/GInputGroup.vue';
import FilterDateRange from '@views/_components/datatable_filter/TableFilterDateRange2';

import constants from '@/const/const';

import moment from 'moment';

import _ from 'lodash';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.sales.list.metaTitle'),
    };
  },
  components: {
    GInputGroup,
    FilterDateRange,
    // FilterReset,
    // CheckboxReset,
    //GDatePicker,
    //FilterContainer,
    PageInnerSet,
    // FilterSelect,
  },
  data: function() {
    return {
      start_date_rule: [
        v =>
          this.filterData.date[1] == null ||
          this.filterData.date[0] == null ||
          moment(this.filterData.date[0]).valueOf() <= moment(this.filterData.date[1]).valueOf() ||
          this.$t('general.reports.message.start_date.greaterThanDateEnd'),

        v =>
          moment(this.filterData.date[0]).valueOf() <= moment().valueOf() ||
          this.$t('general.reports.message.start_date.greaterThanToday'),
      ],
      end_date_rule: [
        v =>
          this.filterData.date[0] == null ||
          this.filterData.date[1] == null ||
          moment(this.filterData.date[1]).valueOf() >= moment(this.filterData.date[0]).valueOf() ||
          this.$t('general.reports.message.end_date.lessThanDateStart'),

        v =>
          moment(this.filterData.date[1]).valueOf() <= moment().valueOf() ||
          this.$t('general.reports.message.end_date.greaterThanToday'),
      ],

      //en: EnglishDates,
      //jp: JapaneseDates,
      monthlySales: [],
      totalMonthlySales: 0,
      options: {
        itemsPerPage: 24,
        page: 1,
        multiSort: false,
        sortBy: [],
        sortDesc: [],
      },
      filterData: {
        statusId: [],
        //minMonth: '',
        //maxMonth: '',
        date: [],
      },
      activeFilters: {
        statusId: 5, //set default value on the server side.
      },
      selectBoxData: {
        statuses: [],
      },
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
      rules: {},
      orderStatusColors: constants.orderStatusColors,
    };
  },
  created() {
    //this sets "all" as default value for
    const retrivedFilter = JSON.parse(localStorage.getItem('salesFilter'));
    if (retrivedFilter != null) {
      this.filterData = retrivedFilter;
    } else {
      this.filterData.statusId = [5];
      this.setDefaultDateRange();
    }

    this.updateAllFilters();
    this.updateDataTable();
  },
  mounted: function() {
    this.retrieveSelectBoxData();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (!this.$refs.filter.validate()) return;
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
  },
  computed: {
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.sales.invoiceMonth'),
          value: 'invoice_month',
          // width: 160,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.sales.amount'),
          value: 'monthly_amount',
          // width: 240,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.sales.amountPotentialHigh'),
          value: 'monthly_high',
          // width: 240,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.sales.amountPotentialModerate'),
          value: 'monthly_moderate',
          // width: 240,
          sortable: true,
          divider: true,
          align: 'right',
        },
        {
          text: this.$t('general.sales.amountPotentialLow'),
          value: 'monthly_low',
          // width: 240,
          sortable: true,
          divider: true,
          align: 'right',
        },
      ];
    },
  },
  methods: {
    tooltipItemForStatus: function(object, originObject) {
      console.log(object, originObject);
      console.log(this.isLocaleJapanese);
      if (object.length !== 0) {
        let tooltipText = [];
        object.forEach(element => {
          this.isLocaleJapanese
            ? tooltipText.push(originObject[element - 1]['name_jp'])
            : tooltipText.push(originObject[element - 1]['name_en']);
        });
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    /**getCalendarLocale: function() {
        if (this.isLocaleJapanese()) {
            return this.jp;
        }
        else {
            return this.en;
        }
    },**/
    getStatusLocale: function(name_jp, name_en) {
      if (this.isLocaleJapanese) {
        return name_jp;
      } else {
        return name_en;
      }
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data
     */
    getAllMonthlySales: async function() {
      let url = 'sales';
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.monthlySales = res.sales.data;
        this.monthlySales.forEach(e => {
          e.monthly_high = parseInt(e.monthly_high) + parseInt(e.monthly_amount);
          e.monthly_moderate = parseInt(e.monthly_moderate) + parseInt(e.monthly_amount);
          e.monthly_low = parseInt(e.monthly_low) + parseInt(e.monthly_amount);
        });
        this.totalMonthlySales = res.sales.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /** Set the default value value to date range filter
     *
     */
    setDefaultDateRange: function() {
      moment().locale('ja-JP'); // set Locale

      this.filterData.date = [
        moment()
          .subtract(1, 'M')
          .format('YYYY-MM'),
        moment().format('YYYY-MM'),
      ];
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: _.debounce(function(resetPageNumber = false) {
      this.getAllMonthlySales();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    }, 2000),
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      //this.filterData.minMonth = "";
      //this.filterData.maxMonth = "";
      this.$refs.filter.reset();
      this.filterData.statusId = [];
      this.updateAllFilters();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      if (this.filterData.statusId.length != 0) {
        this.updateFilter('statusId', this.filterData.statusId);
      } else {
        this.updateFilter('statusId', 0);
      }
      this.updateFilter('date', this.filterData.date);
      localStorage.setItem('salesFilter', JSON.stringify(this.filterData));
      this.updateDataTable();
      //this.updateFilter('min_month', this.getAPIMonthString(this.filterData.minMonth));
      //this.updateFilter('max_month', this.getAPIMonthString(this.filterData.maxMonth));
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page for explanation of "if (Array.isArray(val))" (basically it is just in case string values within an array contain commas)
     *
     */
    updateFilter: function(attr, val, date = false) {
      if (val) {
        if (Array.isArray(val)) {
          //if date, we need to remove the "-" before sending to the api.
          if (date == true) {
            val.forEach(function(value, index, thisArray) {
              thisArray[index] = value.replace('-', '');
            });
          }
          val = val.join('||');
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/sales/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        this.selectBoxData.statuses = res.statuses;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    /**
     * Helper Function List
     */
    displayedMoneyHelper,
    monthYearOnlyHelper,
    monthYearTermJapanHelper,

    /**
     * Change date(time) object to string 'yyyy-mm' to send date data to api.
     *
     * @param object(date(time))  - Date to be converted. It can be null.
     * @return String             - 'yyyy-mm'
     */
    /*getAPIMonthString: function(date) {
      if (!date) {
        return date;
      }
      const localeDateString = date.toLocaleDateString('ja-JP'); // Weekday Month dd yyyy hh:mm:ss GMT+xxxx (XXXXX)  => yyyy/(m)m/(d)d
      const year  = localeDateString.split('/')[0]; // yyyy
      const month = localeDateString.split('/')[1]; // (m)m
      return year + '-' + month;       // yyyy-(m)m
    },*/
  },
};
</script>

<style lang="scss">
.sales {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 450px)  !important;
  }
  .v-data-table__wrapper {
    border: 0 !important;
  }
  .v-pagination__navigation {
    display: none;
  }
  .theme--light.v-label {
    color: inherit !important;
  }

  .checkbox-style {
    border-radius: 4px;
    padding: 2px 4px;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color: white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  .v-chip {
    overflow: initial;
    font-size: 11px !important;
  }
}
.v-input__control {
  .v-input__slot {
    padding-bottom: 5px !important;
  }
}

.no-pr {
  padding-right: 0;
}

.filter-4-character {
  @media (min-width: 960px) {
    flex: 0 0 11% !important;
  }
}

.filter-7-character {
  @media (min-width: 960px) {
    flex: 0 0 15% !important;
  }
}
</style>
