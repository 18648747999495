<template>
  <div>
    <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"> </v-skeleton-loader>
    <v-sheet v-if="!loadingComponent" elevation="1" min-height="70vh" width="100%" :rounded="'sm'">
      <v-container class="px-10">
        <!-- Breadcrumbs -->
        <p class="mx-1 mb-0 text-caption text--secondary">
          <router-link :to="{ name: 'dashboard' }">{{$t("general.nav.dashboard")}}</router-link>
          <span class="mx-2">></span>
          <span>{{ editPage ? $t("general.customer.edit.pageTitle") : $t("general.customer.create.pageTitle") }}</span>
        </p>
        <!-- Page container -->
        <PageInnerSet :title="editPage ? $t('general.customer.edit.pageTitle') : $t('general.customer.create.pageTitle')">
          <v-form ref="customerForm" @submit.prevent="submit">

            <g-input-group :title="$t('general.customer.customer')">
              <v-text-field :rules="rules.name" outlined v-model="item.name" @input="duplicateCheckInstances.name.isUnique = true" @blur="duplicateCheck('name')" :disabled="duplicateCheckInstances.name.isChecking"></v-text-field>
              <div v-if="duplicateCheckInstances.name.isChecking">
                <v-progress-circular class="mb-1" :width="2" :size="20" indeterminate/>
                {{ $t('general.member.message.duplicateChecking') }}
              </div>
              <div v-else-if="!duplicateCheckInstances.name.isUnique">
                <v-icon class="mb-1 orange--text">mdi-alert</v-icon>{{$t('general.member.message.nameDuplicateWarning')}}
              </div>
            </g-input-group>
            <!-- <g-input-group required :title="$t('general.customer.customer')">
                <v-text-field
                outlined 
                clearable 
                v-model="item.name" 
                :rules="rules.name"
                ></v-text-field>
            </g-input-group> -->
            <div class="pt-10">
              <v-btn type="submit" :disabled="duplicateCheckInstances.name.isChecking">SUBMIT</v-btn>
            </div>
          </v-form>
        </PageInnerSet>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { store, getForm, update } from '@services/crud';
import GInputGroup from '@components/form_input/GInputGroup.vue';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import Vue from 'vue';
import {
  handleApiError
} from '@/plugins/axios';

export default {
  data() {
    return {
      rules: {
        name: [
          v => !!v || this.$t('general.customer.message.nameRequired'),
          v => !!this.duplicateCheckInstances.name.isUnique || this.$t('general.member.message.nameDuplicateWarning'),
        ],
      },
      item: {
        id: null,
        name: '',
      },
      editPage: false,
      submitUrl: '',
      loadingComponent: false,
      duplicateCheckInstances: {
        name: {
          isUnique: true,
          checkedValue: null,
          isChecked: true,
          isChecking: false,
        },
      },
    };
  },
  methods: {
    async submit() {
      if (this.$refs.customerForm.validate()) {
        let options = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        let payload = new FormData();
        payload.append('name', this.item.name);
        const res = this.editPage
          ? await update(this.submitUrl, payload, options)
          : await store(this.submitUrl, payload, options);
        if (res) {
          this.$router.push({ name: 'customers' });
        }
      }
    },
    async duplicateCheck(column) {
      if (this.duplicateCheckInstances[column].checkedValue == this.item[column]) return;
      this.duplicateCheckInstances[column].isChecking = true;
      const url = "customers-check";
      let payload = {
        column: column,
        value: this.item[column],
      };
      if(this.editPage) payload['id'] = this.$route.params.id;
      console.log(payload);
      try {
        const res = await Vue.axios.post(url, payload);
        this.duplicateCheckInstances[column].isUnique = res.data.data;
        console.log(res);
      } catch (err) {
        throw handleApiError(err, true);
      }
      this.duplicateCheckInstances[column].checkedValue = this.item[column];
      this.duplicateCheckInstances[column].isChecking = false;
      if(column == "name") this.$refs.customerForm.validate();
    },
  },
  async created() {
    this.loadingComponent = true;
    //fetches the form data, submitURL and this.item(edit only) from the api
    const form = await getForm(this.$route.path);
    //edit(see router/customer.js file)
    if (this.$route.meta.editPage) {
      this.editPage = true;
      let { submitUrl, item } = form;
      this.item = {
        ...this.item,
        name: item.name,
      };
      this.submitUrl = submitUrl;
    } //create
    else {
      let {  submitUrl } = form;
      this.submitUrl = submitUrl;
    }
    this.loadingComponent = false;
  },
  components: {
    GInputGroup,
    PageInnerSet
  },
};
</script>