<template>
  <v-btn dark color="red lighten-2" small @click.stop="resetFilter" class="reset-button mx-1">
    <slot>{{ $t('general.crud.reset') }}</slot>
  </v-btn>
</template>

<script>
  export default {
    methods: {
      resetFilter() {
        this.$emit('resetFilter')
      }
    }
  }
</script>