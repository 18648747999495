<template>
<div>
  <v-skeleton-loader v-if="loadingComponent" type="card-avatar, article, actions"> </v-skeleton-loader>
  <v-sheet v-if="!loadingComponent" elevation="1" min-height="70vh" width="100%" :rounded="'sm'">
    <v-container>
      <!-- Breadcrumbs -->
      <p class="text-caption text--secondary">
        <router-link :to="{ name: 'dashboard' }">{{$t("general.nav.dashboard")}}</router-link>
        <span class="mx-2">></span>
        <span>{{$t("general.unit_price.pageTitle")}}</span>
      </p>
      <!-- Page Title -->
      <PageInnerSet :title="$t('general.unit_price.pageTitle')">
        <v-container>
          <v-row no-gutters>
            <v-col cols="4"><span class="pa-2 text-h6 text--secondary">{{$t("general.unit_price.team")}}</span></v-col>
            <v-col cols="4"><span class="pa-2 text-h6 text--secondary">{{$t("general.unit_price.role")}}</span></v-col>
            <v-col cols="4" style="text-align:right"><span class="pa-2 text-h6 text--secondary" >{{$t("general.unit_price.price")}}</span></v-col>
          </v-row>
        </v-container>

        <!-- Horizontal Line -->
        <hr color="#cccccc">
        <!-- Table Body -->
        <v-container>
          <div v-for="item in editPage ? formData : data" :key="item.id">
            <hr v-if="item.id > 1" class="my-5">
            <div v-for="(detail, index) in item.roles" :key="detail.id">
              <v-row>
                <v-col cols="4">
                  <span class="pa-2 text-h6 text--secondary">
                    {{index == 0 ? item.name : ""}}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="pa-2 text--secondary">
                    {{detail.title}}
                  </span>
                </v-col>
                <v-col cols="4" style="text-align:right">
                  <span v-if="!editPage" class="pa-2 text--secondary">
                    {{detail.unit_cost.toLocaleString()}}
                  </span>
                  <div v-else style="max-height:60px">
                    <v-currency-field locale="ja" :value-as-integer=true :decimal-length="0" :allowNegative="false" v-model.number="detail.unit_cost" outlined />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <!-- Edit Button -->
          <v-row v-if="isMainAdmin()">
            <v-col v-if="!editPage" cols="12" style="text-align:right">
              <v-btn color="primary" width="150px" class="my-5" @click="edit">
                {{$t("general.unit_price.edit")}}
              </v-btn>
            </v-col>
            <v-col v-else cols="12" style="text-align:center">
              <v-btn outlined color="#444444" width="150px" class="my-5 mx-1" style="border-width: 1px;" @click="closeEdit">
                {{$t("general.unit_price.cancel")}}
              </v-btn>
              <v-btn color="primary" width="150px" class="my-5 mx-1" @click="updateUnitPrices">
                {{$t("general.unit_price.save")}}
              </v-btn>
            </v-col>
          </v-row>

        </v-container>
      </PageInnerSet>
    </v-container>
  </v-sheet>
</div>
</template>

<script>
import {
  mapState
} from "vuex";
import {
  getAll, update
} from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';

export default {
  data() {
    return {
      data: [],
      formData: [],
      forms: [],
      loadingComponent: false,
      editPage: false,
    };
  },
  computed: {
    ...mapState({
      authInfo: store =>
        store.auth.info,
    }),
  },
  methods: {
    isMainAdmin() {
      return this.$store.state.auth.info.role === this.$store.state.global.constants.permissions.adminString;
    },
    getAllUnitPrices: async function () {
      try {
        this.loadingComponent = true;
        const res = await getAll(this.$route.path);
        this.data = res;
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComponent = false;
      }
    },
    updateUnitPrices: async function () {
      if(!this.isMainAdmin()) return;
      try{
        this.loadingComponent = true;
        const res = await update(this.$route.path,this.formData);
        console.log(res);
        if (res) {
          this.closeEdit();
          await this.getAllUnitPrices();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComponent = false;
      }
    },
    edit: function() {
      if(this.editPage || !this.isMainAdmin()) return;
      else this.editPage = true;
      this.formData = [];
      this.data.forEach((e) => this.formData.push(JSON.parse(JSON.stringify(e))));
    },
    closeEdit: function() {
      if(!this.editPage) return;
      else this.editPage = false;
      this.formData = [];
      this.data.forEach((e) => this.formData.push(JSON.parse(JSON.stringify(e))));
    }
  },
  created() {
    this.getAllUnitPrices();
    // this.updateUnitPrices();
  },
  components: {
    PageInnerSet
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>