<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    max-width="350px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="valueModel"
        v-bind="{ ...attrs, ...tfAttrs }"
        v-on="on"
        outlined
        dense
        append-icon="mdi-calendar"
        readonly
        clearable
        :label="label"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="valueModel"
      v-bind="dpAttrs"
      @input="isOpen = false"
      @change="$emit('change', $event)"
      :type="type"
      :disabled="disabled"
      :locale="locale"
      :color="color"
      :header-color="headerColor"
      :show-current="showCurrent"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {type: String},
    label: {type: String, default: ''},
    rules: Array,
    disabled: { default: false },
    type: { type: String, default: 'date' },
    color: {type: String, default: 'primary'},
    headerColor: {type: String, default: 'primary'},
    showCurrent: {type: [Boolean, String], default: true}
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    valueModel: {
      get(){return this.value},
      set( value ){this.$emit('input', value)}
    },
    dpAttrs() {
      return Object.entries(this.$attrs).reduce((acc, [key, val]) => {
        if (key.substring(0, 2) == 'dp') {
          return { [key.substring(3, key.length)]: val, ...acc };
        } else {
          return acc;
        }
      }, {});
    },
    tfAttrs() {
      return Object.entries(this.$attrs).reduce((acc, [key, val]) => {
        if (key.substring(0, 2) == 'tf') {
          return { [key.substring(3, key.length)]: val, ...acc };
        } else {
          return acc;
        }
      }, {});
    },
    locale() {
      return this.$i18n.locale;
    }
  },
};
</script>
