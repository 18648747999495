<template>
  <InputGroup :title="title" :title_width="title_width" :required="required" :optional="optional" :class="'mt-' + margin">
    <v-combobox outlined dense :items="items" v-model="selectModel" multiple chips deletable-chips></v-combobox>
  </InputGroup>
</template>

<script>
/**
 * This accepts a pure array as prop "items" for now.
 * Note: Pure array is just an array consisting of number or string, and not object or array
 * 
 * This gives you selected items separated by comma.
 */
import InputGroup from '@views/_components/form_input/GFilterGroup';
export default {
  components: { InputGroup },
  props: {
    title_width: {required: false},
    title: { required: true },
    items: { required: true, type: Array, default: () => [] },
    required: { default: false },
    optional: { default: false },
    disabled: { default: false },    
    margin: { required: false, default: 3 }
  },
  computed: {
    selectModel: {
      get(){ return this.value },
      set( value ){ this.$emit( 'input', value )}
    },
  }
};
</script>