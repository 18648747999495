<template>
  <v-col :cols=col_cols>
    <FilterRange :title="$t('general.reports.date') + '：'" :title_width="2">
      <template v-slot:min>
        <GDatePickerVue v-model="activeFilters.start_date" :rules="rules.start_date" :type="DateType"></GDatePickerVue>
      </template>
      <template v-slot:max>
        <GDatePickerVue v-model="activeFilters.end_date" :rules="rules.end_date" :type="DateType"></GDatePickerVue>
      </template>
    </FilterRange>
  </v-col>
</template>

<script>
  import FilterRange from '@views/_components/molecules/datatable_filter/TableFilterSlotRange';
  import GDatePickerVue from '@views/_components/molecules/form_input/GDatePicker.vue';

  export default {
    props: {
      activeFilters: Object,
      rules: Object,
      row_cols: String,
      col_cols: Number,
      DateType: String,
    },
    components: {
      FilterRange,
      GDatePickerVue,
    },
  }
</script>
