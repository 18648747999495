<template>
  <div class="invoice">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.invoice.list.pageTitle') }}</span>
    </p>
    <!--page container -->
    <PageInnerSet :title="$t('general.invoice.list.pageTitle')">
      <!--Column Filter-->
      <v-row class="mb-1">
        <v-col cols="6"> </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <EditModeButton @editModeMethod="editModeMethod"></EditModeButton>
          <ResetButton @resetFilter="resetFilter"></ResetButton>
          <ColumnFilterModal :title="this.$t('general.crud.filterColumn')"
            :openButton="this.$t('general.crud.filterColumn')">
            <v-row>
              <v-col cols="3" v-for="item in headersForSelectBox" :key="item.value">
                <v-checkbox v-model="visibleColumns" :label="item.text" :value="item.value" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </ColumnFilterModal>
        </v-col>
      </v-row>
      <!--Column Filter-->
      <!-- data table -->
      <v-row>
        <v-col>
          <v-data-table :headers="headers" :items="indexInvoice" :item-class="itemRowBackground"
            :loading="isLoadingTableData" :loading-text="$t('general.crud.loading')" :options.sync="options"
            :page.sync="currentPage" :server-items-length="totalInvoices" @page-count="totalPages = $event" dense
            fixed-header height="700px" hide-default-footer>
            <template v-slot:[`body.prepend`]>
              <tr>
                <td v-show="isEnabledColumn('project.project_type.name_jp')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.projectTypes" :item-value="'id'"
                          :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'" multiple chips deletable-chips
                          style="width: 180px" v-bind="attrs" v-model="filterData.projectTypeId"
                          @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.projectTypeId ? tooltipItem(filterData.projectTypeId) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('project.name')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.projectNames" :item-value="'id'" :item-text="'name'"
                          multiple chips deletable-chips style="width: 200px" v-bind="attrs"
                          v-model="filterData.projectName" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.projectName ? tooltipItem(filterData.projectName) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('name_jp')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.orderNames" :item-value="'id'" :item-text="'name_jp'"
                          multiple chips deletable-chips v-bind="attrs" style="width: 200px"
                          v-model="filterData.orderNameJp" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.orderNameJp ? tooltipItem(filterData.orderNameJp) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('project.customer.name')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.customerNames" :item-value="'id'" :item-text="'name'"
                          multiple chips deletable-chips v-bind="attrs" style="width: 200px"
                          v-model="filterData.customerName" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.customerName ? tooltipItem(filterData.customerName) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('member.name')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.projectManagers" :item-value="'id'" :item-text="'name'"
                          multiple chips deletable-chips v-bind="attrs" style="width: 140px" v-model="filterData.pmId"
                          @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{ filterData.pmId ? tooltipItem(filterData.pmId) : $t('general.tooltip.noSelect') }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('order_status')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.statuses" :item-value="'id'"
                          :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'" multiple chips deletable-chips
                          v-bind="attrs" style="width: 140px" v-model="filterData.statusId" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.statusId ? tooltipItem(filterData.statusId) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('invoice_amount')" class="v-data-table__divider">
                  <v-text-field 
                    v-model.number="filterData.min_payment_amount" 
                    dense
                    :placeholder="$t('general.crud.minPlaceholder')"
                    type="number"
                    min="0"
                    step="500000"
                    @input="updateAllFilters" 
                    hide-details="off" 
                    style="font-size: 12px"
                    :rules="
                      filterData.max_payment_amount && filterData.min_payment_amount
                        ? rules.paymentCountMin.concat(rules.positiveInteger)
                        : []
                    " class="mb-3"></v-text-field>
                  <v-text-field 
                    v-model.number="filterData.max_payment_amount" dense
                    :placeholder="$t('general.crud.maxPlaceholder')"
                    type="number"
                    min="0"
                    step="500000"
                    hide-details="off"
                    @input="updateAllFilters"
                    style="font-size: 12px"
                    :rules="
                      filterData.min_payment_amount && filterData.max_payment_amount
                        ? rules.paymentCountMax.concat(rules.positiveInteger)
                        : []
                    " class="mb-3"></v-text-field>
                </td>
                <td v-show="isEnabledColumn('invoice_information.invoice_amount_with_tax')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('acceptance_date')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('invoice_date')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.invoiceDates" :item-value="'id'" :item-text="'name'"
                          multiple chips deletable-chips v-bind="attrs" style="width: 110px"
                          v-model="filterData.invoiceDate" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.invoiceDate ? tooltipItem(filterData.invoiceDate) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('payment_deadline')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.paymentDeadlines" :item-value="'id'" :item-text="'name'"
                          multiple chips deletable-chips v-bind="attrs" style="width: 110px"
                          v-model="filterData.paymentDeadline" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.paymentDeadline
                        ? tooltipItem(filterData.paymentDeadline)
                        : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('payment_date')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('purchase_order_url')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('invoice_url')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('issuer')" class="v-data-table__divider">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div multiple v-on="on">
                        <v-combobox dense :items="selectBoxData.issuer" :item-value="'id'"
                          :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'" multiple chips deletable-chips
                          v-bind="attrs" style="width: 90px" v-model="filterData.issuer" @input="updateAllFilters" />
                      </div>
                    </template>
                    <span>{{
                      filterData.issuer ? tooltipItem(filterData.issuer) : $t('general.tooltip.noSelect')
                    }}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('updated_at')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('created_at')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('action')" class="v-data-table__divider fixed"></td>
              </tr>
            </template>

            <template v-slot:[`header.invoice_amount`]="{ header }">
              {{ header.text }}
              <hr />
              {{ totalInvoicesAmount.toLocaleString() }}
            </template>

            <template v-slot:[`header.invoice_information.invoice_amount_with_tax`]="{ header }">
              {{ header.text }}
              <hr />
              {{ totalInvoicesAmountWithTax.toLocaleString() }}
            </template>

            <template v-slot:[`item.project.project_type.name_jp`]="{ item }">
              <div style="width: 180px; max-width: 200px">
                {{ isLocaleJapanese? item.project.project_type.name_jp : item.project.project_type.name_en }}
              </div>
            </template>

            <template v-slot:[`item.project.name`]="{ item }">
              <v-autocomplete dense outlined hide-details="auto" :items="selectBoxData.projectNames" :item-value="'id'"
                :item-text="'name'" v-model="item.project_id" v-if="editMode" class="font-size-11"
                @input="editModeSumbit(item, 'project_id')"></v-autocomplete>
              <div style="max-width: 200px" v-else>{{ item.project.name }}</div>
            </template>

            <template v-slot:[`item.name_jp`]="{ item }">
              <template v-if="isLocaleJapanese">
                <v-text-field v-if="editMode" :ref="`name_jp_` + item.id" v-model="item.name_jp" dense outlined
                  class="font-size-11" @focus="handleErrorItem(item.id)"
                  :rules="isLocaleJapanese ? rules.orderNameJpRule : rules.orderNameEnRule"
                  :hide-details="hideDetail[item.id] ? 'auto' : 'off'"
                  @change="editModeSumbit(item, 'name_jp', 'name_jp')"></v-text-field>
                <div style="max-width: 200px" v-else>
                  {{
                    item.type == 1
                      ? item.name_jp + ' ' + (item.invoice_date ? monthYearEnglishHelper(item.invoice_date) : '')
                      : item.name_jp
                  }}
                </div>
              </template>
              <template v-else>
                <v-text-field v-if="editMode" :ref="`name_en_` + item.id" v-model="item.name_en" dense outlined
                  class="font-size-11" @focus="handleErrorItem(item.id)"
                  :rules="isLocaleJapanese ? rules.orderNameJpRule : rules.orderNameEnRule"
                  :hide-details="hideDetail[item.id] ? 'auto' : 'off'"
                  @change="editModeSumbit(item, 'name_en', 'name_en')"></v-text-field>
                <div style="max-width: 200px" v-else>
                  {{
                    item.type == 1
                      ? item.name_en + ' ' + (item.invoice_date ? monthYearEnglishHelper(item.invoice_date) : '')
                      : item.name_en
                  }}
                </div>
              </template>
            </template>

            <template v-slot:[`item.member.name`]="{ item }">
              <v-autocomplete dense outlined hide-details="auto" item-value="id" :items="selectBoxData.projectManagers"
                :item-text="'name'" v-model="item.pm_id" v-if="editMode" class="font-size-11"
                @input="editModeSumbit(item, 'pm_id')"></v-autocomplete>
              <div style="max-width: 200px" v-else>{{ item.member.name }}</div>
            </template>

            <template v-slot:[`item.order_status`]="{ item }">
              <v-autocomplete dense outlined hide-details="auto" item-value="id" :items="selectBoxData.statuses"
                :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'" v-model="item.order_status_id" v-if="editMode"
                @input="editModeSumbit(item, 'order_status_id')" class="font-size-11"></v-autocomplete>
              <v-chip :color="orderStatusColors.bgColor[item.order_status.name_en]"
                :text-color="orderStatusColors.textColor[item.order_status.name_en]" label x-small v-else>
                {{ isLocaleJapanese? item.order_status.name_jp : item.order_status.name_en }}
              </v-chip>
            </template>

            <template v-slot:[`item.invoice_amount`]="{ item }">
              <v-text-field v-model.number="item.invoice_amount" dense outlined :ref="`invoice_amount_` + item.id"
                type="number" class="font-size-11" :rules="rules.invoiceAmountRule" v-if="editMode"
                @focus="handleErrorItem(item.id)" :hide-details="hideDetail[item.id] ? 'auto' : 'off'"
                @change="editModeSumbit(item, 'invoice_amount', invoices[item.index].invoice_information.id)"></v-text-field>
              <div v-else>{{ item.invoice_amount.toLocaleString() }}</div>
              <!--  -->
            </template>

            <template v-slot:[`item.invoice_information.invoice_amount_with_tax`]="{ item }">
              {{ item.invoice_information.invoice_amount_with_tax ? item.invoice_information.invoice_amount_with_tax.toLocaleString() : null }}
            </template>

            <template v-slot:[`item.acceptance_date`]="{ item }">
              <DatePicker type="date" format="YYYY-MM-DD" value-type="YYYY-MM-DD" v-model="item.acceptance_date"
                v-if="editMode" @input="editModeSumbit(item, 'acceptance_date')" />
              <div v-else>{{ formatDateHelper(item.acceptance_date) }}</div>
            </template>

            <template v-slot:[`item.invoice_date`]="{ item }">
              <DatePicker type="date" format="YYYY-MM-DD" value-type="YYYY-MM-DD" v-model="item.invoice_date"
                v-bind:clearable="false" v-if="editMode"
                @input="editModeSumbit(item, 'invoice_date', invoices[item.index].invoice_information.id)" />
              <div v-else>{{ formatDateHelper(item.invoice_date) }}</div>
            </template>

            <template v-slot:[`item.payment_deadline`]="{ item }">
              <DatePicker type="date" format="YYYY-MM-DD" value-type="YYYY-MM-DD" v-model="item.payment_deadline"
                v-bind:clearable="false" v-if="editMode"
                @input="editModeSumbit(item, 'payment_deadline', invoices[item.index].invoice_information.id)" />
              <div v-else>{{ formatDateHelper(item.payment_deadline) }}</div>
            </template>

            <template v-slot:[`item.payment_date`]="{ item }">
              <DatePicker type="date" format="YYYY-MM-DD" value-type="YYYY-MM-DD" v-model="item.payment_date"
                v-if="editMode"
                @input="editModeSumbit(item, 'payment_date', invoices[item.index].invoice_information.id)" />
              <div v-else>{{ formatDateHelper(item.payment_date) }}</div>
            </template>

            <template v-slot:[`item.purchase_order_url`]="{ item }">
              <v-text-field v-if="editMode" v-model="item.purchase_order_url" dense outlined class="font-size-11"
                hide-details="off"
                @change="editModeSumbit(item, 'purchase_order_url', invoices[item.index].invoice_information.id)">
              </v-text-field>
              <div v-else>
                <a v-if="item.purchase_order_url" :href="item.purchase_order_url">
                  <i class="far fa-file-pdf fa-lg"></i>
                </a>
              </div>
            </template>

            <template v-slot:[`item.invoice_url`]="{ item }">
              <v-text-field v-if="editMode" v-model="item.invoice_url" dense outlined class="font-size-11"
                hide-details="off"
                @change="editModeSumbit(item, 'invoice_url', invoices[item.index].invoice_information.id)">
              </v-text-field>
              <div v-else>
                <a v-if="item.invoice_url" :href="item.invoice_url">
                  <i class="far fa-file-pdf fa-lg"></i>
                </a>
              </div>
            </template>

            <template v-slot:[`item.issuer`]="{ item }">
              <v-autocomplete dense outlined hide-details="auto" item-value="id" :items="selectBoxData.issuer"
                :item-text="isLocaleJapanese ? 'name_jp' : 'name_en'" v-model="item.issuer" v-if="editMode"
                class="font-size-11" @input="editModeSumbit(item, 'issuer')"></v-autocomplete>
              <span v-if="item.issuer && !editMode">{{ $t('general.order.officeWork') }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <div>{{ item.created_at }}</div>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
              <div>{{ item.updated_at }}</div>
            </template>

            <template v-if="isHQ()" v-slot:[`item.action`]="{ item }">
              <div class="button-row">
                <v-btn :disabled="isLoadingTableData"
                  :to="{ name: 'orders.edit', params: { id: item.id }, query: filterData }" color="primary"
                  class="white--text" x-small>
                  <v-icon x-small class="py-4">mdi-pencil</v-icon>                  
                  <!-- {{ $t('general.crud.btn.edit') }} -->
                </v-btn>
                <v-btn :disabled="isLoadingTableData"
                  :to="{ name: 'orders.create', params: { id: item.id, duplicate: true }, query: filterData }"
                  color="primary" class="white--text" x-small>
                  <v-icon x-small class="py-4 pr-1">mdi-content-copy</v-icon>
                  {{ $t('general.crud.btn.duplicate') }}
                </v-btn>
                <!-- <v-btn :disabled="isLoadingTableData" @click="checkSubmit(item.id)" color="red accent-2" class="white--text" x-small> -->
                  <!-- <v-icon small class="py-4">mdi-delete</v-icon> -->
                  <!-- {{ $t('general.crud.btn.delete') }} -->
                <!-- </v-btn> -->
              </div>

              <v-dialog v-model="dialog[item.id]" max-width="500" :key="item.id">
                <v-card>
                  <p class="px-6 py-6 text-center">
                    {{ dialogMessage }}
                  </p>
                  <v-card-actions class="justify-center">
                    <v-btn dark class="white--text" @click="$set(dialog, item.id, false)">
                      {{ $t('general.validation.cancel') }}
                    </v-btn>
                    <v-btn v-if="!cancel" color="primary darken-1" class="white--text" :loading="loadingConfirm"
                      @click="deleteSubmit(item.id)">
                      {{ $t('general.validation.confirm') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- <div class="text-center pt-2 mt-3">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="7"
      ></v-pagination>
    </div> -->
    </PageInnerSet>
    <v-overlay v-if="loadingEditMode" value="Loading...">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
/**
 *
 */
import Vue from 'vue';
import { getAll, getSelectBoxData, destroy, update } from '@services/crud';
import { formatDateHelper, displayedMoneyHelper, monthYearOnlyHelper, monthYearEnglishHelper } from '@/helpers';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';

import ColumnFilterModal from '@views/_components/ColumnFilterModal';

import constants from '@/const/const';
import colors from '../../assignment-v2/colors';

import ResetButton from '@views/_components/organisms/button/ResetButton.vue'
import EditModeButton from '@views/_components/organisms/button/EditModeButton.vue';

import moment from 'moment';

export default {
  metaInfo: function () {
    return {
      title: this.$t('general.invoice.list.metaTitle'),
    };
  },
  components: {
    PageInnerSet,
    EditModeButton,
    ResetButton,
    ColumnFilterModal,
    DatePicker,
  },
  data: function () {
    return {
      invoices: [],
      totalInvoices: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        multiSort: true,
        sortBy: ['updated_at'],
        sortDesc: ['true'],
      },
      visibleColumns: [],
      filterData: {
        projectTypeId: this.$route.query.projectTypeId ? this.$route.query.projectTypeId : null,
        projectName: this.$route.query.projectName ? this.$route.query.projectName : '',
        customerName: this.$route.query.customerName ? this.$route.query.customerName : '',
        orderNameJp: this.$route.query.orderNameJp ? this.$route.query.orderNameJp : '',
        pmId: this.$route.query.pmId ? this.$route.query.pmId : null,
        statusId: this.$route.query.statusId ? this.$route.query.statusId : null,
        invoiceDate: this.$route.query.invoiceDate ? this.$route.query.invoiceDate : '',
        paymentDeadline: this.$route.query.paymentDeadline ? this.$route.query.paymentDeadline : '',
        min_payment_amount: this.$route.query.min_payment_amount ? this.$route.query.min_payment_amount : null,
        max_payment_amount: this.$route.query.max_payment_amount ? this.$route.query.max_payment_amount : null,
      },
      activeFilters: {},
      selectBoxData: {
        projectTypes: [],
        projectNames: [],
        customerNames: [],
        orderNames: [],
        projectManagers: [],
        statuses: [],
        invoiceDates: [],
        paymentDeadlines: [],
      },
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
      rules: {
        paymentCountMax: [
          v =>
            (parseInt(v) >= parseInt(this.filterData.min_payment_amount) && v !== null) ||
            this.$t('general.validation.lessThan'),
        ],
        paymentCountMin: [
          v =>
            (parseInt(v) <= parseInt(this.filterData.max_payment_amount) && v !== null) ||
            this.$t('general.validation.greaterThan'),
        ],
        positiveInteger: [v => v >= 0 || this.$t('general.validation.positiveInteger')],

        orderNameJpRule: [
          v => !!v || this.$t('general.order.message.jpRequired'),
          v => (v && v.length >= 3) || this.$t('general.order.message.jpMinCharacters'),
        ],
        orderNameEnRule: [v => (v && v.length >= 8) || this.$t('general.order.message.enMinCharacters')],
        invoiceAmountRule: [v => /^[0-9,]+$/.test(v) || this.$t('general.order.message.invoiceAmountMinCharacters')],
      },
      orderStatusColors: constants.orderStatusColors,
      dialog: {},
      cancel: false,
      dialogMessage: '',
      loadingConfirm: false,
      editMode: false,
      loadingEditMode: false,
      hideDetail: {},
    };
  },
  created() {
    // remove local storage
    localStorage.removeItem('invoices');
    //this sets "all" as default value for
    // this.filterData.projectTypeId = [0];
    // this.filterData.pmId = [0];
    // this.filterData.statusId = [0];
    this.visibleColumns = this.allHeaders.map((_element, index) => index);
  },
  mounted: function () {
    // trigger keyup for edit mode
    document.addEventListener('keyup', this.keyupHandler);

    this.updateAllFilters();
    this.getAllInvoices();
    this.retrieveSelectBoxData();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        // ---------------------
        // UPDATE 8 MARCH 2022
        // ---------------------
        // this.timer = setTimeout(() => {
        // this.$router.push({ name: 'management.invoices', query: this.filterData }).catch(err => {});
        this.updateDataTable(true);
        // }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    isLocaleJapanese: function () {
      return this.$store.state.global.locale === 'ja';
    },
    headersForSelectBox: function () {
      return this.allHeaders.map(function (element, index) {
        return { value: index, text: element.text };
      });
    },
    headers: function () {
      return this.allHeaders.filter(header => this.visibleColumns.includes(this.allHeaders.indexOf(header)));
    },
    allHeaders: function () {
      return [
        {
          text: this.$t('general.project.type'),
          value: 'project.project_type.name_jp',
          width: this.editMode ? '15rem' : '7rem',
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.project.name'),
          value: 'project.name',
          width: this.editMode ? '15rem' : 200,
          sortable: false,
          divider: true,
        },
        {
          text: this.$t('general.invoice.name'),
          value: 'name_jp',
          width: this.editMode ? '15rem' : 200,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.project.customer'),
          value: 'project.customer.name',
          width: this.editMode ? '15rem' : 200,
          sortable: false,
          divider: true,
        },
        {
          text: this.$t('general.invoice.pm'),
          value: 'member.name',
          width: this.editMode ? '15rem' : 140,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.status'),
          value: 'order_status',
          width: this.editMode ? '15rem' : '',
          sortable: false,
          divider: true,
        },
        {
          text: this.$t('general.invoice.amount'),
          value: 'invoice_amount',
          width: this.editMode ? '15rem' : 140,
          align: 'right',
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.amountTax'),
          value: 'invoice_information.invoice_amount_with_tax',
          width: this.editMode ? '15rem' : 140,
          align: 'right',
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.acceptanceDate'),
          value: 'acceptance_date',
          width: this.editMode ? '15rem' : 110,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.invoiceDate'),
          value: 'invoice_date',
          width: this.editMode ? '15rem' : 110,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.paymentDeadline'),
          value: 'payment_deadline',
          width: this.editMode ? '15rem' : 110,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.paymentDate'),
          value: 'payment_date',
          width: this.editMode ? '15rem' : 110,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.invoice.purchaseOrderUrl'),
          value: 'purchase_order_url',
          width: this.editMode ? '15rem' : '',
          sortable: false,
          divider: true,
        },
        {
          text: this.$t('general.invoice.invoiceUrl'),
          value: 'invoice_url',
          width: this.editMode ? '15rem' : '',
          sortable: false,
          divider: true,
        },
        {
          text: this.$t('general.order.issuer'),
          value: 'issuer',
          width: this.editMode ? '15rem' : 90,
          divider: true,
          align: 'center',
        },
        {
          text: this.$t('general.crud.createdAt'),
          value: 'created_at',
          width: 150,
          divider: true,
          align: 'center',
        },
        {
          text: this.$t('general.crud.updatedAt'),
          value: 'updated_at',
          width: 150,
          divider: true,
          align: 'center',
        },
        {
          text: this.$t('general.crud.action'),
          width: 'auto',
          value: 'action',
          sortable: false,
          divider: true,
          align: 'center fixed',
          class: 'fixed',
        },
      ];
    },
    totalInvoicesAmount: function () {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.invoice_amount;
      });
      return total;
    },
    totalInvoicesAmountWithTax: function () {
      let total = 0;
      this.invoices.forEach(element => {
        total = total + element.invoice_information.invoice_amount_with_tax;
      });

      return total;
    },
    indexInvoice() {
      var invoices = this.invoices.map((invoice, index) => ({
        ...invoice,
        index: index,
      }));
      return invoices;
    },
  },
  methods: {
    tooltipItem: function (object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    itemRowBackground: function (item) {
      if (item.is_active == 0) {
        return 'grey';
      } else {
        return false;
      }
    },
    isHQ: function () {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data
     */
    getAllInvoices: async function () {
      let url = 'invoices';
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.invoices = res.invoices.data;
        this.totalInvoices = res.invoices.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function (resetPageNumber = false) {
      this.getAllInvoices();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function () {
      // this.$refs.filter.reset();
      this.filterData = {
        projectTypeId: null,
        projectName: '',
        customerName: '',
        orderNameJp: '',
        pmId: null,
        statusId: null,
        invoiceDate: '',
        paymentDeadline: '',
        min_payment_amount: null,
        max_payment_amount: null,
      };
      this.updateAllFilters();
      // this.updateDataTable(true);
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function () {
      this.updateFilter('projectTypeId', this.filterData.projectTypeId);
      this.updateFilter('projectName', this.filterData.projectName);
      this.updateFilter('orderNameJp', this.filterData.orderNameJp);
      this.updateFilter('customerName', this.filterData.customerName);
      this.updateFilter('pmId', this.filterData.pmId);
      this.updateFilter('statusId', this.filterData.statusId);
      this.updateFilter('invoiceDate', this.filterData.invoiceDate);
      this.updateFilter('paymentDeadline', this.filterData.paymentDeadline);

      this.updateFilter('min_payment_amount', this.filterData.min_payment_amount);
      this.updateFilter('max_payment_amount', this.filterData.max_payment_amount);
      this.updateFilter('issuer', this.filterData.issuer);
      //for debugging the filters
      // console.log('this.activeFilters = ' + JSON.stringify(this.activeFilters));
    },
    /**
     * transform each input date.
     * if each Date "YYYY-M" transform "YYYY-0M" add string "0"
     */
    transDates: function (inputDate) {
      if (inputDate) {
        let checkDate = inputDate;
        let year = checkDate.substr(0, checkDate.indexOf('-'));
        let month = checkDate.substr(checkDate.indexOf('-') + 1);
        if (month.length == 1) {
          const formatedDate = year + '-' + month.padStart(2, '0');
          return formatedDate;
        } else {
          return inputDate;
        }
      }
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page for explanation of "if (Array.isArray(val))" (basically it is just in case string values within an array contain commas)
     */
    updateFilter: function (attr, val, date = false) {
      /*
      attr :str :filter title
      val :list :select filter item
      value :list :select and selected filter items
      -- problem
      - issuer has "" item so remove all selected filter items but this->activefilter has "" So it recognise "" as selected item
      - this is to long code So, unify to value.push(element.id) ideally now I can value.push(element.id) not use if to use else
       */
      if (val) {
        if (Array.isArray(val)) {
          //if date, we need to remove the "-" before sending to the api
          if (date == true) {
            val.forEach(function (value, index, thisArray) {
              thisArray[index] = value.replace('-', '');
            });
          }
          let value = [];
          if (attr == 'projectTypeId') {
            val.forEach(element => {
              value.push(element.id);
            });
          } else if (attr == 'projectName') {
            val.forEach(element => {
              value.push(element.id);
            });
          } else if (attr == 'orderNameJp') {
            val.forEach(element => {
              value.push(element.name_jp);
            });
          } else if (attr == 'customerName') {
            val.forEach(element => {
              value.push(element.name);
            });
          } else if (attr == 'pmId') {
            val.forEach(element => {
              value.push(element.id);
            });
          } else if (attr == 'statusId') {
            val.forEach(element => {
              value.push(element.id);
            });
          } else if (attr == 'invoiceDate') {
            val.forEach(element => {
              value.push(element);
            });
          } else if (attr == 'paymentDeadline') {
            val.forEach(element => {
              value.push(element);
            });
          } else if (attr == 'issuer') {
            val.forEach(element => {
              value.push(element.id);
            });
          } else {
            val.forEach(element => {
              value.push(element.id);
            });
          }
          val = value.join('||');
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function () {
      let url = '/invoices/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        //map is used when we filter by the value itself.(e.g. map(item => item.name); DISCARDS the ID )
        //if the item is data for a select box where id = the value we filter by, do not use map()
        //Tomas - 2021/07/19
        this.selectBoxData.projectTypes = res.projectTypes;
        this.selectBoxData.projectNames = res.projectNames;
        this.selectBoxData.customerNames = res.customerNames;
        this.selectBoxData.orderNames = res.orderNames;
        this.selectBoxData.projectManagers = res.projectManagers;
        this.selectBoxData.statuses = res.statuses;
        this.selectBoxData.issuer = res.issuer;
        //note: the map function here seems to remove duplicate date values from the resulting array
        this.selectBoxData.invoiceDates = this.sortDates(this.monthYearOnlyHelper(res.invoiceDates));
        this.selectBoxData.paymentDeadlines = this.sortDates(this.monthYearOnlyHelper(res.paymentDeadlines));
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    sortDates: function (date_array) {
      let res = date_array
        .map(item => item.date ?? '')
        .sort(function (a, b) {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          } else {
            return 0;
          }
        });
      return res;
    },
    /**
     *List OF Helper Functions must set first in mehtods
     */
    monthYearOnlyHelper,
    monthYearEnglishHelper,
    displayedMoneyHelper,
    formatDateHelper,
    // ----------------------------------
    // Check Submit Dialog
    // ----------------------------------
    async checkSubmit(id) {
      var vm = this;
      var url = '/getOrderMessage/' + id;
      const res = await Vue.axios.get(url);
      if (res) {
        if (res.data != null) {
          if (res.data.data) {
            if (res.data.data.invoice_information) {
              vm.dialogMessage = 'Orders with a registered invoice information cannot be deleted.';
              vm.cancel = true;
            }
            if (res.data.data.report_items) {
              vm.dialogMessage = 'Reports have been made on this order. It can no longer be deleted.';
              vm.cancel = true;
            }
          } else {
            vm.dialogMessage = 'Are you sure you want to delete this order?';
            vm.cancel = false;
          }
        }
      }
      vm.$set(vm.dialog, id, true);
    },
    // ----------------------------------
    // Delete Submit Dialog
    // ----------------------------------
    async deleteSubmit(id) {
      var vm = this;
      vm.loadingConfirm = true;
      try {
        let url = `/order/${id}`;
        var res = await destroy(url);
        if (res) {
          vm.$set(vm.dialog, id, false);
          vm.options.page = 1;
          vm.getAllInvoices();
        }
        vm.loadingConfirm = false;
      } catch (error) {
        console.log(error);
      }
    },

    // for hide select filter if table header is hidden
    isEnabledColumn: function (value) {
      let obj_selectedHeaders = this.headers.find(obj => obj.value == value);
      return obj_selectedHeaders ? true : false;
    },
    // for set to edit mode
    editModeMethod: function () {
      this.editMode = !this.editMode;
      if (this.editMode == false) {
        this.enabledColumn = {};
      }
    },
    // Save Data Per-row and store to local storage
    editModeSumbit(item, type, args = null) {
      try {
        var vm = this;

        // get old value
        var old_value = '';
        if (type == 'invoice_date') {
          old_value = vm.invoices[item.index].invoice_date;
        } else if (type == 'payment_deadline') {
          old_value = vm.invoices[item.index].payment_deadline;
        } else if (type == 'payment_date') {
          old_value = vm.invoices[item.index].payment_date;
        } else {
          old_value = vm.invoices[item.index][type];
        }

        // check if validation order_name is error
        if (type == 'name_jp' || type == 'name_en') {
          const orderName = type + '_' + item.id;
          if (!vm.$refs[orderName].valid) {
            if (type == 'name_jp') vm.indexInvoice[item.index].name_jp = old_value;
            if (type == 'name_en') vm.indexInvoice[item.index].name_en = old_value;
            return;
          }
        }

        // check if validation invoice_amount is error
        if (type == 'invoice_amount') {
          const invoice = type + '_' + item.id;
          if (!vm.$refs[invoice].valid) {
            vm.indexInvoice[item.index].invoice_amount = old_value;
            return;
          }
        }

        // check if non-nullable date is null
        if (type == 'invoice_date') {
          if (item.invoice_date == null || !moment(item.invoice_date).isValid()) {
            vm.indexInvoice[item.index].invoice_date = old_value;
            return;
          }
        }
        if (type == 'payment_deadline') {
          if (item.payment_deadline == null || !moment(item.payment_deadline).isValid()) {
            vm.indexInvoice[item.index].payment_deadline = old_value;
            return;
          }
        }

        //check if date is not valid
        if (type == 'payment_date') {
          if (!moment(item.payment_date).isValid()) {
            vm.indexInvoice[item.index].payment_date = old_value;
            return;
          }
        }

        var arr = [];

        // get new value
        var value = '';
        if (type == 'invoice_date') {
          value = item.invoice_date;
        } else if (type == 'payment_deadline') {
          value = item.payment_deadline;
        } else if (type == 'payment_date') {
          value = item.payment_date;
        } else {
          value = item[type];
        }

        var data = {};
        data['index'] = item.index; // index invoice
        data['id'] = item.id; // id order
        data['args'] = args; // additional value
        var arrType = ['purchase_order_url', 'invoice_url']; // check if column purchase order url and invoice url

        var storage = JSON.parse(localStorage.getItem('invoices')); // get item local storage invoices
        // check if local storage is null
        if (storage == null) {
          data[type] = value; // set type value
          data['old_value'] = old_value; // set old value for ctrl z

          if (type) {
            if (type == 'purchase_order_url' && data['old_value'] != '') {
              arr.push(data); // push to array
            } else if (type == 'invoice_url' && data['old_value'] != '') {
              arr.push(data); // push to array
            } else if (!arrType.includes(type)) {
              arr.push(data); // push to array
            }
          }
          localStorage.setItem('invoices', JSON.stringify(arr)); // set item to local storage

          // set old value to invoices array
          if (type == 'invoice_date') {
            vm.invoices[item.index].invoice_date = value;
          } else if (type == 'payment_deadline') {
            vm.invoices[item.index].payment_deadline = value;
          } else if (type == 'payment_date') {
            vm.invoices[item.index].payment_date = value;
          } else {
            vm.invoices[item.index][type] = value;
          }
        }

        // check if local storage not null
        if (storage != null) {
          data[type] = value; // set type value
          data['old_value'] = old_value; // set old value for ctrl z

          arr = storage; // set local storage to arr
          if (type) {
            if (type == 'purchase_order_url' && data['old_value'] != '') {
              arr.push(data); // push to array
            } else if (type == 'invoice_url' && data['old_value'] != '') {
              arr.push(data); // push to array
            } else if (!arrType.includes(type)) {
              arr.push(data); // push to array
            }
          }
          localStorage.setItem('invoices', JSON.stringify(arr)); // set item to local storage

          // set old value to invoices array
          if (type == 'invoice_date') {
            vm.invoices[item.index].invoice_date = value;
          } else if (type == 'payment_deadline') {
            vm.invoices[item.index].payment_deadline = value;
          } else if (type == 'payment_date') {
            vm.invoices[item.index].payment_date = value;
          } else {
            vm.invoices[item.index][type] = value;
          }
        }
        // store to database
        vm.invoiceEditMode(data);
        // ---------------------------
      } catch (error) {
        console.log(error);
      }
    },
    // check shortcut ctrl+z
    keyupHandler(event) {
      var key = event.key;
      var cmd = event.metaKey;
      if ((event.ctrlKey && event.code === 'KeyZ') || (cmd && key.toLowerCase() == 'z')) {
        var vm = this;
        var storage = JSON.parse(localStorage.getItem('invoices'));
        // check condition to check localsotrage and length
        console.log(storage + ' Local Storage');
        if (storage != null && storage.length > 0) {
          // get last array on local storage
          var lastArr = storage.slice(-1)[0];
          console.log(lastArr + ' Last Array');
          // check condition for column project name
          if (lastArr['project_id']) {
            lastArr['project_id'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].project_id = lastArr['project_id'];
          }
          // check condition for column name_jp or name_en
          if (lastArr['name_jp'] || lastArr['name_en']) {
            // check if args is name_jp
            if (lastArr['args'] == 'name_jp') {
              lastArr['name_jp'] = lastArr['old_value'];
              vm.indexInvoice[lastArr['index']].name_jp = lastArr['name_jp'];
            }
            // check if args is name_en
            if (lastArr['args'] == 'name_en') {
              lastArr['name_en'] = lastArr['old_value'];
              vm.indexInvoice[lastArr['index']].name_en = lastArr['name_en'];
            }
          }
          // check condition for column PM
          if (lastArr['pm_id']) {
            lastArr['pm_id'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].pm_id = lastArr['pm_id'];
          }
          // check condition for column order status id
          if (lastArr['order_status_id']) {
            lastArr['order_status_id'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].order_status_id = lastArr['order_status_id'];
          }
          // check condition for column acceptance date
          if (lastArr['acceptance_date']) {
            lastArr['acceptance_date'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].acceptance_date = lastArr['acceptance_date'];
          }
          // check condition for column invoice date
          if (lastArr['invoice_date']) {
            lastArr['invoice_date'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].invoice_date = lastArr['invoice_date'];
          }
          // check condition for column payment date
          if (lastArr['payment_date']) {
            lastArr['payment_date'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].payment_date = lastArr['payment_date'];
          }
          // check condition for column payment deadline
          if (lastArr['payment_deadline']) {
            lastArr['payment_deadline'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].payment_deadline = lastArr['payment_deadline'];
          }
          // check condition for column purchase order url
          if (lastArr['purchase_order_url'] || lastArr['purchase_order_url'] == '') {
            lastArr['purchase_order_url'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].purchase_order_url = lastArr['purchase_order_url'];
          }
          // check condition for column purchase order url
          if (lastArr['issuer'] || lastArr['issuer'] == '') {
            lastArr['issuer'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].issuer = lastArr['issuer'];
          }
          // check condition for column customer
          if (lastArr['customer_id']) {
            lastArr['customer_id'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].customer_id = lastArr['customer_id'];
          }
          // check condition for column project type
          if (lastArr['project_type_id']) {
            lastArr['project_type_id'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].project_type_id = lastArr['project_type_id'];
          }
          // check condition for column project type
          if (lastArr['invoice_amount']) {
            lastArr['invoice_amount'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].invoice_amount = lastArr['invoice_amount'];
          }
          // check condition for column invoice
          if (lastArr['invoice_rul'] || lastArr['invoice_rul'] == '') {
            lastArr['invoice_rul'] = lastArr['old_value'];
            vm.indexInvoice[lastArr['index']].invoice_rul = lastArr['invoice_rul'];
          }
          // store to database
          vm.invoiceEditMode(lastArr, true, storage);
          // ---------------------------
        }
      }
    },
    // function to store to database when EDIT MODE
    async invoiceEditMode(data, undo = false, storage = null) {
      try {
        var vm = this;
        // set loading edit mode true
        vm.loadingEditMode = true;
        // store to database
        var url = '/invoices';
        let payload = new FormData();
        payload.append('dataset', JSON.stringify(data));
        var res = await update(url, payload);
        if (res) {
          if (undo) {
            var arr = storage.slice(0, -1);
            localStorage.setItem('invoices', JSON.stringify(arr)); // set item to local storage
          }
          if (data['project_id']) {
            vm.indexInvoice[data['index']].project = res.order.project;

            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column name_jp or name_en
          if (data['name_jp'] || data['name_en']) {
            // check if args is name_jp
            if (data['args'] == 'name_jp') {
              vm.indexInvoice[data['index']].name_jp = res.order.name_jp;

              vm.indexInvoice[data['index']].project = res.order.project;
              vm.indexInvoice[data['index']].order_status = res.order.order_status;
              vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
              vm.indexInvoice[data['index']].member = res.order.member;
              vm.indexInvoice[data['index']].pm_id = res.order.member.id;
            }
            // check if args is name_en
            if (data['args'] == 'name_en') {
              vm.indexInvoice[data['index']].name_en = res.order.name_en;

              vm.indexInvoice[data['index']].project = res.order.project;
              vm.indexInvoice[data['index']].order_status = res.order.order_status;
              vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
              vm.indexInvoice[data['index']].member = res.order.member;
              vm.indexInvoice[data['index']].pm_id = res.order.member.id;
            }
          }
          // check condition for column PM
          if (data['pm_id']) {
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
          }
          // check condition for column order status id
          if (data['order_status_id']) {
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column acceptance date
          if (data['acceptance_date']) {
            vm.indexInvoice[data['index']].acceptance_date = res.order.acceptance_date;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column invoice date
          if (data['invoice_date']) {
            vm.indexInvoice[data['index']].invoice_date = res.order.invoice_date;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column payment date
          if (data['payment_date']) {
            vm.indexInvoice[data['index']].payment_date = res.order.payment_date;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column payment deadline
          if (data['payment_deadline']) {
            vm.indexInvoice[data['index']].payment_deadline = res.order.payment_deadline;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column purchase order url
          if (data['purchase_order_url']) {
            vm.indexInvoice[data['index']].purchase_order_url = res.order.purchase_order_url;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column purchase order url
          if (data['issuer']) {
            vm.indexInvoice[data['index']].issuer = res.order.issuer;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column amount with tax
          if (data['invoice_amount']) {
            vm.indexInvoice[data['index']].invoice_amount = res.order.invoice_amount;
            vm.indexInvoice[data['index']].invoice_amount_tax = res.order.invoice_amount_tax;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // check condition for column invoice url
          if (data['invoice_url']) {
            vm.indexInvoice[data['index']].invoice_url = res.order.invoice_url;

            vm.indexInvoice[data['index']].project = res.order.project;
            vm.indexInvoice[data['index']].order_status = res.order.order_status;
            vm.indexInvoice[data['index']].order_status_id = res.order.order_status.id;
            vm.indexInvoice[data['index']].member = res.order.member;
            vm.indexInvoice[data['index']].pm_id = res.order.member.id;
          }
          // set loading edit mode true
          vm.loadingEditMode = false;
        }
      } catch (error) {
        vm.loadingEditMode = false;
        console.log(error);
      }
    },

    handleErrorItem(id) {
      var vm = this;
      vm.$set(vm.hideDetail, id, true);
    },
  },
};
</script>

<style lang="scss">
.invoice {
  .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 260px)  !important;
  }

  .v-pagination__navigation {
    display: none;
  }

  .highlight {
    padding: 0.625rem;
    background: red;
    border-radius: 0.25rem;
    color: white;
  }

  .theme--light.v-label {
    color: inherit !important;
  }

  .checkbox-style {
    border-radius: 4px;
  }

  .input-title {
    font-size: 0.9rem;
  }

  // .v-text-field__details {
  //   display: none;
  // }
  // .v-messages {
  //   display: none;
  // }
  .v-data-table>.v-data-table__wrapper>table {

    &>thead>tr>th,
    &>tbody>tr>th,
    &>tfoot>tr>th {
      font-size: 11px !important;
    }
  }

  .v-data-table>.v-data-table__wrapper>table {

    &>tbody>tr>td,
    &>thead>tr>td,
    &>tfoot>tr>td {
      font-size: 11px !important;
    }
  }

  table>tbody>tr>td.fixed:nth-last-child(1),
  table>thead>tr>th.fixed:nth-last-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    z-index: 3;
    background: white;
    -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
    box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  }

  table>thead>tr>th.fixed:nth-last-child(1) {
    z-index: 4;
  }

  /* .v-overlay--active {
    z-index: 9998 !important;
  }
  .v-dialog__content--active {
    z-index: 9999 !important;
  } */
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color: white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
}

.mx-datepicker {
  .mx-input {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.38);
    font-size: 11px;
    color: #000;

    &:focus {
      border-color: #1976d2 !important;
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.75);
    }
  }
}

.v-input {
  &.font-size-11 {
    .v-input__control {
      .v-input__slot {
        padding: 6px 15px !important;
        min-height: 34px !important;
        height: 34px;

        .v-select__slot {
          .v-input__append-inner {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

.font-size-11 {
  font-size: 11px !important;
}
</style>
