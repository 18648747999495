import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import AppBase from '@views/main/_layouts/AppBase';
import Dashboard from '@views/main/daily_report/Form';
import usersRoutes from './modules/user';
import dummyMeetingsRoutes from './modules/dummy-meeting';
import membersRoutes from './modules/member';
import dailyReportsRoutes from './modules/daily_report';
import morningReportsRoutes from './modules/morning_report';
import projectsRoutes from './modules/project';
import ordersRoutes from './modules/order';
import managementRoutes from './modules/management';
import customersRoutes from './modules/customer';
import unitPriceRoutes from './modules/unit_price';
import assignmentRoutes from './modules/assignment';
import scheduleRoutes from './modules/schedule';
import errors from './errors';
import auth from './auth';

import AdminAppBase from '@views/admin/_layouts/AppBase';
import AdminDashboard from '@views/main/daily_report/Form';

import { guards as availableGuards } from '@/store/modules/auth';

Vue.use(VueRouter);

/**
 * Define index routes.
 * This contain child routes. Please refer other modules.
 */
const routes = [
  {
    path: '/',
    name: 'home',
    component: AppBase,
    meta: {
      requiresAuth: true,
      guard: 'web',
      main: true,
    },
    children: [
      {
        path: 'reports/add',
        name: 'dashboard',
        component: Dashboard,
      },
      ...morningReportsRoutes,
      ...dailyReportsRoutes,
      ...projectsRoutes,
      ...usersRoutes,
      ...dummyMeetingsRoutes,
      ...customersRoutes,
      ...ordersRoutes,
      ...managementRoutes,
      ...membersRoutes,
      //...unitPriceRoutes,
      ...unitPriceRoutes,
      ...assignmentRoutes,
      ...scheduleRoutes,
    ],
  },
  {
    path: '/admin',
    name: 'admin_home',
    component: AdminAppBase,
    meta: {
      requiresAuth: true,
      guard: 'admin',
      main: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: AdminDashboard,
      },
    ],
  },
  ...auth,
  ...errors,
  {
    name: 'catch_all',
    path: '*',
    redirect: { name: 'home' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

/**
 * Pre process to start routing (route guard)
 * [about args]
 * to: requested URL.
 * from: previous router.(only when requested from Vue)
 * next: callback function.
 * see https://router.vuejs.org
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // arr.reduce((accumulater, currentValue) => {...}, initialValue );
    // [args] "accumulater" is accumulated value. "currenValue" is arr[index] .
    // Ex: [1,2,3].reduce( (acc, elem) => acc+elem ) is 1+2+3 -> result is 6.
    let reducer = (acc, cur) => {
      // Check if cur.meta.guard exists
      if (Object.prototype.hasOwnProperty.call(cur.meta, 'guard')) {
        // If there is cur.meta.guard( = to.matched[i].meta.guard ) , append it to acc.
        return [...acc, cur.meta.guard];
      } else {
        return acc;
      }
    };
    // collect to.matched[i].meta.guard to simple array.
    let guards = to.matched.reduce(reducer, []);
    // Check current guards in availableGuards and get loginRoute.
    let loginRoute = guards[0] === '' ? availableGuards.default.loginRoute : availableGuards[guards[0]].loginRoute;

    // Check auth.
    await store.dispatch('auth/checkAuth');
    //store.state.auth.info: this data is sent from the API LoginController
    //store.state.auth.info:
    const authInfo = store.state.auth.info;
    if (!authInfo.status) {
      // if not authenticated, redirect to correct login page
      // After authentication, it will direct you to where you
      // wanted to go
      console.log('if(!authInfo.status) is true. Sending user back to log in page');
      next({
        name: loginRoute,
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // if isAuthenticated and route.guard matched with authInfo.guard
      if (guards.some(guard => guard === authInfo.guard)) {
        //returns list of UNauthorized users types for target route
        let unauthorizedUserRoles = to.meta.restricted;
        console.log('authInfo.role = ' + authInfo.role);
        if (unauthorizedUserRoles) {
          //checks if authenticated admin's role not matching to route.meta.restricted
          if (!unauthorizedUserRoles.includes(authInfo.role)) {
            next();
            //else redirect to unathorized page
          } else {
            next({ name: 'ErrorUnauthorized' });
          }
          // route.meta.restricted not defined
        } else {
          next();
        }
      } else {
        next({ name: 'errorNotFound' });
      }
    }
  } else {
    // for route that does not require auth
    next();
  }
});

export default router;
