import Form from '@views/main/user/Form';
import Datatable from '@views/main/user/Datatable';
import constants from "@/const/const";

let nonAdmin = [constants.permissions.userString, constants.permissions.restrictedUserString];
console.log('non admin = ' + nonAdmin);
const usersRoutes = [
  {
    path: 'users',
    name: 'users',
    component: Datatable,
  },
  {
    path: 'users/create',
    name: 'users.create',
    component: Form,
    meta: {
      restricted: nonAdmin
    },
  },
  {
    path: 'users/:id/edit',
    name: 'users.edit',
    component: Form,
    meta: {
      editPage: true,
      restricted: nonAdmin
    },
  },
];

export default usersRoutes;
