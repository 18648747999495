<template>
  <div class="page-title py-2 px-2">
    <Title>
      <slot></slot>
    </Title>
  </div>
</template>

<script>
  import Title from '@views/_components/atoms/Title.vue'
  export default {
    components: {
      Title
    },
  }
</script>