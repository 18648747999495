<template>
  <InputGroup :title="title" :required="required" :optional="optional" class="mt-3">
    <v-select
      outlined
      dense
      :items="items"
      v-model="selectModel"
      :item-value="itemValue"
      :item-text="itemName"
      :no-data-text="$t('general.filter.noItem')"
    ></v-select>
  </InputGroup>
</template>

<script>
import InputGroup from "@views/_components/form_input/GFilterGroup";

export default {
  components: { InputGroup },
  props: {
    title: { required: false },
    value: { required: true },
    items: { required: true, type: Array, default: () => [] },
    option: {
      type: Array,
      default: () => ["id", "name"], // Use "id" for value and "name" for display
    },
    required: { default: false },
    optional: { default: false },
    disabled: { default: false },
  },
  computed: {
    selectModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    itemPair() {
      let option = this.option;
      if (!Array.isArray(option)) option = [option, option];
      return option;
    },
    itemValue() {
      return this.itemPair[0]; // Default to "id"
    },
    itemName() {
      return this.itemPair[1]; // Default to "name"
    },
  },
};
</script>
